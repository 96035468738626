import React from "react";
import { Map, Marker } from "google-maps-react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  map: {
    maxWidth: "100%",
  },
}));

export default function MapContainer(props) {
  const classes = useStyles();
  const onDragMarker = (coord, e) => {
    const lat = e.position.lat();
    const lng = e.position.lng();

    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDvOyqm0sGs-DLE0it3GOwgmiMRUjdBMdk`,
      headers: {},
    };

    axios(config)
      .then(function (results) {
        props.setGeo({ lat, lng });
        props.setAddress(
          results.data.results[0].formatted_address?.split(",")[0]
        );
        props.setCity(
          `${results.data.results[0].formatted_address.split(",")[1]},${
            results.data.results[0].formatted_address.split(",")[2]
          }`
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className={classes.root}>
      <Map
        google={window.google}
        zoom={18}
        initialCenter={{
          lat: props.coord.lat,
          lng: props.coord.lng,
        }}
        mapTypeControl={false}
        className={classes.map}
        zoomControl={true}
        fullscreenControl={true}
        rotateControl={false}
        streetViewControl={false}
      >
        <Marker
          onDragend={(marker, e) => onDragMarker(marker, e)}
          draggable={true}
          position={{ lat: props.coord.lat, lng: props.coord.lng }}
        />
      </Map>
    </div>
  );
}
