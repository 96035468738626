import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { textColorBlack, letterSpacing } from "./theme";
import numeral from "numeral";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    minWidth: 340,
    flexDirection: "column",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  content: {
    flex: "1 0 auto",
    padding: 5,
    "& > p": {
      textTransform: "uppercase",
      letterSpacing: letterSpacing,
      margin: 0,
    },
  },
  productName: {
    color: textColorBlack,
    marginBottom: 0,
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontSize: 12,
  },
  productValue: {
    fontWeight: "bold",
    fontSize: 12,
    width: "100%",
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
  },
  contentPrice: {
    display: "flex",
    maxWidth: 150,
    minWidth: 150,
  },
  strikethroughPrice: {
    textDecorationLine: "line-through",
    fontSize: 12,
  },
}));

export default function ListProduct(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className={classes.productName}> {props.name}</p>
          <div className={classes.contentPrice}>
            <p className={classes.productValue}>
              {props.price_functionality == "StandardPlusPersonalization"
                ? numeral(props.price).format("($0,0)")
                : props.price_functionality == "DeterminedByPersonalization"
                ? "VER PRECIO"
                : "Precio temporada"}
            </p>
            {props.price_functionality == "StandardPlusPersonalization" &&
              props.strikethrough_price && (
                <p className={classes.strikethroughPrice}>
                  {numeral(props.strikethrough_price).format("($0,0)")}
                </p>
              )}
          </div>
        </div>
        <div>
          <AddCircleIcon style={{ color: `${props.color}` }} />
        </div>
      </div>
    </div>
  );
}
