import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { SELECTOR_PRODUCTSELECTED } from '../redux/selectors/product-selected';
import { useSelector } from 'react-redux';
import { ProductSelectedSet } from '../redux/actions/product-selected';
import { Category } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDialog-container': {
      height: props.height,
      width: '100%',
    },
  }),
});

export default function AlertDialogSlide(props) {
  const productSelected = useSelector(SELECTOR_PRODUCTSELECTED);
  const [height, setHeigth] = useState('100%');

  useEffect(() => {
    if (
      productSelected.menu_category?.layout == 'Grid2by2WithImage' &&
      productSelected.path_main
    ) {
      setHeigth('100%');
    } else {
      let countItems = 0;

      const personalizationMenuItemSections =
        productSelected.personalization_menu_item_sections;

      personalizationMenuItemSections &&
        personalizationMenuItemSections.map(
          (it) =>
            (countItems +=
              it.personalization_section.personalization_opt_sections.length)
        );

      if (countItems >= 0 && countItems <= 3) {
        setHeigth('80%');
      } else if (countItems >= 4) {
        setHeigth('100%');
      }
    }
  }, [productSelected]);

  const classes = useStyles({ height });

  return (
    <div>
      <Dialog
        className={classes.root}
        fullScreen
        fullWidth
        open={props.openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {React.cloneElement(props.children, {
            handleClose: props.handleClose,
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
}
