/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { HeaderRestaurant2 } from '../../components/index';
import { ScrollSpyTabs } from '../../components/index';
import styled from 'styled-components';
import {
  restaurantsService,
  menuItemDepartmentsService,
} from '../../api/feathers';
import { s3PathImageHandrer } from '../../utils/constants';
import { RestaurantSet } from '../../redux/actions/restaurant';
import lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardProduct,
  ProductDialog,
  HelmetRestaurant,
  ListProduct,
  OrderActive,
} from '../../components/commons';
import { ProductDetails } from '../../components';
import { useGA4React } from 'ga-4-react';
import {
  grayPrimary,
  graySecond,
  mainColor,
  letterSpacing,
} from '../../components/commons/theme';
import { SELECTOR_TYPE_ORDER_SELECTED } from '../../redux/selectors/current-type-order';
import SwitchDepartaments from '../../components/swith-departments';

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 240px;
  min-height: 10px;
`;

const useStyles = makeStyles(() => ({
  contendCardProduct: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
      cursor: 'pointer',
    },
  },
  contentListProduct: {
    cursor: 'pointer',
  },
  underlineTabs: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    flexDirection: 'column',
    alingItems: 'center',
    textAling: 'center',
    '& .MuiTab-root': {
      lineHeight: '13px',
      backgroundColor: `white`,
      color: props.menuLayoutColor.menu_text_color,
      height: '36px',
      minHeight: '10px !important',
      fontSize: '12px',
      paddingLeft: '18px',
      paddingRight: '18px',
      letterSpacing: `${letterSpacing}`,
      opacity: 'none',
      textTransform: 'uppercase !important',
      fontWeight: 'bold',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: props.menuLayoutColor.menu_background_underline_color,
    },
  }),
  roundedButtons: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 10,
    alingItems: 'center',
    textAling: 'center',
    '& .MuiTab-root': {
      lineHeight: '13px',
      backgroundColor: `${graySecond}`,
      color: `${grayPrimary}`,
      borderRadius: '50px',
      height: '36px',
      minHeight: '10px !important',
      fontSize: '12px',
      paddingLeft: '18px',
      paddingRight: '18px',
      paddingTop: 0,
      paddingBottom: 0,
      letterSpacing: `${letterSpacing}`,
      opacity: 'none',
      textTransform: 'uppercase !important',
      fontWeight: 'bold',
    },
    '& .Mui-selected': {
      backgroundColor: `${props.backgroundColor}`,
      color: 'white !important',
      fontWeight: 'bold',
    },
  }),
}));

function RestaurantList(props) {
  const history = useHistory();
  const location = useLocation();
  const ga = useGA4React();
  const [restaurant, setRestaurant] = useState({ category: {} });
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(0);
  const [color, setColor] = useState(null);
  const dispatch = useDispatch();

  const [menuLayoutColor, setMenuLayoutColor] = useState({
    menu_layout: 'roundedButtons',
    menu_background_underline_color: '',
    menu_text_color: mainColor,
  });
  const typeOrdersSelect = useSelector(SELECTOR_TYPE_ORDER_SELECTED);
  const classes = useStyles({
    backgroundColor: color,
    menuLayoutColor: menuLayoutColor,
  });

  const [dataScroll, setDataScroll] = useState([
    {
      text: 'entradas',
      component: <p style={{ height: '80vh' }}>cargando...</p>,
    },
  ]);
  const [menuId, setMenuId] = useState(null);
  const SkeletonTabs = () => (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        {[1, 2, 3].map((it, key) => (
          <Skeleton
            key={key}
            animation="wave"
            height={50}
            width="120px"
            style={{ borderRadius: 20, marginLeft: 10 }}
          />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginLeft: 4,
          marginRight: 4,
          marginTop: -38,
        }}
      >
        {[1, 2, 3, 4].map((it, key) => (
          <Skeleton
            key={key}
            animation="wave"
            style={{
              height: 280,
              minWidth: 175,
              maxWidth: 250,
            }}
          />
        ))}
      </div>
    </div>
  );

  const showProduct = (menuItemId) => {
    return history.push(
      `r-${restaurant.slug}/p-${menuItemId}?typeOrder=${typeOrdersSelect}`
    );
  };

  const generateDataScroll = (menuItems) => {
    const categories = lodash.orderBy(
      menuItems,
      ['menu_category_position', 'position'],
      ['asc', 'asc']
    );

    const categoriesOrders = lodash.uniqBy(
      categories.map((it) => it.menu_category_id)
    );

    const menuCategories = lodash.groupBy(categories, 'menu_category_id');

    const data = [];
    for (let index = 0; index < categoriesOrders.length; index++) {
      const menuCategoriesKey = categoriesOrders[index];

      const menuCategory = menuCategories[menuCategoriesKey];
      const component = [];
      let layout = 'Grid2by2WithImage';
      let menuCategoryName = '';
      let bannerPath = '';
      for (let index = 0; index < menuCategory.length; index++) {
        const menu = menuCategory[index];
        menuCategoryName = menu.menu_category_name;
        bannerPath = menu.menu_category_banner_path;

        if (menu.menu_category_layout === 'FullWidthNoImage') {
          layout = 'FullWidthNoImage';
          component.push(
            <div
              className={classes.contentListProduct}
              key={menu.id}
              onClick={() => {
                setMenuId(menu.id);
                showProduct(menu.id);
              }}
            >
              <ListProduct
                menu_category_name={menu.category_name}
                name={menu.name}
                id={menu.id}
                price={menu.price_tax_incl}
                price_functionality={menu.price_functionality}
                strikethrough_price={menu.strikethrough_price}
                color={color}
              />
            </div>
          );
        } else {
          component.push(
            <div
              className={classes.contendCardProduct}
              key={menu.id}
              onClick={() => {
                setMenuId(menu.id);
                showProduct(menu.id);
              }}
            >
              <CardProduct
                path={
                  menu.path_main
                    ? `${s3PathImageHandrer}/${window.imageShark(
                      menu.path_main,
                      220,
                      220
                    )}`
                    : false
                }
                price_functionality={menu.price_functionality}
                strikethrough_price={menu.strikethrough_price}
                price={menu.price_tax_incl}
                name={menu.name}
                id={menu.id}
              />
            </div>
          );
        }
      }

      data.push({
        text: menuCategory[0].menu_category_name,
        bannerPath,
        component,
        layout,
        menuCategoryName,
        color,
      });
    }
    setDataScroll(data);
  };

  const match = useRouteMatch('/r-:slug');

  const getRestaurant = (slug) => {
    restaurantsService
      .find({
        query: {
          slug: slug ? slug : match?.params.slug,
          status: 'active',
          $client: {
            filterMenuCategories: 'true',
            menu: 'true',
            joinMenu: 'true',
            typeOrder:
              typeOrdersSelect === 'pick_up' ? 'pickup' : typeOrdersSelect,
            menuItemdepartmentId: departmentId,
          },
        },
      })
      .then((it) => {
        setRestaurant(it.data[0]);
        dispatch(RestaurantSet(it.data[0]));
        setColor(
          it.data[0]?.primary_color ? it.data[0]?.primary_color : mainColor
        );
        setMenuLayoutColor({
          menu_layout: it.data[0].menu_layout,
          menu_background_underline_color:
            it.data[0].menu_background_underline_color,
          menu_text_color: it.data[0].menu_text_color,
        });
      })
      .catch((e) => console.log(e));
  };

  const getDeparments = ({ brand_id }) => {
    menuItemDepartmentsService.find({ query: { $limit: 50, $sort: { position: -1 }, brand_id } }).then((it) => {
      setDepartments(it.data);
    });
  };

  useEffect(() => {
    if (restaurant.menu_items_v2?.length && color) {
      generateDataScroll(restaurant.menu_items_v2);
    } else {
      setDataScroll([
        {
          text: 'entradas',
          component: <strong>No hay menú disponible</strong>,
        },
      ]);
    }
  }, [restaurant, color]);

  useEffect(() => {
    getRestaurant();
  }, [typeOrdersSelect]);

  useEffect(() => {
    if (!restaurant) getRestaurant();
  }, []);

  useEffect(() => {
    if (restaurant) getDeparments({ brand_id: restaurant.brand_id });
  }, [restaurant]);

  useEffect(() => {
    getRestaurant();
  }, [departmentId]);

  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname + (location.search || ''));
    }
  }, [ga, location.pathname]);

  useEffect(() => {
    window.addEventListener('popstate', backBlock);
    return () => {
      window.removeEventListener('popstate', backBlock);
    };
  }, []);

  function backBlock(event) {
    history.push('/');
  }

  return (
    <>
      <OrderActive />
      <div className={'upperpart'}>
        {restaurant.path_cover ? (
          <Img
            src={`${s3PathImageHandrer}/${window.imageShark(
              restaurant.path_cover,
              1000,
              1000
            )}`}
            alt={restaurant.name}
          />
        ) : (
          <Skeleton
            animation="wave"
            style={{
              height: 300,
              minWidth: 600,
              width: '100%',
              marginTop: -72,
            }}
          />
        )}
      </div>
      <HeaderRestaurant2
        name={restaurant.name}
        slogan={restaurant.slogan}
        path_logo={restaurant.path_logo}
        brand_id={restaurant.brand_id}
        {...restaurant}
        getRestaurant={getRestaurant}
      />
      <div
        className={
          menuLayoutColor.menu_layout === 'roundedButtons'
            ? classes.roundedButtons + ' menucontainer'
            : classes.underlineTabs + ' menucontainer'
        }
      >
        {restaurant.status === 'active' && dataScroll.length ? (
          <Fragment style={{ display: 'flex', flexDirection: 'column' }}>
            {!!departments.length && (
              <SwitchDepartaments
                departments={departments}
                setDepartmentId={setDepartmentId}
                mainColor={restaurant.primary_color}
              />
            )}
            <ScrollSpyTabs tabsInScroll={dataScroll} />
          </Fragment>
        ) : restaurant.status === 'inactive' ? (
          <div>El restaurante desactivo temporalmente el acceso al menú</div>
        ) : (
          <SkeletonTabs />
        )}
      </div>
      <ProductDialog
        children={<ProductDetails menuId={menuId} />}
        menuId={menuId}
      />
      <HelmetRestaurant
        title={`${restaurant.name ? restaurant.name : ''}`}
        cover={`${restaurant.path_cover}`}
        description={restaurant.description ? restaurant.description : null}
        slug={restaurant.slug ? restaurant.slug : null}
      />
    </>
  );
}

export default RestaurantList;
