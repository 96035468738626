import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const UserIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.799"
      height="26.412"
      viewBox="0 0 16.799 26.412"
    >
      <g id="menu_1_" data-name="menu (1)" transform="translate(-93.76 0.105)">
        <g
          id="Grupo_685"
          data-name="Grupo 685"
          transform="translate(93.86 -0.001)"
        >
          <g id="Grupo_684" data-name="Grupo 684" transform="translate(0 0)">
            <path
              id="Trazado_822"
              data-name="Trazado 822"
              d="M109.148,3.056V1.372a1.42,1.42,0,0,0-.522-1.114,1.176,1.176,0,0,0-1.009-.226L95.08,3.066a1.308,1.308,0,0,0-1.22,1.3V24.9a1.312,1.312,0,0,0,1.31,1.31h13.978a1.312,1.312,0,0,0,1.31-1.31V4.367A1.312,1.312,0,0,0,109.148,3.056ZM107.823.881a.3.3,0,0,1,.263.063.541.541,0,0,1,.189.428V3.056H98.833ZM109.585,24.9a.437.437,0,0,1-.437.437H95.17a.437.437,0,0,1-.437-.437V4.367a.437.437,0,0,1,.437-.437h13.978a.437.437,0,0,1,.437.437Z"
              transform="translate(-93.86 0.001)"
              fill={props.color}
              stroke="#fff"
              strokeWidth="0.2"
            />
            <path
              id="Trazado_823"
              data-name="Trazado 823"
              d="M274.369,113.362a1.312,1.312,0,0,0,1.31-1.31V106.81a.875.875,0,0,0,.874-.874v-2.621c0-1.909-.982-3.235-2.564-3.46l-.433-.061a.437.437,0,0,0-.5.433v11.825A1.312,1.312,0,0,0,274.369,113.362Zm-.437-12.631c1.575.267,1.747,1.891,1.747,2.584v2.621h-.437a.437.437,0,0,0-.437.437v5.678a.437.437,0,1,1-.874,0v-11.32Z"
              transform="translate(-263.886 -91.747)"
              fill={props.color}
              stroke="#fff"
              strokeWidth="0.2"
            />
            <path
              id="Trazado_824"
              data-name="Trazado 824"
              d="M171.537,105.835v6.788a1.31,1.31,0,1,0,2.621,0v-6.788a1.739,1.739,0,0,0,.874-1.512v-3.494a.437.437,0,0,0-.874,0v2.184a.437.437,0,1,1-.874,0v-2.184a.437.437,0,0,0-.874,0v2.184a.437.437,0,1,1-.874,0v-2.184a.437.437,0,0,0-.874,0v3.494A1.738,1.738,0,0,0,171.537,105.835Zm0-1.592a1.269,1.269,0,0,0,1.31-.26,1.269,1.269,0,0,0,1.31.26v.08a.861.861,0,0,1-.578.818.437.437,0,0,0-.3.413v7.068a.437.437,0,1,1-.874,0v-7.068a.437.437,0,0,0-.3-.413.861.861,0,0,1-.578-.818Z"
              transform="translate(-166.732 -92.318)"
              fill={props.color}
              stroke="#fff"
              strokeWidth="0.2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default UserIcon;
