import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ordersDetailsService } from '../api/feathers';
import {
  letterSpacing,
  grayPrimary,
  graySecond,
} from '../components/commons/theme';
import { s3Path } from '../utils/constants';
import Numeral from 'numeral';
import { AddedButton } from './';
import { Divider } from './commons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LogoRestaurant from '../components/commons/Logo-restaurant';
import { s3PathImageHandrer } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 20,
  },
  contentTitle: {
    marginTop: 46,
    display: 'flex',
    fontWeight: '500',
    marginLeft: 20,
    '& > :nth-child(2n)': {
      marginLeft: 20,
    },
  },
  message: {
    marginTop: -2,
    fontSize: 12,
    color: grayPrimary,
    letterSpacing,
    fontWeight: 'normal',
  },
  contentProducts: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 4,
  },
  contentLogo: {
    minWidth: '60px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: (props) => props.height,
    width: (props) => props.width,
    borderRadius: '50%',
    background: 'lightgray',
    opacity: (props) =>
      props.logoOpacity > 100
        ? '0.5 !important'
        : props.logoOpacity > 160
        ? '0.0 !important'
        : '1',
  },
  imgProduct: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  contentItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: 10,
  },
  nameProduct: {
    fontSize: 13,
    color: 'black',
    fontWeight: '200',
  },
  subTitle: {
    fontSize: 9,
  },
  description: {
    color: grayPrimary,
    marginLeft: 14,
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    minWidth: 40,
  },
  contentPersonalizations: {
    marginLeft: 0,
    marginTop: -10,
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 30,
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      flexGrow: 0,
    },
    backgroundColor: 'transparent',
  },
  itemsPersonalizations: {
    width: '100%',
    color: grayPrimary,
    fontSize: 12,
    textTransform: 'Capitalize',
  },
  titlePersonalization: {
    fontSize: 12,
    color: grayPrimary,
  },
  contentPrice: {
    display: 'flex',
  },
  strikethroughPrice: {
    marginLeft: 10,
    fontSize: 9,
    textDecorationLine: 'line-through',
  },
}));

const Message = (props) => (
  <>
    {props.restaurant.type == 'instore'
      ? 'PEDIDO EN EL RESTAURANTE'
      : props.restaurant.type == 'delivery'
      ? 'PEDIDO A DOMICILIO'
      : 'PEDIDO PICKUP'}
  </>
);

function RestaurantListProductCheckout(props) {
  const classes = useStyles();

  useEffect(() => {}, [props]);

  const handleIncrease = (id, quantity) => {
    ordersDetailsService
      .patch(id, { quantity: quantity + 1 })
      .then((it) => props.getOrder());
  };

  const handleReduce = (id, quantity) => {
    ordersDetailsService
      .patch(id, { quantity: quantity - 1 })
      .then((it) => props.getOrder());
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentTitle}>
          <div>
            <LogoRestaurant
              path_logo={props.order?.restaurant?.path_logo}
              height={50}
              width={50}
            />
          </div>
          <div>
            <div>{props.order?.restaurant?.name}</div>
            <div
              style={{
                marginTop: -6,
              }}
            >
              {props.order?.restaurant?.slogan}
            </div>
            <div className={classes.message}>
              {props.order && <Message restaurant={props.order} />}
            </div>
          </div>
        </div>
        <div className={classes.contentProducts}>
          {props.order?.order_details?.map((itt, index) => (
            <div key={`oderDetails${index}`}>
              <div>
                <Divider
                  size={1}
                  width={'100%'}
                  backgroundColor={graySecond}
                  marginTop={10}
                  marginBottom={5}
                />
              </div>
              <div className={classes.contentItem}>
                <div
                  className={classes.contentLogo}
                  logoOpacity={props.logoOpacity}
                >
                  <img
                    className={classes.imgProduct}
                    src={`${s3PathImageHandrer}/${window.imageShark(
                      itt.menu_item.path_main
                        ? itt.menu_item.path_main
                        : 'static/suenadeli.png',
                      200,
                      200
                    )}`}
                    alt="logo"
                    height={'60px'}
                    width={'60px'}
                  />
                </div>
                <div className={classes.description}>
                  <div className={classes.nameProduct}>
                    {itt.menu_item.name}
                  </div>
                  <div className={classes.contentPrice}>
                    <div className={classes.subTitle}>
                      Platos: {itt.quantity} X
                      {Numeral(
                        itt.menu_item.price_functionality ==
                          'StandardPlusPersonalization'
                          ? itt.menu_item.price_tax_incl
                          : itt.menu_item.price_functionality ==
                            'DeterminedByPersonalization'
                          ? itt.personalization_total_tax_incl
                          : ''
                      ).format('($0,0)')}
                    </div>
                    {itt.menu_item.strikethrough_price &&
                      itt.menu_item.price_functionality ==
                        'StandardPlusPersonalization' && (
                        <div className={classes.strikethroughPrice}>
                          {Numeral(
                            itt.menu_item.strikethrough_price * itt.quantity
                          ).format('($0,0)')}
                        </div>
                      )}
                  </div>
                </div>
                <div>
                  <AddedButton
                    quantity={itt.quantity}
                    handleIncrease={() => handleIncrease(itt.id, itt.quantity)}
                    handleReduce={() => handleReduce(itt.id, itt.quantity)}
                  />
                </div>
              </div>
              {itt.order_details_personalization.length ? (
                <div>
                  <div className={classes.contentPersonalizations}>
                    <Accordion className={classes.accordion}>
                      <AccordionSummary
                        style={{ maxWidth: 330 }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className={classes.titlePersonalization}>
                          PERSONALIZADA
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ marginLeft: 82 }}>
                          {itt.order_details_personalization?.map(
                            (it, index) => (
                              <Fragment
                                key={`orderDetailsPersonalization${index}`}
                              >
                                <div className={classes.itemsPersonalizations}>
                                  <div>{it.meta_option_name}</div>
                                  <div>
                                    {it.quantity} X
                                    {Numeral(
                                      it.personalization_opt_section
                                        ?.price_tax_incl
                                    ).format('($0,0)')}
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RestaurantListProductCheckout;
