import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grayPrimary, letterSpacing } from "../components/commons/theme";
import { s3Path } from "../utils/constants";
import S3Field from "./commons/S3-field";
import { paymentsService } from "../api/feathers";

const useStyles = makeStyles({
  root: { padding: "10px 20px 10px 20px" },
  content: {
    display: "flex",
    justifyContent: "space-between",
    letterSpacing,
    fontWeight: "500",
    fontSize: 12,
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
  },
  contentPaymentMethod: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: "500",
    fontSize: 10,
  },
  paymentDescription: {
    marginTop: 30,
    width: "100%",
  },
});

export default function PaymentMethodTracking(props) {
  const classes = useStyles();
  const { restaurant_id, id } = props.order;
  const { getOrder } = props;

  const [payments, setPayments] = useState([]);

  const getPayments = () => {
    paymentsService.find({ query: { order_id: id } }).then(it => {
      setPayments(it.data);
    });
  };

  useEffect(() => {
    if (id) getPayments();
  }, [id]);

  const uploadEvidence = (id, urls) => {
    const { fileKey } = urls;
    paymentsService
      .patch(id, { bank_transfer_evidence_path: fileKey })
      .then(it => getOrder());
    getPayments();
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>METODO DE PAGO</div>
      <div className={classes.content}>
        {payments?.map(it => (
          <>
            <div className={classes.contentPaymentMethod}>
              {it.method == "credit_card" ? (
                <img
                  src={`${s3Path}/static/logos-tarjetas-de-credito/${it.image}.png`}
                  height={50}
                  width={70}
                />
              ) : (
                <img
                  src={`${s3Path}/static/payment-methods/${it.image}.svg`}
                  height={80}
                  width={80}
                />
              )}
              <div>
                {it.method == "credit_card" ? (
                  `${it.credit_card_masked_number}`
                ) : it.method == "cash" ? (
                  "Pago en efectivo / Datafono"
                ) : it.method == "cash_on_delivery" ? (
                  "Pago contra entrega"
                ) : it.method === "bank_transfer" ? (
                  "Transferencia Bancaria"
                ) : it.method === "dataphone_on_delivery" ? (
                  "Transferencia Bancaria"
                ) : it.method === "payment_on_pickup" ? (
                  "Pago en el establecimiento"
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className={classes.paymentDescription}>
              {it.method === "bank_transfer" && (
                <div>
                  <S3Field
                    label={"Subir comprobante de pago"}
                    idComponent="s3uploader"
                    path={`restaurants/${restaurant_id}/payments_evidences/${id}/`}
                    id={it.id}
                    uploadEvidence={uploadEvidence}
                    preview={it.bank_transfer_evidence_path}
                  />
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
