import React, { useEffect, useState, Fragment } from "react";
import {
  letterSpacing,
  greenSuccess,
  graySecond,
} from "../../components/commons/theme";
import { makeStyles } from "@material-ui/core/styles";
import { userAddressesService } from "../../api/feathers";
import { FormAddress } from "../../components/";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Divider } from "../../components/commons";
import { useDispatch } from "react-redux";
import { AddressesSet } from "../../redux/actions/addresses";

const useStyles = makeStyles(theme => ({
  root: {},
  contentRestaurant: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0px",
    letterSpacing,
    textTransform: "uppercase",
    width: "100%",
    boxShadow: "rgb(0 0 0 / 12%) 0px 7px 16px -6px",
  },
  goBack: {
    zIndex: 10,
    position: "absolute",
    marginLeft: 20,
    marginTop: 6,
  },
  contendAddress: {
    padding: "10px 20px",
    boxShadow: "rgb(0 0 0 / 12%) 0px 7px 16px -6px",
  },
  title: {
    marginBottom: 10,
    letterSpacing,
    fontWeight: "100",
  },
  titleMain: {
    marginBottom: 30,
    letterSpacing,
    fontWeight: "500",
  },
  contendList: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    minHeight: 50,
    alignItems: "flex-end",
  },
  contentInput: {
    marginBottom: 30,
  },
}));

function Addreeses(props) {
  const classes = useStyles();
  const [addresses, setAddresses] = useState([]);
  const [showList, setShowList] = useState(true);
  const dispatch = useDispatch();

  const getAddresses = () => {
    userAddressesService
      .find({ query: { $limit: 50000 } })
      .then(it => {
        setAddresses(it.data);
        dispatch(AddressesSet(it.data));
      })
      .catch(it => console.log(it.message));
  };

  const updateMainAddress = id => {
    userAddressesService.patch(id, { main: "true" }).then(it => {
      getAddresses();
      props.handleCloseDialogFullScreen();
    });
  };

  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {showList && (
          <h3 className={classes.titleMain}>
            Agrega o selecciona una dirección
          </h3>
        )}
        <div className={classes.contentInput}>
          <FormAddress
            handleCloseDialogFullScreen={props.handleCloseDialogFullScreen}
            getAddresses={getAddresses}
            setShowList={setShowList}
            restaurant={props.restaurant}
          />
        </div>
        {showList &&
          addresses.map(it => (
            <Fragment>
              <Divider
                size={1}
                width={"100%"}
                backgroundColor={graySecond}
                marginTop={5}
                marginBottom={5}
                style={{ marginLeft: 30 }}
              />
              <div
                className={classes.contendList}
                onClick={() => updateMainAddress(it.id)}
              >
                <div className={classes.title}>
                  <div>{it.address}</div>
                </div>
                <div>
                  {it.main === "true" ? (
                    <RadioButtonCheckedIcon style={{ color: greenSuccess }} />
                  ) : (
                    <div>
                      <RadioButtonUncheckedIcon
                        style={{ color: greenSuccess }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
}

export default Addreeses;
