import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonPrimary } from "./commons/";
import { Divider } from "../components/commons";

import {
  graySecond,
  mainColor,
  letterSpacing,
  blackMain,
} from "../components/commons/theme";

const useStyles = makeStyles({
  root: {
    minHeight: 150,
    padding: 30,
    // minWidth: 300,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 30,
    width: "100%",
  },
  actions: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    "& > :first-child": {
      marginRight: 20,
    },
  },
  message: {
    letterSpacing,
    textAlign: "center",
  },
  contentTitle: {
    maxWidth: 213,
    textAlign: "center",
    marginBottom: 10,
    letterSpacing,
    fontSize: 16,
    color: blackMain,
    fontWeight: "lighter",
    lineHeight: "20px",
  },
});

export default function CallWaiter(props) {
  const classes = useStyles();

  const call = () => {
    console.log("llamar al mesero");
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentTitle}>
        <div>{props.title}</div>
      </div>
      <Divider
        size={2}
        width={125}
        backgroundColor={mainColor}
        marginTop={5}
        marginBottom={5}
        style={{ marginLeft: 30 }}
      />
      <div className={classes.message}>
        Entérate cuando esta funcionalidad este lista en instagram
        <a
          style={{ textDecoration: "underline", color: mainColor }}
          target="_blank"
          href="https://instagram.com/poketmenu"
        >
          @poketmenu
        </a>
      </div>
      <div className={classes.actions}>
        <div>
          <ButtonPrimary
            color={mainColor}
            label="ACEPTAR"
            onClick={props.handleClose}
          />
        </div>
      </div>
    </div>
  );
}
