import react, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing } from "./theme";

const useStyles = makeStyles({
  root: (props) => ({
    padding: 10,
    justifyContent: "center",
    display: "flex",
    backgroundColor: props.color,
    borderRadius: 30,
    color: "white",
    width: 150,
    letterSpacing,
    fontWeight: 500,
  }),
});

function ButtonPrimary({ onClick, label, ...props }) {
  const classes = useStyles(props);

  useEffect(() => {}, [props]);

  return (
    <div className={classes.root}>
      <div onClick={onClick}>{label}</div>
    </div>
  );
}

export default ButtonPrimary;
