import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import throttle from 'lodash/throttle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider } from '../components/commons';
import { s3PathImageHandrer } from '../utils/constants';

const tabHeight = 69;

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
})((props) => (
  // <AppBar position="static" color="transparent">
  <Tabs
    {...props}
    scrollButtons="auto"
    TabIndicatorProps={{ children: <div /> }}
  />
  // </AppBar>
));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    height: tabHeight,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    opacity: 1,
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  underlineTabs: {
    minHeight: 30,
    top: -1,
    left: 0,
    right: 0,
    width: '100%',
    position: 'sticky',
    backgroundColor: 'white',
    paddingTop: 10,
    paddingLeft: 10,
    '& .MuiTabs-root': {
      justifyContent: 'center',
      '& > div': {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        overflow: 'auto !important',
        width: '100%',
      },
    },
  },
  roundedButtons: {
    minHeight: 30,
    top: -1,
    left: 0,
    right: 0,
    width: '100%',
    position: 'sticky',
    backgroundColor: 'white',
    paddingTop: 10,
    paddingLeft: 10,
    '& .MuiTabs-root': {
      justifyContent: 'center',
      '& > div': {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        overflow: 'auto !important',
        width: '100%',
      },
    },
  },
  contentItemsGrid2by2WithImage: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  contentFullWidthNoImage: {
    display: 'flex',
    flexFlow: 'row wrap',
    // justifyContent: "space-around",
    width: '100%',
    marginTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    '& > div': {
      width: '100%',
    },
  },
}));

/******* This is the scroll spy magic */
/*
Credits: Material UI
Source: 
https://github.com/mui-org/material-ui/blob/404c2ba16816f5c7ab7d8b2caf6bbc3d2218b820/docs/src/modules/utils/textToHash.js
*/
const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '')
    ),
    unique
  );
};
const noop = () => { };

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) return undefined;

    window.addEventListener('scroll', throttledCallback);
    return () => {
      window.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}

function ScrollSpyTabs(props) {
  const [activeState, setActiveState] = useState(null);
  const { tabsInScroll } = props;
  let itemsServer = tabsInScroll.map((tab, i) => {
    const hash = textToHash(tab.text);
    return {
      layout: tab?.layout,
      key: i,
      icon: tab.icon || '',
      text: tab.text,
      component: tab.component,
      hash: hash,
      color: tab.color,
      node: document.getElementById(hash),
      menuCategoryName: tab.menuCategoryName,
      bannerPath: tab.bannerPath,
    };
  });

  const itemsClientRef = useRef([]);
  useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef(null);
  const findActiveIndex = useCallback(
    (activateCategory) => {
      // set default if activeState is null
      if (activeState === null || activateCategory == 'activateFirstCategory')
        setActiveState(itemsServer[0].hash);

      // Don't set the active index based on scroll if a link was just clicked
      if (clickedRef.current) return;

      let active;
      for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
        // No hash if we're near the top of the page
        if (document.documentElement.scrollTop < 0) {
          active = { hash: null };
          break;
        }

        const item = itemsClientRef.current[i];

        if (
          item.node &&
          item.node.offsetTop <
          document.documentElement.scrollTop +
          document.documentElement.clientHeight / 8 +
          tabHeight
        ) {
          active = item;
          break;
        }
      }

      if (active && activeState !== active.hash) {
        setActiveState(active.hash);
      }
    },
    [activeState, itemsServer]
  );

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);

      if (window)
        window.scrollTo({
          top:
            document.getElementById(hash).getBoundingClientRect().top -
            110 +
            window.pageYOffset,
          behavior: 'smooth',
        });
    }
  };

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  useEffect(() => {
    findActiveIndex('activateFirstCategory');
  }, [tabsInScroll]);

  const classes = useStyles();

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <nav className={classes.roundedButtons}>
        <StyledTabs value={activeState ? activeState : itemsServer[0].hash}>
          {itemsServer.map((item2) => (
            <StyledTab
              key={item2.hash}
              label={item2.text}
              onClick={handleClick(item2.hash)}
              value={item2.hash}
            />
          ))}
        </StyledTabs>
      </nav>
      {itemsServer.map((item1, i) => (
        <article id={item1.hash} key={i}>
          {item1.bannerPath && (
            <img
              className={classes.imgProduct}
              src={`${s3PathImageHandrer}/${window.imageShark(
                item1.bannerPath,
                600
              )}`}
              alt="logo"
              height={'auto'}
              width={'100%'}
            />
          )}
          <div
            className={
              item1?.layout === 'FullWidthNoImage'
                ? classes.contentFullWidthNoImage
                : classes.contentItemsGrid2by2WithImage
            }
          >
            {item1?.layout === 'FullWidthNoImage' ? (
              <div>
                <div
                  style={{
                    textTransform: 'uppercase',
                    fontSize: 20,
                    textAlign: 'left',
                  }}
                >
                  {!item1.bannerPath && item1.menuCategoryName}
                </div>
                {!item1?.bannerPath && <Divider
                  size={1}
                  width={'100%'}
                  backgroundColor={item1.color}
                  marginTop={5}
                  marginBottom={5}
                />}
                <div>{item1.component}</div>
              </div>
            ) : (
              item1.component
            )}
          </div>
        </article>
      ))}
    </div>
  );
}

export default ScrollSpyTabs;
