import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackIcon } from '../../components/icons';
import {
  letterSpacing,
  headerTitleColor,
} from '../../components/commons/theme';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 26,
    marginLeft: 35,
    marginRight: 35,
  },
  headerTitle: {
    letterSpacing,
    color: headerTitleColor,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 300,
  },
});

function HeaderCommon(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div>
        <BackIcon />
      </div>
      <div className={classes.headerTitle}> {props.title}</div>
    </div>
  );
}

export default HeaderCommon;
