import { useEffect } from "react";
import styled, { css } from "styled-components";
import { s3Path,s3PathImageHandrer } from "../../utils/constants";
import Skeleton from "@material-ui/lab/Skeleton";

const ContentLogo = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: 50%;
  background: lightgray;
  opacity: ${(props) =>
    props.logoOpacity > 100
      ? "0.5 !important"
      : props.logoOpacity > 160
      ? "0.0 !important"
      : "1"};
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

function LogoRestaurant(props) {
  return (
    <ContentLogo
      logoOpacity={props.logoOpacity}
      height={props.height}
      width={props.height}
    >
      <Img src={`${s3PathImageHandrer}/${window.imageShark(
              props.path_logo,
              props.height *2,
              props.height *2
                      )}`} alt="logo" />
    </ContentLogo>
  );
}

export default LogoRestaurant;
