import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";
import clsx from "clsx";
import { graySecond } from "../../commons/theme";

const useStyles = makeStyles({
  root: props => ({
    height: 38,
    marginBottom: 6,
    marginTop: 6,
    minWidth: 220,
    borderRadius: 50,
    border: `2px solid ${graySecond}`,
    "&::placeholder": {
      color: "gray",
    },
    "&:hover": {
      borderColor: graySecond,
      border: "1px solid",
      borderRightWidth: "none",
    },
  }),
});

export default function InputField(props) {
  const classes = useStyles(props);

  return <Input {...props} className={clsx(classes.root, props.className)} />;
}
