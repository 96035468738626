/* eslint-disable react-hooks/exhaustive-deps */
import { SlideDialog } from "../";
import { matchPath } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SELECTOR_PRODUCTSELECTED } from "../../redux/selectors/product-selected";

const PRODUCT_MATCH = "p-:p_id";

export const PRODUCT_ROUTES_MATCH = [
  `/${PRODUCT_MATCH}`,
  "/:id",
  `/:id/${PRODUCT_MATCH}`,
];

export function getProductData(path) {
  return matchPath(path, {
    exact: true,
    path: PRODUCT_ROUTES_MATCH,
  });
}

function ProductDialog(props) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const productSelected = useSelector(SELECTOR_PRODUCTSELECTED);

  useEffect(() => {
    const data = getProductData(location.pathname);
    if (data?.params?.p_id && !open) {
      setOpen(true);
    } else {
      if (!data?.params?.p_id && open) {
        setOpen(false);
      }
    }
  }, [location.pathname, productSelected]);

  function handleClose() {
    const data = getProductData(location.pathname);

    history.push(
      `/${data?.params?.id ? `${data.params.id}` : ""}${location.search || ""}`
    );
  }

  return (
    <SlideDialog handleClose={handleClose} openModal={open}>
      {props.children}
    </SlideDialog>
  );
}

export default ProductDialog;
