import React, { useEffect, useState, Fragment } from 'react';
import {
  letterSpacing,
  greenSuccess,
  graySecond,
} from '../../components/commons/theme';
import { AddCreditCard } from '../../components';
import { Divider } from '../../components/commons';
import { makeStyles } from '@material-ui/core/styles';
import { s3Path } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethodSet } from '../../redux/actions/payment_method';
import { CreditCardsSet } from '../../redux/actions/credit-cards';
import { ordersService, userCreditCardService } from '../../api/feathers';
import { useRouteMatch } from 'react-router-dom';
import { SELECTOR_CREDIT_CARDS } from '../../redux/selectors/credit-cards';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { SELECTOR_PAYMENT_METHOD } from '../../redux/selectors/payment-method';
import PaymentMethodItem from '../../components/Payment-method-item';

const useStyles = makeStyles((theme) => ({
  root: {},
  titleMain: {
    letterSpacing,
    fontWeight: '500',
  },
  contendList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 50,
    alignItems: 'flex-end',
  },
  contentInput: {
    marginBottom: 30,
  },
  contentCreditCard: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  contentImage: {
    paddingRight: 12,
    marginLeft: 10,
  },
  description: {
    textTransform: 'capitalize',
    width: '100%',
  },
  buttonBack: {
    height: 36,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px ${greenSuccess} solid`,
    color: greenSuccess,
    justifyContent: 'center',
    fontWeight: '500',
    fontSize: 16,
    borderRadius: 20,
  },
}));

function PaymentsMethods({
  handleCloseDialogFullScreen,
  restaurant,
  closedFullScreen,
  showTitle,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState({});
  const match = useRouteMatch('/checkout/:id');
  const [showFrom, setShowFrom] = useState(false);
  const creditCards = useSelector(SELECTOR_CREDIT_CARDS);
  const paymentMethod = useSelector(SELECTOR_PAYMENT_METHOD);

  const dispatch = useDispatch();

  const getOrder = () => {
    ordersService
      .find({ query: { id: match.params.id } })
      .then((it) => {
        setOrder(it.data[0]);
      })
      .catch((it) => console.log(it.message));
  };

  //buscar la tarjeta de credito por defecto

  const getCreditCards = () => {
    userCreditCardService
      .find({ query: { $limit: 50000 } })
      .then((it) => {
        dispatch(CreditCardsSet(it.data));
      })
      .catch((it) => console.log(it.message));
  };

  const setPaymentMethod = (method) => {
    dispatch(PaymentMethodSet(method));
    if (closedFullScreen) handleCloseDialogFullScreen();
  };

  const updateCreditCardMain = (id) => {
    userCreditCardService.patch(id, { default: 'true' }).then((it) => {
      getCreditCards();
      dispatch(PaymentMethodSet('credit_card'));
      if (closedFullScreen) handleCloseDialogFullScreen();
    });
  };

  useEffect(() => {
    getOrder();
    getCreditCards();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {showTitle ? (
          <h3 className={classes.titleMain}>Selecciona un metodo de pago.</h3>
        ) : null}
        {showFrom ? (
          <AddCreditCard
            handleCloseDialogFullScreen={handleCloseDialogFullScreen}
            getCreditCards={getCreditCards}
            closedFullScreen={false}
            setPaymentMethod={setPaymentMethod}
            setShowFrom={setShowFrom}
          />
        ) : (
          <Fragment>
            {restaurant?.payment_cash_on_delivery === 'active' &&
              order.type !== 'pickup' && (
                <PaymentMethodItem
                  paymentMethod="cash_on_delivery"
                  pathImg={`${s3Path}/static/payment-methods/cash_on_delivery.svg`}
                  name="Pago en efectivo contra entrega"
                  setPaymentMethod={setPaymentMethod}
                  showCheckbox={true}
                />
              )}
            {restaurant?.payment_dataphone_on_delivery === 'active' &&
              order.type !== 'pickup' && (
                <PaymentMethodItem
                  paymentMethod="dataphone_on_delivery"
                  pathImg={`${s3Path}/static/payment-methods/dataphone_on_delivery.svg`}
                  name="Pago con datafono contra entrega"
                  setPaymentMethod={setPaymentMethod}
                  showCheckbox={true}
                />
              )}
            {order.type === 'pickup' &&
              restaurant?.payment_on_pickup === 'active' && (
                <PaymentMethodItem
                  paymentMethod="payment_on_pickup"
                  pathImg={`${s3Path}/static/payment-methods/payment_on_pickup.svg`}
                  name="Pago en el establecimiento"
                  setPaymentMethod={setPaymentMethod}
                  showCheckbox={true}
                />
              )}
            {order.type !== 'pickup' &&
              restaurant?.payment_on_pickup === 'active' && (
                <PaymentMethodItem
                  paymentMethod="payment_on_pickup"
                  pathImg={`${s3Path}/static/payment-methods/payment_on_pickup.svg`}
                  name="Pago en el establecimiento"
                  setPaymentMethod={setPaymentMethod}
                  showCheckbox={true}
                />
              )}
            {restaurant?.bank_transfer === 'true' && (
              <PaymentMethodItem
                paymentMethod="bank_transfer"
                pathImg={`${s3Path}/static/payment-methods/bank_transfer.svg`}
                name="Transferencia bancaria"
                setPaymentMethod={setPaymentMethod}
                showCheckbox={true}
              />
            )}
            {restaurant?.payment_gateway_pse === 'active' && (
              <PaymentMethodItem
                paymentMethod="pse"
                pathImg={`${s3Path}/static/payment-methods/pse.png`}
                name="PSE"
                height={60}
                width={80}
                setPaymentMethod={setPaymentMethod}
                showCheckbox={true}
              />
            )}
            {restaurant?.payment_gateway_bancolombia === 'active' && (
              <PaymentMethodItem
                paymentMethod="bancolombia_transfer"
                pathImg={`${s3Path}/static/payment-methods/bancolombia.png`}
                name="Transferencia bancolombia"
                height={60}
                width={80}
                setPaymentMethod={setPaymentMethod}
                showCheckbox={true}
              />
            )}
            {restaurant?.payment_gateway_nequi === 'active' && (
              <PaymentMethodItem
                paymentMethod="nequi"
                pathImg={`${s3Path}/static/payment-methods/nequi.svg`}
                name="Pago por medio de nequi"
                height={60}
                width={80}
                setPaymentMethod={setPaymentMethod}
                showCheckbox={true}
              />
            )}
            {restaurant?.payment_gateway_credit_card === 'active' && (
              <>
                {creditCards.map((it) => (
                  <div>
                    <Divider
                      size={1}
                      width={'100%'}
                      backgroundColor={graySecond}
                      marginTop={5}
                      marginBottom={5}
                      style={{ marginLeft: 30 }}
                    />
                    <div
                      className={classes.contendList}
                      onClick={() => updateCreditCardMain(it.id)}
                    >
                      <div className={classes.contentCreditCard}>
                        <div className={classes.contentImage}>
                          <img
                            src={`${s3Path}/static/logos-tarjetas-de-credito/${it.brand}.png`}
                            height={40}
                            width={60}
                            alt="logo"
                          />
                        </div>
                        <div className={classes.description}>
                          <div>{it.owner_name}</div>
                          <div>{it.masked_number}</div>
                        </div>
                        <div>
                          {it.default === 'true' &&
                          (paymentMethod === false ||
                            paymentMethod === 'credit_card') ? (
                            <RadioButtonCheckedIcon
                              style={{ color: greenSuccess }}
                            />
                          ) : (
                            <div>
                              <RadioButtonUncheckedIcon
                                style={{ color: greenSuccess }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div onClick={() => setShowFrom(!showFrom)}>
                  <PaymentMethodItem
                    paymentMethod="new_credit_card"
                    pathImg={`${s3Path}/static/payment-methods/add_credit_card.svg`}
                    name="Agregar Tarjeta de credito"
                    setPaymentMethod={() => {}}
                    showCheckbox={false}
                  />
                </div>
              </>
            )}
          </Fragment>
        )}
        {showFrom && (
          <div style={{ marginTop: 16 }}>
            <div
              onClick={() => setShowFrom(!showFrom)}
              className={classes.buttonBack}
            >
              Volver
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentsMethods;
