import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { InputField } from "../components/commons/inputs";
import { LabelCommon, ButtonMain } from "../components/commons";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing } from "../components/commons/theme";
import { greenSuccess } from "../components/commons/theme";
import { usersService } from "../api/feathers";
import { SELECTOR_USER } from "../redux/selectors/auth";
import { useSelector, useDispatch } from "react-redux";
import { getAuth } from "../redux/actions/auth";
import { mainColor } from "../components/commons/theme";
import Divider from "../components/commons/divider";
import CheckboxInput from "../components/commons/checkbox";

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    marginBottom: 10,
    letterSpacing,
    fontWeight: "500",
  },
  suggestion: { zIndex: 1000 },
  ButtonMain: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  form: {
    marginTop: 10,
    zIndex: 50,
  },
  buttonMain: {
    marginTop: 20,
  },
  error: {
    color: "red",
  },
  header: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: { marginTop: 10 },
  contentTerms: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function FormUpdateUser(props) {
  const classes = useStyles(props);
  const user = useSelector(SELECTOR_USER);
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);

  const validate = values => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "Este campo es requerido";
    }

    if (!values.last_name) {
      errors.last_name = "Este campo es requerido";
    }

    if (!values.email) {
      errors.email = "Este campo es requerido";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Ingresa un email valido.";
    }

    if (!checked) setErrorTerms(true);

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: null,
      last_name: null,
      email: null,
    },
    validate,
    onSubmit: values => {
      if (!checked) {
        setErrorTerms(true);
        return;
      }

      usersService
        .patch(user.id, values)
        .then(it => {
          dispatch(getAuth(it));
          props.handleClose();
        })
        .catch(e => console.log(e.message));
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.contentTitle}>
          <div>Este es tu primer pedido</div>
        </div>
        <Divider
          size={2}
          width={125}
          backgroundColor={mainColor}
          marginTop={5}
          marginBottom={5}
          style={{ marginLeft: 30 }}
        />
        <div className={classes.message}>
          Solo esta vez te pediremos que completes tus datos y aceptes los
          terminos y condiciones para poder continuar.
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={classes.form}>
          <div className={classes.label}>
            <LabelCommon value={"Nombres"} />
            {!!formik.errors.first_name && formik.touched.first_name && (
              <div className={classes.error}>{formik.errors.first_name}</div>
            )}
          </div>
          <div>
            <InputField
              value={formik.values.first_name}
              onChange={formik.handleChange}
              name="first_name"
              style={{
                height: 40,
                borderRadius: 20,
                boxShadow: "rgb(0 0 0 / 12%) 0px 6px 51px -10px",
              }}
            />
          </div>
          <div className={classes.label}>
            <LabelCommon value={"Apellidos"} />
            {!!formik.errors.last_name && formik.touched.last_name && (
              <div className={classes.error}>{formik.errors.last_name}</div>
            )}
          </div>
          <div>
            <InputField
              value={formik.values.last_name}
              onChange={formik.handleChange}
              name="last_name"
              style={{
                height: 40,
                borderRadius: 20,
                boxShadow: "rgb(0 0 0 / 12%) 0px 6px 51px -10px",
              }}
            />
          </div>
          <div className={classes.label}>
            <LabelCommon value={"Email"} />
            {!!formik.errors.email && formik.touched.email && (
              <div className={classes.error}>{formik.errors.email}</div>
            )}
          </div>
          <div>
            <InputField
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              style={{
                height: 40,
                borderRadius: 20,
                boxShadow: "rgb(0 0 0 / 12%) 0px 6px 51px -10px",
              }}
            />
          </div>
          <div className={classes.contentTerms}>
            <CheckboxInput
              onClick={() => {
                setChecked(!checked);
                if (!checked) setErrorTerms(false);
              }}
              checked={checked}
            />
            <div className={errorTerms ? classes.error : null}>
              <div>
                {`Aceptar terminos y condiciones ${props.restaurantName} `}
                <span
                  onClick={() =>
                    history.push(`/terms?restaurantId=${props.restaurantId}`)
                  }
                >
                  (Ver)
                </span>
                {`y pocket Menu`}
                <span
                  onClick={() =>
                    history.push(`/terms?restaurantId=${props.restaurantId}`)
                  }
                >
                  (Ver)
                </span>
              </div>
            </div>
          </div>
          <div className={classes.buttonMain}>
            <ButtonMain
              type="submit"
              description="Guardar"
              color={greenSuccess}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
