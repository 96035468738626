import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {},
});

const HomeIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      color="white"
      height="25.083"
      width="22.673"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M421.936,211.786v238.533h-90.064V347.155c0-9.045-7.331-16.375-16.375-16.375H200.324
			c-9.045,0-16.375,7.331-16.375,16.375v103.164H94.431V211.786H61.681v254.908c0,9.045,7.331,16.375,16.375,16.375h122.269
			c9.045,0,16.375-7.331,16.375-16.375V363.531h82.422v103.164c0,9.045,7.331,16.375,16.375,16.375h122.815
			c9.045,0,16.375-7.331,16.375-16.375V211.786H421.936z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M506.815,255.508L269.373,33.351c-6.25-5.857-15.966-5.895-22.27-0.104L5.295,255.405
			c-6.659,6.119-7.096,16.474-0.977,23.133c3.226,3.521,7.636,5.3,12.063,5.3c3.957,0,7.931-1.43,11.075-4.318L258.085,67.635
			l226.355,211.787c6.616,6.184,16.965,5.83,23.144-0.77C513.758,272.047,513.419,261.687,506.815,255.508z"
          />
        </g>
      </g>
    </svg>
  );
};
export default HomeIcon;
