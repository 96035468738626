import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: (props) => ({
    height: props.size,
    width: props.width,
    backgroundColor: props.backgroundColor,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
  }),
});

export default function Divider(props) {
  const classes = useStyles(props);

  return <div className={classes.root}></div>;
}
