import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grayPrimary, greenSuccess, letterSpacing } from '../commons/theme';
import InputField from '../commons/inputs/InputField';
import SelectField from '../commons/inputs/SelectField';
import { Row, Col } from 'antd';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 12,
    letterSpacing,
    fontWeight: '500',
    fontSize: 12,
    alignItems: 'center',
  },
  textInfo: {
    color: greenSuccess,
    width: '100%',
    textAlign: 'center',
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: '500',
    fontSize: 10,
  },
  contentIntructions: {
    marginTop: 10,
    marginBottom: 16,
  },
  addMethod: {
    color: greenSuccess,
  },
  buttonMain: {
    marginTop: 20,
  },
});

const FormPsePayments = ({ dataForm, getterDataForm }) => {
  const classes = useStyles();
  const [institution, setInstitution] = useState([]);

  async function getInstitutions() {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append(
      'Authorization',
      'Bearer prv_prod_3Y2P9XpcAAeUBxcNrvefXgtIEVEahiXx'
    );
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    try {
      const rowResponse = await fetch(
        'https://production.wompi.co/v1/pse/financial_institutions',
        requestOptions
      );
      const response = await rowResponse.json();

      setInstitution(
        response.data.map((it) => ({
          id: it?.financial_institution_code,
          name: it?.financial_institution_name,
        }))
      );
    } catch (e) {
      console.log('Error getInstitutions', e.message);
    }
  }

  useEffect(() => {
    getInstitutions().catch();
  }, []);

  return (
    <div className={classes.content}>
      <p className={classes.title}>Informacion financiera</p>
      <Row gutter={[24, 12]}>
        <Col span={24} align="center">
          <SelectField
            placeholder="Institucion financiera"
            choices={institution}
            style={{ width: '80%' }}
            name="financial_institution_code"
            onChange={(e) =>
              getterDataForm({ ...dataForm, financial_institution_code: e })
            }
          />
        </Col>
        <Col span={24} align="center">
          <SelectField
            style={{ width: '80%' }}
            placeholder="Tipo de usuario"
            choices={[
              { id: '0', name: 'Natural' },
              { id: '1', name: 'Juridica' },
            ]}
            name="user_type"
            onChange={(e) => getterDataForm({ ...dataForm, user_type: e })}
          />
        </Col>

        <Col span={24} align="center">
          <SelectField
            style={{ width: '80%' }}
            placeholder="Tipo de documento"
            choices={[
              { id: 'CC', name: 'Cedula de ciudadania' },
              { id: 'CE', name: 'Cedula de extranjería' },
              { id: 'NIT', name: 'NIT' },
            ]}
            name="user_legal_id_type"
            onChange={(e) =>
              getterDataForm({ ...dataForm, user_legal_id_type: e })
            }
          />
        </Col>
        <Col span={24} align="center">
          <InputField
            placeholder="Numero de documnto"
            style={{ width: '82%' }}
            name="user_legal_id"
            onChange={(e) => {
              const { name, value } = e.target;
              getterDataForm({ ...dataForm, [name]: value });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FormPsePayments;
