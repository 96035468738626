import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export default function CheckboxInput(props) {
  const [checked, setChecked] = React.useState(false);

  return (
    <div>
      <Checkbox
        checked={props.checked}
        onClick={() => props.onClick()}
        size="small"
      />
    </div>
  );
}
