import React, { Fragment, useState } from "react";
import S3Uploader from "react-s3-uploader";
import { HOST_API, PROTOCOL_HTTP, s3Path } from "../../utils/constants";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import { grayPrimary, graySecond } from "../../components/commons/theme";
import EditIcon from "@material-ui/icons/EditOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  dropZone: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    border: ({ errors }) => `1px dashed ${errors ? "red" : grayPrimary}`,
    backgroundColor: graySecond,
    minHeight: 130,
  },
  img: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  contentImg: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    width: "100%",
    background: "white",
    position: "relative",
  },
  editIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 100000,
  },
  backdrop: {
    backgroundColor: "rgba(237, 237, 237, 0.6)",
    height: 130,
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function S3Field({
  label,
  idComponent,
  path,
  id,
  uploadEvidence,
  preview,
  errors,
}) {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles({ errors });

  const onUploadStart = (...props) => {};

  const onSignedUrl = (...props) => {};

  const onUploadProgress = (progress, ...props) => {
    setProgress(progress);
    setLoading(true);
  };

  const onUploadError = (error, progress) => {
    console.log(error);
  };

  const onUploadFinish = urls => {
    setLoading(false);
    uploadEvidence(id, urls);
  };

  return (
    <div className={classes.root}>
      <div className={classes.dropZone}>
        <label htmlFor={idComponent} className={classes.label}>
          {preview && preview != "null" ? (
            <div className={classes.contentImg}>
              <div className={classes.closeIcon}>
                <EditIcon className={classes.editIcon} color="red" />
              </div>
              <img className={classes.img} src={`${s3Path}/${preview}`} />
            </div>
          ) : (
            <>
              <IconButton aria-label="delete" size="small" component="span">
                <CloudUploadIcon fontSize="large" />
              </IconButton>
              <div>{label}</div>
            </>
          )}
        </label>
      </div>
      <label style={{ display: "none" }}>
        <S3Uploader
          id={idComponent}
          signingUrl="/s3Client/sign"
          signingUrlMethod="GET"
          accept="image/*"
          s3path={path}
          // preprocess={() => onUploadStart()}
          // onSignedUrl={() => onSignedUrl()}
          onProgress={e => onUploadProgress(e)}
          onError={e => onUploadError(e)}
          onFinish={e => onUploadFinish(e)}
          signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
          uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
          contentDisposition="auto"
          scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, "")}
          server={`${PROTOCOL_HTTP}://${HOST_API}`}
          // inputRef={(cmp) => (this.uploadInput = cmp)}
          autoUpload={true}
          className="s3-uploader"
          style={{ visibility: "hidden" }}
        />
      </label>
      {loading && (
        <div className={classes.backdrop}>
          <CircularProgress variant="determinate" value={progress} />
        </div>
      )}
    </div>
  );
}
