import React from 'react';
import {
  greenSuccess,
  graySecond,
  grayPrimary,
} from '../components/commons/theme';
import { Divider } from '../components/commons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { SELECTOR_PAYMENT_METHOD } from '../redux/selectors/payment-method';

const useStyles = makeStyles((theme) => ({
  root: {},
  contendList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 50,

    alignItems: 'flex-end',
  },
  contentItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 6,
    width: '100%',
    alignItems: 'center',
    textAlign: 'left',
  },
  description: {
    textTransform: 'capitalize',
    color: grayPrimary,
  },
}));

function PaymentMethodItem({
  paymentMethod,
  pathImg,
  name,
  description,
  setPaymentMethod,
  showCheckbox,
  height = 60,
  width = 80,
}) {
  const classes = useStyles();

  const currentPaymentMethod = useSelector(SELECTOR_PAYMENT_METHOD);

  return (
    <div className={classes.root}>
      <Divider
        size={1}
        width={'100%'}
        backgroundColor={graySecond}
        marginTop={5}
        marginBottom={5}
        style={{ marginLeft: 30 }}
      />
      <div
        className={classes.contendList}
        onClick={() => setPaymentMethod(paymentMethod)}
      >
        <div className={classes.contentItem}>
          <div>
            <div className={classes.contentImage}>
              <img src={pathImg} height={height} width={width} alt="logo" />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div>{name}</div>
            <div className={classes.description}>{description}</div>
          </div>
          {showCheckbox ? (
            <div>
              {currentPaymentMethod === paymentMethod ? (
                <RadioButtonCheckedIcon style={{ color: greenSuccess }} />
              ) : (
                <div>
                  <RadioButtonUncheckedIcon style={{ color: greenSuccess }} />
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodItem;
