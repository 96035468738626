import React, { useEffect, useState } from 'react';
import Numeral from 'numeral';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { letterSpacing, grayPrimary } from '../../components/commons/theme';
import { AlertTitle } from '../../components/commons/';
import { ordersService, reviewsService } from '../../api/feathers';
import { s3PathImageHandrer } from '../../utils/constants';
import {
  TimeLineIcons,
  TimeLine,
  RestaurantListProductsTraking,
  ShippingAddressInfo,
  ContactInfo,
  PaymentMethodTracking,
} from '../../components/';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { useHistory, useLocation } from 'react-router';
import { Review } from '../../components/review';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 50,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    letterSpacing,
    width: '100%',
    padding: '10px 20px 10px 20px',
    justifyContent: 'center',
  },
  titleMain: {
    alignItems: 'center',
    marginTop: -10,
  },
  containerTimeLineIcons: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
  },
  containerTimeLine: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
  },
  containerRestaurantListProductsTraking: {
    width: '100%',
  },
  containerShippingAddress: {
    width: '100%',
    marginTop: 10,
    padding: '20px 0px 20px 0px',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  contentTimeLines: {
    width: '100%',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
    marginTop: 20,
  },
  containerTotals: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right',
  },
  items: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contendTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    marginTop: 20,
    '& h1': {
      marginBottom: 0,
    },
    '& >div': {
      letterSpacing,
      color: grayPrimary,
      fontWeight: 100,
    },
  },
  contentTitleLogo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentLogo: {
    borderRadius: 50,
    overflow: 'hidden',
  },
  alertTitle: { width: '100%', margin: '20px 10px 10px 10px' },
}));

function OrderDetails(props) {
  const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch('/order/:id');
  const [order, setOrder] = useState({});
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const [reviewState, setReviewState] = useState({
    loading: false,
    called: false,
    data: null,
  });
  const getOrder = () => {
    ordersService
      .get(match.params.id)
      .then((it) => {
        setOrder(it);
      })
      .catch((e) => console.log(e.message));
  };

  const getReview = async () => {
    try {
      setReviewState({
        loading: true,
        ...reviewState,
      });
      const resp = await reviewsService.find({
        query: {
          order_id: order?.id,
        },
      });
      setReviewState({
        loading: false,
        called: true,
        data: resp?.data?.[0],
      });
    } catch (err) {
      setReviewState({
        loading: false,
        called: true,
        data: null,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);
  console.log(order);
  useEffect(() => {
    ordersService.on('patched', (data) => {
      if (data.id === order.id) setOrder(data);
    });
    if (order.id) {
      getReview();
    }
  }, [order]);

  const redirect = () => {
    params.newOrder
      ? history.push(`/r-${order.restaurant?.slug}`)
      : history.goBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.contendTitle}>
          <div className={classes.contentTitleLogo}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div onClick={() => redirect()}>
                <ArrowBackSharpIcon
                  style={{
                    color: 'white',
                    backgroundColor: grayPrimary,
                    borderRadius: '50%',
                    fontSize: 26,
                  }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <h1 style={{ fontSize: 30 }}>{`Pedido #${order.id}`}</h1>
                <div className={classes.titleMain}>
                  Pedido
                  {order?.type === 'delivery'
                    ? ' a domicilio'
                    : order?.type === 'pickup'
                    ? ' contraentrega'
                    : ''}
                </div>
              </div>
            </div>
            <div
              className={classes.contentLogo}
              logoOpacity={props.logoOpacity}
            >
              <img
                className={classes.imgProduct}
                src={`${s3PathImageHandrer}/${window.imageShark(
                  order.restaurant?.path_logo
                    ? order.restaurant.path_logo
                    : 'static/suenadeli.png',
                  200,
                  200
                )}`}
                alt="logo"
                height={'60px'}
                width={'60px'}
              />
            </div>
          </div>
        </div>

        {order.payment_status === 'rejected' &&
        order.status !== 'waiting_gateway_confirmation' ? (
          <div className={classes.alertTitle}>
            <AlertTitle
              severity="error"
              title="Error en el pago"
              message={
                order?.payments[0]?.message
                  ? order?.payments[0]?.message
                  : 'Error en el pago'
              }
            />
          </div>
        ) : order.status === 'waiting_gateway_confirmation' ? (
          <div className={classes.alertTitle}>
            <AlertTitle
              severity="warning"
              title="Esperando confirmación de pago"
              message={
                'La pasarela de pago del establecimiento esta confirmando el pago. Te notificaremos una vez tengamos una respuesta.'
              }
            />
          </div>
        ) : order.status === 'canceled_by_establishment' ? (
          <div className={classes.alertTitle}>
            <AlertTitle
              severity="error"
              title="Cancelado por el establecimiento"
              message={order.canceled_reason}
            />
          </div>
        ) : (
          <div className={classes.contentTimeLines}>
            <div className={classes.containerTimeLineIcons}>
              <TimeLineIcons status={order.status} type={order.type} />
            </div>
            <div className={classes.containerTimeLineIcons}>
              <TimeLine status={order.status} type={order.type} />
            </div>
          </div>
        )}
        {!reviewState?.loading &&
          reviewState?.called &&
          order?.status === 'completed' && (
            <div className={classes.containerShippingAddress}>
              <Review order={order} review={reviewState} />
            </div>
          )}
        <div className={classes.containerShippingAddress}>
          <ContactInfo order={order} />
        </div>
        <div className={classes.containerShippingAddress}>
          <ShippingAddressInfo order={order} />
        </div>
        <div className={classes.containerShippingAddress}>
          <PaymentMethodTracking order={order} getOrder={getOrder} />
        </div>
        <div className={classes.containerRestaurantListProductsTraking}>
          <RestaurantListProductsTraking order={order} />
        </div>
        <div className={classes.containerTotals}>
          <div className={classes.items}>
            <div>Productos:</div>
            <div>
              {Numeral(
                order?.total_tax_incl - order?.total_shipping - order?.total_tip
              ).format('($0,0)')}
            </div>
          </div>
          <div className={classes.items}>
            <div>Propina:</div>
            <div>{Numeral(order?.total_tip).format('($0,0)')}</div>
          </div>
          <div className={classes.items}>
            <div>Envío:</div>
            <div>{Numeral(order?.total_shipping).format('($0,0)')}</div>
          </div>
          <div className={classes.items}>
            <div>Total:</div>
            <div>{Numeral(order?.total_tax_incl).format('($0,0)')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
