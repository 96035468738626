import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { SELECTOR_SHOW_ORDERS } from '../redux/selectors/show-orders';
import { useSelector, useDispatch } from 'react-redux';
import { ordersService } from '../api/feathers';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LogoRestaurant from '../components/commons/Logo-restaurant';
import { grayPrimary } from '../components/commons/theme';
import { useHistory } from 'react-router-dom';
import { SELECTOR_UPDATE_ORDERS } from '../redux/selectors/update-orders';
import { CurrentShoppingCartSet } from '../redux/actions/current-shopping-cart';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDialog-container': {
      transition: `height 4s, ${props.height}`,
      width: '100%',
      height: props.height,
    },
    // "& .MuiDialogContent-root": {
    //   padding: "20px 10px",
    // },
  }),
  contentTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 18,
  },
  note: {
    fontSize: 14,
    marginTop: -20,
  },
  accordion: {
    marginTop: 10,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  contentRestaurant: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Message = (props) => (
  <>
    {props.restaurant.type == 'instore'
      ? 'PEDIDO EN EL RESTAURANTE'
      : props.restaurant.type == 'delivery'
      ? 'PEDIDO A DOMICILIO'
      : 'PEDIDO PICKUP'}
  </>
);

export default function AlertDialogSlide(props) {
  const showOrders = useSelector(SELECTOR_SHOW_ORDERS);
  const updateOrders = useSelector(SELECTOR_UPDATE_ORDERS);
  const [orders, setOrders] = useState([]);
  const [height, setHeigth] = useState('50%');
  const history = useHistory();
  const classes = useStyles({ height });
  const dispatch = useDispatch();

  useEffect(() => {
    ordersService.find({ query: { status: 'creation' } }).then((it) => {
      setOrders(it.data);
      dispatch(CurrentShoppingCartSet());
    });
  }, [updateOrders]);

  const onClose = () => {
    setHeigth('50%');
    props.handleClose();
  };

  const checkout = (id, orderType) => {
    props.handleClose();
    history.push(`./checkout/${id}?type=${orderType}`);
  };

  return (
    <div>
      <Dialog
        className={classes.root}
        fullScreen
        fullWidth
        open={showOrders}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div className={classes.content}>
            <div className={classes.contentTitle}>
              <p>TIENES {orders.length} PEDIDOS ABIERTOS</p>
              <span className={classes.note}>Elige al que deseas ingresar</span>
            </div>
            <div className={classes.lists}>
              {orders.map((it, index) => (
                <div
                  className={classes.accordion}
                  key={`orders${index}`}
                  onClick={() => checkout(it.id, it.type)}
                >
                  <AccordionSummary
                    expandIcon={<ChevronRightIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      <div className={classes.contentRestaurant}>
                        <div>
                          <LogoRestaurant
                            path_logo={it.restaurant.path_logo}
                            height={70}
                            width={70}
                          />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          <div>{it.restaurant.name}</div>
                          <div
                            style={{
                              marginTop: -2,
                              fontSize: 12,
                              color: grayPrimary,
                            }}
                          >
                            {it.restaurant.address}
                          </div>
                          <div
                            style={{
                              marginTop: -2,
                              fontSize: 12,
                              color: grayPrimary,
                            }}
                          >
                            <Message restaurant={it} />
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
