import React, { useEffect, useState } from 'react';
import Numeral from 'numeral';
import { Addresses, PaymentsMethods } from '../index';
import {
  letterSpacing,
  grayPrimary,
  greenSuccess,
} from '../../components/commons/theme';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { SELECTOR_ADDRESSES } from '../../redux/selectors/addresses';
import { SELECTOR_PAYMENT_METHOD } from '../../redux/selectors/payment-method';
import { SELECTOR_CREDIT_CARDS } from '../../redux/selectors/credit-cards';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RestaurantListProductCheckout, AmoutTip } from '../../components/';
import {
  CardPaymentsMethods,
  FullScreenDialog,
} from '../../components/commons';
import {
  getService,
  ordersService,
  searchShippingCostsService,
  userAddressesService,
  userCreditCardService,
} from '../../api/feathers';
import { ShippingAddress, PickupRestaurant } from '../../components';
import { CreditCardsSet } from '../../redux/actions/credit-cards';
import { AddressesSet } from '../../redux/actions/addresses';
import Tooltip from '@material-ui/core/Tooltip';
import OpenAlerDialog from '../../components/commons/open-alert-dialog';
import FormUpdateUser from '../../components/form-update-user';
import Snackbar from '../../components/commons/snackbars';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 50,
    height: 'calc(100vh - 73px)',
    overflow: 'auto',
  },
  contentRestaurant: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
    letterSpacing,
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  goBack: {
    zIndex: 10,
    position: 'absolute',
    marginLeft: 20,
    marginTop: 6,
  },
  contendAddress: {
    padding: '10px 20px',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: '500',
    fontSize: 10,
  },
  amoutTip: {
    padding: '20px 20px',
    margin: '20px 0px',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  paymentMethod: {
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
    padding: '20px 20px',
    margin: '20px 0px',
    marginBottom: '100px',
  },
  btnPay: {
    width: '100%',
    height: '40px',
    backgroundColor: ({
      paymentMethod,
      creditCardMain,
      shippingCost,
      bankTransferEvidencePath,
      order,
      dataPaymentPse,
      shippingFree
    }) =>
      paymentMethod === 'credit_card' && creditCardMain && (shippingCost || shippingFree)
        ? greenSuccess
        : paymentMethod === 'cash_on_delivery' && (shippingCost || shippingFree)
          ? greenSuccess
          : paymentMethod === 'dataphone_on_delivery' && (shippingCost || shippingFree)
            ? greenSuccess
            : paymentMethod === 'payment_on_pickup' && (shippingCost || shippingFree)
              ? greenSuccess
              : paymentMethod === 'bank_transfer' &&
                bankTransferEvidencePath &&
              (shippingCost || shippingFree)
                ? greenSuccess
                : order.type === 'pickup'
                  ? greenSuccess
                  : paymentMethod === 'pse' && Object.keys(dataPaymentPse).length >= 4
                    ? greenSuccess
                    : paymentMethod === 'bancolombia_transfer'
                      ? greenSuccess
                      : paymentMethod === 'nequi' && Object.keys(dataPaymentPse).length >= 1
                        ? greenSuccess
                        : grayPrimary,
    color: 'white',
    borderRadius: '10px',
    boxShadow: '2px 2px 3px #999',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: 20,
    letterSpacing,
  },
  contendBtnPay: {
    position: 'fixed',
    bottom: '20px',
    width: '100%',
    padding: '0px 20px',
  },
}));

function Ckeckout(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [amoutTip, setAmoutTip] = useState(0);
  const match = useRouteMatch('/checkout/:id');
  const [totalOrder, setTotalOrder] = useState(0);
  const addresses = useSelector(SELECTOR_ADDRESSES);
  const [shippingFree, setShippingFree] = useState(false);
  const user = useSelector(SELECTOR_USER);
  const paymentMethod = useSelector(SELECTOR_PAYMENT_METHOD);
  const [addressMain, setAddressMain] = useState({});
  const [openAddress, setOpenAddress] = useState(false);
  const [shippingCost, setShippingCost] = useState(null);
  const creditCards = useSelector(SELECTOR_CREDIT_CARDS);
  const [messageBtnPay, setMenssageBtnPay] = useState([]);
  const [creditCardMain, setCreditCardMain] = useState({});
  const [openCreditCard, setOpenCreditCard] = useState(false);
  const [openUpdateUser, SetOpenUpdateuser] = useState(false);
  const [paymentMethodState, setPaymentMethodState] = useState({ brand: null });
  const [bankTransferEvidencePath, setBankTransferEvidencePath] =
    useState(null);
  const [message, setMessage] = useState({ show: false });
  const [dataPaymentPse, setDataPaymentPse] = useState({});
  const [partchedDataPayment, setPartchedDataPayment] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const classes = useStyles({
    paymentMethod,
    creditCardMain,
    bankTransferEvidencePath,
    shippingCost,
    order,
    dataPaymentPse,
    shippingFree
  });

  const getCreditCard = () => {
    userCreditCardService.find({ query: { $limit: 50 } }).then((it) => {
      dispatch(CreditCardsSet(it.data));
    });
  };

  const setBankTransferEvidence = (id, urls) => {
    setBankTransferEvidencePath(urls.fileKey);
    getOrder();
  };

  const getAddresses = () => {
    setAddressMain({});
    userAddressesService.find({ query: { $limit: 500 } }).then((it) => {
      dispatch(AddressesSet(it.data));
      setAddressMain(it.data.find((it) => it.main === 'true'));
    });
  };

  const getOrder = () => {
    ordersService
      .find({ query: { id: match.params.id } })
      .then((it) => {
        if (!it.data.length || it.data[0].order_details.length === 0) {
          history.goBack();
          return;
        }
        setOrder(it.data[0]);
      })
      .catch((it) => console.log(it.message));
  };

  const getShippingCost = () => {
    if (Object.keys(order).length) {
      searchShippingCostsService
        .find({
          query: {
            user_address_id: addressMain?.id,
            restaurant_id: order.restaurant_id,
          },
        })
        .then((it) => {
          const shippingCost = it?.shippingCost?.free_shipping_minimum_order_amount ? parseInt(it?.shippingCost?.free_shipping_minimum_order_amount) :0;
          if(shippingCost && shippingCost <= order.total_order_in_creation){
            setShippingCost(0);
            setShippingFree(true)
          }else {
            setShippingCost(it.shippingCost);
          }
        })
        .catch((it) => {
          setShippingCost(null);
        });
    }
  };

  const newOrder = () => {
    const errors = getErrors();

    setMenssageBtnPay(errors);

    if (errors.length) return;

    let paramsToServer = {
      $client: {
        payment_method: paymentMethod,
      },
    };

    if (paymentMethod === 'credit_card') {
      paramsToServer.$client.credit_card_id = creditCardMain.id;
    } else if (paymentMethod === 'bank_transfer') {
      paramsToServer.$client.bank_transfer_evidence_path =
        bankTransferEvidencePath;
    }
    if (paymentMethod === 'pse' || paymentMethod === 'nequi') {
      paramsToServer.$client.paymentDataPse = { ...dataPaymentPse };
    }

    if (order.type === 'delivery') {
      paramsToServer['$client'].user_address_id = addressMain.id;
    }

    if (paymentMethod === 'pse' || paymentMethod === 'nequi') {
      ordersService
        .patch(
          order.id,
          {
            status: 'in_gateway',
            total_tip: amoutTip,
          },
          { query: paramsToServer }
        )
        .then((_) => {
          setLoadingPayment(true);
          setPartchedDataPayment(!partchedDataPayment);
        })
        .catch((e) =>
          setMessage({ message: e.message, show: true, severity: 'error' })
        );
    } else {
      ordersService
        .patch(
          order.id,
          {
            status:
              paymentMethod === 'bancolombia_transfer'
                ? 'in_gateway'
                : 'new',
            total_tip: amoutTip,
          },
          { query: paramsToServer }
        )
        .then((it) => {
          if (paymentMethod === 'bancolombia_transfer') setLoadingPayment(true);
          console.log(
            'paymentMethod',
            paymentMethod,
            'loadingPayment',
            loadingPayment
          );
          if (
            !['pse', 'nequi', 'bancolombia_transfer'].includes(paymentMethod)
          ) {
            history.push(`/order/${it.id}?newOrder=true`);
          }
          setPartchedDataPayment(!partchedDataPayment);
        })
        .catch((e) =>
          setMessage({ message: e.message, show: true, severity: 'error' })
        );
    }
  };

  useEffect(() => {
    getService('payments').on('created', (data) => {
      if (data.url) {
        window.location.href = data?.url;
      }
      if (data.payment.url) {
        window.location.href = data?.payment?.url;
        setLoadingPayment(false);
      }
    });
  }, [partchedDataPayment]);

  const getAmoutTip = (amoutTip) => {
    setAmoutTip(amoutTip);
  };

  const getErrors = () => {
    const result = [];
    if (
      !shippingCost &&
      !creditCardMain &&
      paymentMethod === 'credit_card' &&
      order.type !== 'pickup'
    )
      result.push([
        addressMain
          ? 'No tenemos cobertura para tu direcion'
          : 'Dirección de envio no seleccionada.',
        'Metodo de pago no seleccionado.',
      ]);
    else if (!creditCardMain && paymentMethod === 'credit_card')
      result.push(['Debes selecionar un metodo de pago.']);
    else if (!addressMain && order.type !== 'pickup') {
      //aquiii validar el tipo de orden
      result.push(['Debes agregar una dirección.']);
    } else if (addressMain && (!shippingCost && !shippingFree) && order.type !== 'pickup') {
      result.push(['No tenemos cobertura para tu dirion.']);
    } else if (!paymentMethod && order.type !== 'pickup') {
      result.push(['Metodo de pago no seleccionado.']);
    } else if (paymentMethod === 'bank_transfer' && !bankTransferEvidencePath) {
      result.push(['Debes subir el comprobante de pago.']);
    } else if (paymentMethod === 'pse' && !Object.keys(dataPaymentPse).lenght >= 4) {
      result.push(['Debe llenar todos los campos para poder realizar su pedido.']);
    } else if (paymentMethod === 'nequi' && !Object.keys(dataPaymentPse).lenght >= 1) {
      result.push(['Debe proporciar su numero de telefono.']);
    }
    return result;
  };

  const switchUpdateUser = () => {
    if (!user.first_name || !user.last_name || !user.email) return true;
    return false;
  };

  useEffect(() => {
    getOrder();
    getCreditCard();
  }, []);

  useEffect(() => {
    getAddresses();
  }, [order]);

  useEffect(() => {
    setAddressMain(addresses.find((it) => it.main === 'true'));
  }, [addresses]);

  useEffect(() => {
    setCreditCardMain(creditCards?.find((it) => it.default === 'true'));
  }, [creditCards]);

  useEffect(() => {
    getShippingCost();
  }, [addressMain]);

  useEffect(() => {
    function deleteTooltip(evt) {
      const flyoutElement = document.getElementById('btn-pay');
      let targetElement = evt && evt.target; // clicked element

      if (!flyoutElement.contains(targetElement)) {
        setMenssageBtnPay([]);

        return '';
      }
    }

    document.addEventListener('click', deleteTooltip, true);
    return () => {
      document.removeEventListener('click', deleteTooltip, true);
    };
  }, []);

  useEffect(() => {
    setTotalOrder(
      order?.total_order_in_creation +
      amoutTip +
      parseFloat(
        order?.type === 'delivery' && shippingCost ? shippingCost.price : 0
      )
    );
  }, [amoutTip, shippingCost, order]);

  useEffect(() => {
    switch (paymentMethod) {
      case 'credit_card':
        setPaymentMethodState({
          brand: creditCardMain?.brand,
          masked_number: creditCardMain?.masked_number,
        });
        break;
      case 'cash_on_delivery':
        setPaymentMethodState({
          brand: 'cash_on_delivery',
        });
        break;
      case 'bank_transfer':
        setPaymentMethodState({
          brand: 'bank_transfer',
        });
        break;

      case 'dataphone_on_delivery':
        setPaymentMethodState({
          brand: 'dataphone_on_delivery',
        });
        break;

      case 'payment_on_pickup':
        setPaymentMethodState({
          brand: 'payment_on_pickup',
        });
        break;
      case 'pse':
        setPaymentMethodState({
          brand: 'pse',
        });
        break;
      case 'nequi':
        setPaymentMethodState({
          brand: 'nequi',
        });
        break;
      case 'bancolombia_transfer':
        setPaymentMethodState({
          brand: 'bancolombia_transfer',
        });
        break;
      default:
        break;
    }
  }, [paymentMethod, creditCardMain]);

  const setTextButtonPay = () => {
    const PAID_TEXT = 'PAGAR ';
    const CASH_ON_DELIVERY_TEXT = 'HACER PEDIDO ';
    const BANK_TRANFER = 'HACER PEDIDO ';
    const DATAPHONE_ON_DELIVERY = 'HACER PEDIDO ';
    const PAYMENT_ON_PICKUP = 'HACER PEDIDO ';
    const PSE = 'HACER PEDIDO ';
    const NEQUI = 'HACER PEDIDO ';
    const BANCOLOMBIA = 'HACER PEDIDO ';

    if (paymentMethod === undefined) return '';
    if (paymentMethod === 'credit_card' && creditCardMain) return PAID_TEXT;
    if (paymentMethod === 'credit_card' && !creditCardMain) return '';
    if (paymentMethod === 'cash_on_delivery') return CASH_ON_DELIVERY_TEXT;
    if (paymentMethod === 'bank_transfer') return BANK_TRANFER;
    if (paymentMethod === 'dataphone_on_delivery') return DATAPHONE_ON_DELIVERY;
    if (paymentMethod === 'payment_on_pickup') return PAYMENT_ON_PICKUP;
    if (paymentMethod === 'pse') return PSE;
    if (paymentMethod === 'nequi') return NEQUI;
    if (paymentMethod === 'bancolombia_transfer') return BANCOLOMBIA;
  };

  return (
    <div className={classes.root}>
      <div className={classes.goBack} onClick={() => history.goBack()}>
        <ArrowBackSharpIcon
          style={{
            color: 'white',
            backgroundColor: grayPrimary,
            borderRadius: '50%',
            fontSize: 26,
          }}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.contentRestaurant}>
          <RestaurantListProductCheckout order={order} getOrder={getOrder} />
        </div>
        {order?.type === 'delivery' && (
          <div className={classes.contendAddress}>
            <ShippingAddress
              addressMain={addressMain}
              shippingCost={shippingCost}
              setOpenAddress={() => setOpenAddress(true)}
              shippingFree={shippingFree ? shippingFree : false}
            />
          </div>
        )}
        {order?.type === 'pickup' && (
          <div className={classes.contendAddress}>
            <PickupRestaurant order={order} />
          </div>
        )}
        {(addressMain || order?.type === 'pickup') && (
          <>
            <div className={classes.amoutTip}>
              <div className={classes.title}>PROPINA</div>
              <div>
                <AmoutTip
                  totalOrder={order ? order.total_order_in_creation : 0}
                  getAmoutTip={getAmoutTip}
                />
              </div>
            </div>
            <div className={classes.paymentMethod}>
              <div className={classes.title}>METODO DE PAGO</div>
              <div>
                {paymentMethodState?.brand === null && (
                  <PaymentsMethods
                    {...props}
                    restaurant={order.restaurant}
                    closedFullScreen={false}
                    showTitle={false}
                  />
                )}
                <CardPaymentsMethods
                  setOpenCreditCard={setOpenCreditCard}
                  brand={paymentMethodState?.brand}
                  masked_number={paymentMethodState?.masked_number}
                  order={order}
                  setBankTransferEvidence={setBankTransferEvidence}
                  preview={bankTransferEvidencePath}
                  errors={messageBtnPay.length}
                  setterDataPaymentPse={setDataPaymentPse}
                  dataPaymentPse={dataPaymentPse}
                />
              </div>
            </div>
          </>
        )}
        <div className={classes.contendBtnPay}>
          <Tooltip
            arrow
            open={messageBtnPay.length}
            aria-label="add"
            classes={{ tooltip: classes.customWidth }}
            title={messageBtnPay.map((it) => it)}
          >
            <div>
              {!loadingPayment && (
                <div
                  className={classes.btnPay}
                  onClick={() => {
                    if (paymentMethod === 'nequi') {
                      if (
                        dataPaymentPse?.phone_number &&
                        dataPaymentPse?.phone_number.length >= 10
                      ) {
                        switchUpdateUser()
                          ? SetOpenUpdateuser(true)
                          : newOrder();
                      }
                    } else if (paymentMethod === 'pse') {
                      if (Object.keys(dataPaymentPse).length >= 4) {
                        switchUpdateUser()
                          ? SetOpenUpdateuser(true)
                          : newOrder();
                      }
                    } else {
                      switchUpdateUser() ? SetOpenUpdateuser(true) : newOrder();
                    }
                  }}
                  id="btn-pay"
                >
                  {`${setTextButtonPay()}`}
                  {Numeral(totalOrder).format('($0,0)')}
                </div>
              )}

              {loadingPayment && (
                <div
                  style={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                  }}
                >
                  <CircularProgress />
                  <span>Espera por favor, estamos redireccionando...</span>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
      {openAddress && (
        <FullScreenDialog
          open={openAddress}
          handleClose={() => setOpenAddress(false)}
          childrens={(props) => (
            <Addresses {...props} restaurant={order.restaurant} />
          )}
        />
      )}
      {openCreditCard && (
        <FullScreenDialog
          open={openCreditCard}
          handleClose={() => setOpenCreditCard(false)}
          childrens={(props) => (
            <PaymentsMethods
              {...props}
              restaurant={order.restaurant}
              closedFullScreen={true}
              showTitle={true}
            />
          )}
        />
      )}
      <OpenAlerDialog
        childrens={
          <FormUpdateUser
            handleClose={() => SetOpenUpdateuser(false)}
            restaurantName={order?.restaurant?.name}
            restaurantId={order?.restaurant?.id}
          />
        }
        open={openUpdateUser}
        handleClose={() => SetOpenUpdateuser(false)}
        padding={'20px 20px'}
      />

      <Snackbar
        open={message.show}
        severity={'error'}
        message={message.message}
        handleClose={() => setMessage({ show: false })}
      />
    </div>
  );
}

export default Ckeckout;
