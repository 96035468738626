import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  HeaderCommon,
  UserAvatar,
  LabelCommon,
  ButtonFullWidth,
  Snackbar,
} from '../../components/commons';
import { InputField } from '../../components/commons/inputs';
import { mainColor } from '../../components/commons/theme';
import { EditIcon } from '../../components/icons/';
import { useDispatch, useSelector } from 'react-redux';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { Skeleton } from 'antd';
import S3Field from '../../components/S3-field';
import { s3PathImageHandrer } from '../../utils/constants';
import { updateUserInfo } from '../../redux/actions/auth';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  contendHeader: {
    width: '100%',
    display: 'flex',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  contendForm: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingRight: 50,
    paddingLeft: 50,
    marginBottom: 30,
  },
  buttonFullWidth: {
    width: '70%',
    pádding: '10px 30px',
    backgroundColor: '#fff',
    color: '#FFF',
    textAlign: 'center',
  },
  editAvatar: {
    position: 'absolute',
    marginBottom: -84,
    marginRight: -100,
    width: 36,
    height: 36,
    backgroundColor: mainColor,
    borderRadius: '50%',
    textAlign: 'center',
    alignItems: 'center',
  },
  label: {
    marginLeft: 10,
  },
});

function Profile(props) {
  const classes = useStyles(props);
  const user = useSelector(SELECTOR_USER);

  const [dataForm, setDataForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: '',
    type: '',
    show: false,
  });

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target || {};
    setDataForm({ ...dataForm, [name]: value });
  };

  const handleUploadFinish = (path) => {
    dispatch(updateUserInfo({ avatar_path: path }))
      .then(() => {
        setMessage({
          message: 'Imagen actualizada',
          type: 'success',
          show: true,
        });
      })
      .catch((err) => {
        setMessage({
          message: err?.message,
          type: 'error',
          show: true,
        });
      });
  };

  const handleSubmit = async () => {
    const { first_name, last_name, email } = dataForm || {};

    if (!first_name || !last_name) {
      setMessage({
        message: 'Verifica los campos requeridos',
        type: 'Error',
        show: true,
      });
      return;
    }
    dispatch(updateUserInfo({ first_name, last_name, email }))
      .then(() => {
        setMessage({
          message: 'Información actualizada',
          type: 'success',
          show: true,
        });
      })
      .catch((err) => {
        setMessage({
          message: err?.message,
          type: 'error',
          show: true,
        });
      });
  };

  useEffect(() => {
    if (!Object.keys(user).length) {
      setLoading(true);
    } else {
      setLoading(false);
      setDataForm({ ...user });
    }
  }, [user]);

  if (loading) {
    return (
      <div className={classes.root}>
        <div className={classes.contendHeader}>
          <HeaderCommon title={'MI PERFIL'} />
        </div>
        <div className={classes.container}>
          <div className={classes.contentAvatar}>
            <Skeleton.Avatar
              style={{ width: '150px', height: '150px' }}
              active
            />
          </div>
          <div className={classes.contendForm}>
            <div style={{ margin: '10px 0px' }}>
              <SkeletonInput active />
            </div>
            <div style={{ margin: '10px 0px' }}>
              <SkeletonInput active />
            </div>
            <div style={{ margin: '10px 0px' }}>
              <SkeletonInput active />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.contendHeader}>
        <HeaderCommon title={'MI PERFIL'} />
      </div>
      <S3Field
        path={`users/${user.id}`}
        handleUploadFinish={handleUploadFinish}
        id={'1'}
        idComponent="avatar"
        component={
          <div className={classes.contentAvatar}>
            <UserAvatar
              path={user.avatar_path}
              height={140}
              width={140}
              imgHeight={160}
            />
            <div className={classes.editAvatar}>
              <EditIcon width={20} height={'100%'} />
            </div>
          </div>
        }
      />

      <form className={classes.contendForm}>
        <div>
          <div className={classes.label}>
            <LabelCommon value="Nombres *" />
          </div>
          <InputField
            name="first_name"
            value={dataForm?.first_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <div className={classes.label}>
            <LabelCommon value="Apellidos *" />
          </div>
          <InputField
            name="last_name"
            value={dataForm?.last_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <div className={classes.label}>
            <LabelCommon value="Email" />
          </div>
          <InputField
            name="email"
            value={dataForm?.email}
            onChange={handleChange}
          />
        </div>
      </form>
      <div className={classes.buttonFullWidth}>
        <ButtonFullWidth
          style={{ borderRadius: '30px', height: '36px' }}
          onClick={() => handleSubmit()}
          description="GUARDAR CAMBIOS"
        />
      </div>
      <Snackbar
        open={message?.show}
        severity={message?.type}
        message={message?.message}
        handleClose={() => setMessage({ show: false, message: '', type: '' })}
      />
    </div>
  );
}

export default Profile;
