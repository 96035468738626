import React, { useEffect } from "react";
import { BottonNavigation } from "../components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BrowserRouter as Route } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    paddingBottom: 100,
  },
});

function Main(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
        {props.childrens}
      <BottonNavigation />
    </div>
  );
}

export default Main;
