import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  restaurant: {
    id: null
  },
};

const { Types, Creators } = createActions({
  setRestaurant: ["restaurant"],
});

const setRestaurant = (state, { restaurant }) => {
  return produce(state, (draftState) => {
    draftState.restaurant = restaurant;
  });
};

// noinspection JSCheckFunctionSignatures
export const restaurant = createReducer(INITIAL_STATE, {
  [Types.SET_RESTAURANT]: setRestaurant,
});

export default {
  Types,
  Creators,
};
