import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const DeliveryIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.673"
      height="25.083"
      viewBox="0 0 22.673 25.071"
    >
      <g id="bell" transform="translate(0.262 0.25)">
        <g id="Grupo_31" data-name="Grupo 31" transform="translate(7.805)">
          <g id="Grupo_30" data-name="Grupo 30" transform="translate(0)">
            <path
              id="Trazado_31"
              data-name="Trazado 31"
              d="M191.159,0a3.272,3.272,0,0,0-3.269,3.269v.986h1.691V3.269a1.578,1.578,0,0,1,3.156,0v.986h1.691V3.269A3.273,3.273,0,0,0,191.159,0Z"
              transform="translate(-187.89)"
              fill={props.color}
              stroke="#33313e"
              strokeWidth="0.5"
            />
          </g>
        </g>
        <g
          id="Grupo_33"
          data-name="Grupo 33"
          transform="translate(7.073 20.598)"
        >
          <g id="Grupo_32" data-name="Grupo 32" transform="translate(0)">
            <path
              id="Trazado_32"
              data-name="Trazado 32"
              d="M178.936,429.2a2.285,2.285,0,0,1-2.282,2.283H176.6a2.285,2.285,0,0,1-2.282-2.283h-1.691a3.978,3.978,0,0,0,3.973,3.973h.056a3.978,3.978,0,0,0,3.973-3.973Z"
              transform="translate(-172.624 -429.205)"
              fill={props.color}
              stroke="#33313e"
              strokeWidth="0.5"
            />
          </g>
        </g>
        <g id="Grupo_35" data-name="Grupo 35" transform="translate(0 3.128)">
          <g id="Grupo_34" data-name="Grupo 34">
            <path
              id="Trazado_33"
              data-name="Trazado 33"
              d="M47.263,82.328l-2.4-3.736V73.8a8.538,8.538,0,1,0-17.076,0v4.8l-2.4,3.736a.845.845,0,0,0,.711,1.3H46.552a.845.845,0,0,0,.711-1.3Zm-19.62-.388,1.7-2.643a.845.845,0,0,0,.134-.457V73.8a6.848,6.848,0,1,1,13.695,0V78.84a.845.845,0,0,0,.134.457L45,81.94Z"
              transform="translate(-25.25 -65.174)"
              fill={props.color}
              stroke="#33313e"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default DeliveryIcon;
