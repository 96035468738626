import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grayPrimary, greenSuccess, letterSpacing } from '../commons/theme';
import InputField from '../commons/inputs/InputField';
import { Row, Col } from 'antd';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 12,
    letterSpacing,
    fontWeight: '500',
    fontSize: 12,
    alignItems: 'center',
  },
  textInfo: {
    color: greenSuccess,
    width: '100%',
    textAlign: 'center',
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: '500',
    fontSize: 10,
  },
  contentIntructions: {
    marginTop: 10,
    marginBottom: 16,
  },
  addMethod: {
    color: greenSuccess,
  },
  buttonMain: {
    marginTop: 20,
  },
});

const FormPsePayments = ({ dataForm, getterDataForm }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <p className={classes.title}>Informacion financiera</p>
      <Row gutter={[24, 12]}>
        <Col span={24} align="center">
          <InputField
            placeholder="Numero celular"
            style={{ width: '82%' }}
            name="phone_number"
            onChange={(e) => {
              const { name, value } = e.target;
              getterDataForm({ ...dataForm, [name]: value });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FormPsePayments;
