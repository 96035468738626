import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { rawQuerysService } from "../../api/feathers";
import { greenSuccess } from "../../components/commons/theme";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {},
  contentTitle: {
    fontWeight: 500,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    backgroundColor: greenSuccess,
    color: "white",
    height: 34,
  },
});

export default function OrdersActive(props) {
  const classes = useStyles();
  const [ordersActive, setOrdersActive] = useState(0);
  const history = useHistory();

  useEffect(() => {
    rawQuerysService
      .find({
        query: {
          $client: { countOrderActive: "countOrderActive" },
        },
      })
      .then(it => {
        setOrdersActive(it[0] ? it[0].count : null);
      });
  }, []);

  return (
    <div className={classes.root}>
      {ordersActive > 0 ? (
        <div
          className={classes.contentTitle}
          onClick={() => history.push("/orders")}
        >
          <div>
            Tienes {`${ordersActive}`}{" "}
            {`${ordersActive > 1 ? "pedidos" : "pedido"}`} en curso
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
