import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  credit_cards: [],
};

const { Types, Creators } = createActions({
  setCreditCards: ["credit_cards"],
});

const setCreditCards = (state, { credit_cards }) => {
  return produce(state, (draftState) => {
    draftState.credit_cards = credit_cards;
  });
};

// noinspection JSCheckFunctionSignatures
export const credit_cards = createReducer(INITIAL_STATE, {
  [Types.SET_CREDIT_CARDS]: setCreditCards,
});

export default {
  Types,
  Creators,
};
