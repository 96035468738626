import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  product: {},
};

const { Types, Creators } = createActions({
  setProduct: ["product"],
});

const setProduct = (state, { product }) => {
  return produce(state, (draftState) => {
    draftState.product = product;
  });
};

// noinspection JSCheckFunctionSignatures
export const product = createReducer(INITIAL_STATE, {
  [Types.SET_PRODUCT]: setProduct,
});

export default {
  Types,
  Creators,
};
