import { useEffect } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  contenLogo: (props) => ({
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: props.width,
    height: props.height,
    borderRadius: props.borderRadius ? props.borderRadius : "none",
  }),
  img: (props) => ({
    width: "100%",
    height: props.imgHeight,
    objectFit: "cover",
    objectPosition: "center",
  }),
});

function Logo(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.contenLogo}>
      <img
        className={classes.img}
        src="https://menuapps3.s3.us-east-1.amazonaws.com/static/icon-poketmenu.png"
        alt="logo"
      />
    </div>
  );
}

export default Logo;
