import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import { termsAndCondicionsService } from "../../api/feathers";
import Snackbar from "../../components/commons/snackbars";
import BackIcon from "../../components/icons/back-icon";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "20px",
    padding: "0px 10px",
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    marginTop: 20,
  },
}));

export default function Terms(props) {
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const [restaurant, setRestaurant] = useState({});
  const history = useHistory();
  const [message, setMessage] = useState({ show: false });

  useEffect(() => {
    termsAndCondicionsService
      .find({ query: { restaurant_id: params.restaurantId } })
      .then(it => {
        if (!it.data[0]) {
          setMessage({
            message: "No se encontro el restaurante",
            show: true,
            severity: "error",
          });
          setTimeout(() => {
            history.push("/");
          }, 1000);
          return;
        }

        setRestaurant(it.data[0]);
      })
      .catch(e => console.log(e.message));
  }, []);

  return (
    <div className={classes.root}>
      <BackIcon />
      <div className={classes.content}>
        <div>
          <div className={classes.title}>Terminos y conciones restaurante</div>
          <div
            dangerouslySetInnerHTML={{ __html: restaurant.body_restaurant }}
          />
        </div>
        <div>
          <div className={classes.title}>Terminos y conciones Poket Menu</div>
          <div
            dangerouslySetInnerHTML={{ __html: restaurant.body_restaurant }}
          />
        </div>
      </div>
      <Snackbar
        open={message.show}
        severity={"error"}
        message={message.message}
        handleClose={() => setMessage({ show: false })}
      />
    </div>
  );
}
