import { ordersService } from "../../api/feathers";
import currentShoppingCart from "../reducers/current-shopping-cart";

export function CurrentShoppingCartSet() {
  return async dispatch => {
    ordersService
      .find({ query: { status: "creation" } })
      .then(it =>
        dispatch(currentShoppingCart.Creators.setCurrentShoppingCart(it.data))
      );
  };
}
