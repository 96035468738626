import React, { useEffect, useState } from 'react';
import {
  Logo,
  Dropdown,
  CardRestaurant,
  SkeletonCardRestaurant,
  OrderActive,
  Drawer,
  LateralMenu,
} from '../../components/commons/';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { s3PathImageHandrer } from '../../utils/constants';
import ButtonNavigation from '../../components/bottom-navigation';
import { letterSpacing, grayPrimary } from '../../components/commons/theme';
import {
  brandsService,
  citiesService,
  rawQuerysService,
} from '../../api/feathers';
import MenuIcon from '@material-ui/icons/Menu';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 50,
    height: 'calc(100vh - 60px)',
    overflow: 'auto'
    },
  header: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    '& > div': {
      border: '2px solid white',
      boxShadow: '0px 10px 16px -4px rgba(0,0,0,0.40)',
    },
  },
  contendSearch: {
    width: '100%',
    marginLeft: 30,
    marginRight: 30,
  },
  contendTitle: {
    marginTop: 20,
    '& >h1': {
      marginBottom: -8,
    },
    '& >div': {
      letterSpacing,
      color: grayPrimary,
      fontWeight: 100,
    },
  },
  contentCardRestaurant: {
    marginTop: 20,
  },
  contentCardRestaurantBottom: {
    marginTop: 5,
  },
  contentSkeleton: {
    '& > :first-child': {
      marginTop: 0,
    },
    '& > .MuiSkeleton-root': {
      marginTop: 0,
    },
  },
}));

function Home(props) {
  const classes = useStyles();
  const [brands, setBrands] = useState([]);
  const [cities, setCities] = useState([]);
  const [citySelectId, setCitySelectId] = useState(1);
  const [currentCityName, setCurrentCityName] = useState(null);
  const [skeleton, setSkeleton] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const user = useSelector(SELECTOR_USER);

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const getCityName = (city_id) => {
    const city = cities.filter((it) => it.id === city_id);
    setCurrentCityName(city[0] && city[0].name);
  };

  const getMenuItemsCount = (city_id) => {
    rawQuerysService
      .create({
        type: 'countCities',
        city_id,
      })
      .then((it) => {
        setMenuItems(it[0].count);
      })
      .catch((error) => console.log(error));
  };

  const getBranch = (city_id) => {
    brandsService
      .find({
        query: {
          $limit: 25,
          $sort: { createdAt: -1 }, 
          $client: {
            city_id,
          },
          status: 'active',
        },
      })
      .then((it) => {
        setBrands(it.data);
        setSkeleton(true);
      });
  };

  const getCities = () => {
    citiesService.find({ query: {} }).then((it) => {
      setCities(it.data);
    });
  };

  const handleChange = async (name, city_id) => {
    getBranch(city_id);
    setCitySelectId(city_id);
    getMenuItemsCount(city_id);
  };

  useEffect(() => {
    getCityName(citySelectId);
  }, [citySelectId]);

  useEffect(() => {
    getCities();
    getBranch(citySelectId);
    getMenuItemsCount(citySelectId);
  }, []);

  return (
    <>
      <OrderActive />
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <Logo height={50} width={50} imgHeight={50} />
          </div>
          <div className={classes.contendSearch}>
            <Dropdown
              className={classes.button}
              children={
                <Menu className={classes.menuItem}>
                  {cities.map((it) => (
                    <Menu.Item key={1}>
                      <div
                        key={1}
                        onClick={() => handleChange('city_id', it.id)}
                      >
                        {it.name}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              description={currentCityName ? currentCityName : 'Barranquilla'}
            />
          </div>
          {user.id && (
            <div className={classes.avatar}>
              <MenuIcon fontSize="large" onClick={() => setOpenDrawer(true)} />
            </div>
          )}
        </div>
        <div className={classes.contendTitle}>
          <h1 style={{ fontSize: 40 }}>Hola</h1>
          {menuItems ? (
            <div>{`${menuItems}`} platos disponibles</div>
          ) : (
            <Skeleton animation="wave" height={25} width={20} />
          )}
        </div>
        <div>
          {skeleton
            ? brands.map((it) => (
                <div className={classes.contentCardRestaurant}>
                  <Link to={`/${it.slug}`}>
                    <CardRestaurant
                      image={`${s3PathImageHandrer}/${window.imageShark(
                        it.restaurants[0]?.path_cover
                          ? it.restaurants[0]?.path_cover
                          : '/static/suenadeli.png',
                        1000,
                        1000
                      )}`}
                      logo={it?.restaurants[0]?.path_logo}
                      name={it.name}
                      slogan={it.slogan}
                      loading
                    />
                  </Link>
                </div>
              ))
            : [1, 2, 3, 4].map((it) => (
                <div className={classes.contentSkeleton}>
                  <SkeletonCardRestaurant />
                </div>
              ))}
        </div>
        <ButtonNavigation home />
      </div>
      <Drawer open={openDrawer} closeDrawer={() => closeDrawer()}>
        <LateralMenu />
      </Drawer>
    </>
  );
}

export default Home;
