import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { LogoRestaurant } from './commons/';
import { makeStyles } from '@material-ui/core/styles';
import { letterSpacing } from '../components/commons/theme';
import { OpenAlerDialog } from './commons/';
import { SwitchOrder } from '../components';
import { ShareRestaurant } from './';
import { useLocation, matchPath } from 'react-router';
import { HOST_FRONT } from '../utils/constants';
import Dropdown from '../components/commons/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentTypeOrderSet } from '../redux/actions/current-type-order';
import { WaiterICon, DeliveryIcon } from '../components/icons/';
import StoreIcon from '@material-ui/icons/Store';
import { SELECTOR_RESTAURANT } from '../redux/selectors/restaurant';
import { SELECTOR_TYPE_ORDER_SELECTED } from '../redux/selectors/current-type-order';
import { restaurantsService, searchShippingCostsService, userAddressesService } from '../api/feathers';
import { useHistory } from 'react-router-dom';
import { SELECTOR_ADDRESSES } from '../redux/selectors/addresses';
import { AddressesSet } from '../redux/actions/addresses';
import Numeral from "numeral";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    padding: '0 20px',
    marginTop: -132,
  },
  contentdescription: {
    display: 'flex',
    color: 'white',
    alignContent: 'center',
    flexDirection: 'column',
    marginLeft: '18px',
    justifyContent: 'start',
    letterSpacing: letterSpacing,
    fontWeight: '500',
    marginTop: 10,
  },
  header: {
    display: 'flex',
    marginBottom: 8,
  },
  card: {
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
    borderRadius: 10,
    paddingBottom: 10,
  },
  ubication: {
    minHeight: 30,
    display: 'flex',
    justifyContent: 'space-around',
  },
  contentSwitchOrder: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  name: {
    fontSize: 18,
    textTransform: 'uppercase',
    marginBottom: -8,
    display: 'flex',
  },
}));

function HeaderRestaurant(props) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const restaurant = useSelector(SELECTOR_RESTAURANT);
  const addresses = useSelector(SELECTOR_ADDRESSES);
  const typeOrdersSelect = useSelector(SELECTOR_TYPE_ORDER_SELECTED);
  const [open, setOpen] = useState(false);
  const [addressMain, setAddressMain] = useState({});
  const [shippingCost,setShippingCost] =useState({})
  const [currentAddressDes, setCurrentAddressDes] = useState(null);
  const [typeOrderSelected, setTypeOrderSelected] = useState(typeOrdersSelect);
  const [addressSelectId, setAddressSelectId] = useState();
  const [restaurantAddress, setRestaurantAddress] = useState([]);
  const [switchOrders, setSwitchOrders] = useState([]);
  const [brandId, setBrandId] = useState();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  const handleChangeAddress = async (fieldName, slug, description) => {
    setAddressSelectId(slug);
    setCurrentAddressDes(description);

    history.push(`/r-${slug}`);

    props.getRestaurant(slug);
  };

  const selectedTypeOrder = (val) => {
    dispatch(CurrentTypeOrderSet(val));
    setTypeOrderSelected(val);
  };

  useEffect(() => {
    if (props.city && props.brand_id) {
      setCurrentAddressDes(`${props.city.name} - ${props.address}`);
      setBrandId(props.brand_id);
    }
  }, [props.city]);

  useEffect(() => {
    const match = matchPath(`${location.pathname}`, {
      path: `/r-${restaurant.slug}`,
      exact: true,
      strict: false,
    });

    if (brandId != null && match) {
      restaurantsService
        .find({ query: { brand_id: brandId, status: 'active' } })
        .then((it) => {
          setRestaurantAddress(
            it.data.map((it) => ({
              address: `${it.city.name} - ${it.address}`,
              restaurant_id: it.id,
              slug: it.slug,
            }))
          );
        });
    }

    let typeOrders = [];

    if (restaurant.instore === 'active') {
      typeOrders.push({
        id: 'instore',
        description: 'Restaurante',
        icon: (
          <WaiterICon
            // color={`${typeOrderSelected === "instore" ? "white" : "#838383"}`}
            color="white"
            style={{ width: '25px' }}
          />
        ),
      });
    }
    if (restaurant.delivery === 'active') {
      typeOrders.push({
        id: 'delivery',
        description: 'Delivery',
        icon: (
          <DeliveryIcon
            color="white"
            style={{ marginRight: 6, width: '25px' }}
            // color={`${typeOrderSelected === "delivery" ? "white" : "#838383"}`}
          />
        ),
      });
    }
    if (restaurant.pickup === 'active') {
      typeOrders.push({
        id: 'pick_up',
        description: 'Pick up',
        icon: <StoreIcon color="white" />,
      });
    }

    setSwitchOrders(typeOrders);

    if (
      !typeOrdersSelect ||
      !typeOrders.map((it) => it.id).includes(typeOrdersSelect)
    ) {
      if (params.typeOrder) {
        selectedTypeOrder(params.typeOrder);
      } else {
        selectedTypeOrder(typeOrders[0]?.id);
      }
    }

  }, [props, addressSelectId, location.pathname]);


  useEffect(()=>{

    if(typeOrderSelected === 'delivery'){
      getAddresses()
    }

  },[typeOrdersSelect, props])

  useEffect(() => {
    setAddressMain(addresses.find((it) => it.main === 'true'));
  }, [addresses]);

  const getAddresses = () => {
    userAddressesService.find({ query: { $limit: 500 } }).then((it) => {
      dispatch(AddressesSet(it.data));
      setAddressMain(it.data.find((it) => it.main === 'true'));
      if(!it.data.find((it) => it.main === 'true')){
        setAddressMain({});
      }
    });
  };


  useEffect(()=>{

    if(typeOrdersSelect === 'delivery' && addressMain)

      getShippingCost({user_address_id:addressMain.id, restaurant_id:restaurant.id})

  },[addressMain])



  const getShippingCost = ({user_address_id, restaurant_id}) => {
    searchShippingCostsService
      .find({
        query: {
          user_address_id ,
          restaurant_id,
        },
      })
      .then((it) => {
        setShippingCost(it.shippingCost);
      })
      .catch((it) => {
        setShippingCost(null);
      });

  };


  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <LogoRestaurant path_logo={props.path_logo} height={70} width={70} />
          <div className={classes.contentdescription}>
            <div className={classes.name}>
              <div>{props.name}</div>
            </div>
            <div>{props.slogan}</div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.ubication}>
            <Dropdown
              buttonLocation={true}
              children={
                <Menu className={classes.menuItem}>
                  {restaurantAddress.map((it, key) => (
                    <Menu.Item key={key}>
                      <div
                        key={key}
                        onClick={() =>
                          handleChangeAddress('slug', it.slug, it.address)
                        }
                      >
                        {it.address}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              description={currentAddressDes}
            />
          </div>
          <div className={classes.contentSwitchOrder}>
            {switchOrders[0]?.id ? (
              <SwitchOrder
                selectedTypeOrder={selectedTypeOrder}
                values={switchOrders}
                selected={typeOrdersSelect}
                numValues={switchOrders.length}
              />
            ) : (
              'Aún esta ubicación no activa pedidos online.'
            )}
          </div>
          <div>{typeOrdersSelect === 'delivery' && shippingCost?.free_shipping_minimum_order_amount && (

            <div style={{ fontSize: 12, marginLeft:15 }}>{ `Envio gratis a partir de compras superiores o iguales a ${Numeral(shippingCost.free_shipping_minimum_order_amount).format("($0,0)")}` }</div>

          )}</div>
        </div>
      </div>
      <OpenAlerDialog
        childrens={
          <ShareRestaurant link={`${HOST_FRONT}${location.pathname}`} />
        }
        open={open}
        handleClose={() => setOpen(false)}
        minWidth={260}
      />
    </div>
  );
}

export default HeaderRestaurant;
