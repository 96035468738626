import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  current_shopping_cart: [],
};

const { Types, Creators } = createActions({
  setCurrentShoppingCart: ["current_shopping_cart"],
});

const setCurrentShoppingCart = (state, { current_shopping_cart }) => {
  return produce(state, (draftState) => {
    draftState.current_shopping_cart = current_shopping_cart;
  });
};

// noinspection JSCheckFunctionSignatures
export const current_shopping_cart = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_SHOPPING_CART]: setCurrentShoppingCart,
});

export default {
  Types,
  Creators,
};
