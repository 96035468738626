import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useFormik } from 'formik';
import { InputField } from '../components/commons/inputs';
import { LabelCommon, ButtonMain, Divider } from '../components/commons';
import { makeStyles } from '@material-ui/core/styles';
import { letterSpacing } from '../components/commons/theme';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { graySecond, greenSuccess } from '../components/commons/theme';
import { Map } from '../components';
import { userAddressesService } from '../api/feathers';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
const useStyles = makeStyles((theme) => ({
  root: {},
  contentRestaurant: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
    letterSpacing,
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  goBack: {
    zIndex: 10,
    position: 'absolute',
    marginLeft: 20,
    marginTop: 6,
  },
  contendAddress: {
    padding: '10px 20px',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  title: {
    marginBottom: 10,
    letterSpacing,
    fontWeight: '500',
  },
  suggestion: { zIndex: 1000 },
  ButtonMain: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  contendSearch: {
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
  },
  contendList: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'row',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 50,
    alignItems: 'flex-end',
  },
  contentMap: {
    height: 360,
    width: '100%',
    position: 'relative',
  },
  titleMap: {
    marginBottom: 10,
    fontWeight: '600',
    fontSize: 22,
  },
  form: {
    marginTop: 50,
    zIndex: 50,
  },
  buttonMain: {
    marginTop: 20,
    zIndex: 1000000000000000000,
  },
  error: {
    color: 'red',
  },
  btnPay: {
    width: '100%',
    height: '40px',
    backgroundColor: greenSuccess,
    color: 'white',
    borderRadius: '10px',
    boxShadow: '2px 2px 3px #999',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: 20,
    letterSpacing,
  },
  buttonNewAddress: {
    position: 'fixed',
    bottom: '20px',
    width: '100%',
    paddingRight: 50,
  },
  inputField: {
    height: 40,
    borderRadius: 20,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 51px -10px',
  },
}));

export default function FormAddress(props) {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [geo, setGeo] = useState({});
  const [showButtonSetAddress, setShowButtonSetAddress] = useState(false);
  const [titleMap, setTitleMap] = useState('Confirma tu dirección.');
  const [titleAddress, setTitleAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: 11.004107,
    lng: -74.806984,
  });

  const [showMap, setShowMap] = useState(false);

  const classes = useStyles();

  const handleChange = (address, event) => {
    props.setShowList(false);

    setShowButtonSetAddress(true);
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setAddress(results[0].formatted_address?.split(',')[0]);
        setCity(
          `${results[0].formatted_address.split(',')[1]},${
            results[0].formatted_address.split(',')[2]
          }`
        );
        setPostalCode(
          results[0].address_components.find((it) =>
            it.types.includes('postal_code')
          )?.short_name
        );

        getLatLng(results[0]).then((it) => {
          setGeo({ lat: it.lat, lng: it.lng });
          setShowMap(true);
        });
      })
      .catch((error) => console.error('Error', error));
  };

  const addressNotFoundInPlaces = () => {
    setTitleMap(
      <div>
        1. Arrastra el punto{' '}
        <img src="/point_google_map.png" width="auto" height="40px" /> a la
        ubicación de entrega.
      </div>
    );
    setTitleAddress('2. Confirma tu dirección.');
    setAddress();
    setGeo({ lat: props.restaurant.lat, lng: props.restaurant.lng });
    setShowMap(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = 'Este campo es requerido';
    }

    if (!values.description) {
      errors.city = 'Este campo es requerido';
    }

    if (!values.details) {
      errors.details = 'Este campo es requerido';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      address: address,
      description: city,
      postal_code: postalCode,
      lat: geo.lat,
      lng: geo.lng,
    },
    validate,
    onSubmit: (values) => {
      userAddressesService
        .create({ ...values })
        .then((it) => {
          props.handleCloseDialogFullScreen();
          props.getAddresses();
        })
        .catch((it) => console.log(it));
    },
  });

  // const deleted = e => {
  //   if (address.length == 2 && e.key == "Backspace") {
  //     setAddress("");
  //   }
  // };

  return (
    <div>
      {!showMap && coordinates.lat && (
        <PlacesAutocomplete
          value={`${address}`}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={{
            location: new window.google.maps.LatLng(
              props.restaurant.lat,
              props.restaurant.lng
            ),
            radius: 50000,
            componentRestrictions: { country: 'co' },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <div className={classes.contendForm}>
                <div className={classes.contentItem}>
                  <div className={classes.label}>
                    <LabelCommon value={'DIRECCIÓN Y CIUDAD'} />
                  </div>
                  <div>
                    <InputField
                      id="address"
                      name="address"
                      type="text"
                      className={classes.inputField}
                      placeholder="Escribe una dirección"
                      prefix={<LocationOnIcon />}
                      {...getInputProps()}
                      onKeyDown={(e) => {
                        getInputProps().onKeyDown(e);
                        // deleted(e);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => {
                    return (
                      <div
                        className={classes.contendList}
                        key={`suggestions${index}`}
                        {...getSuggestionItemProps(suggestion, {})}
                      >
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {suggestion.formattedSuggestion.mainText}
                              </span>
                              <span>
                                {suggestion.formattedSuggestion.secondaryText}
                              </span>
                            </div>
                            <div>
                              <ArrowRightIcon />
                            </div>
                          </div>
                          <Divider
                            size={1}
                            width={'100%'}
                            backgroundColor={graySecond}
                            marginTop={5}
                            marginBottom={5}
                            style={{ marginLeft: 30 }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
      {!showMap && showButtonSetAddress && (
        <div className={classes.buttonNewAddress}>
          <div
            className={classes.btnPay}
            onClick={(e) => addressNotFoundInPlaces()}
          >
            No encuento mi dirección
          </div>
        </div>
      )}
      {showMap && (
        <>
          <div className={classes.contentMap}>
            <div className={classes.titleMap}>{titleMap}</div>
            <Map
              coord={{ lat: geo.lat, lng: geo.lng }}
              setCity={setCity}
              setAddress={setAddress}
              setGeo={setGeo}
            />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.form}>
              <div className={classes.label}>
                <div className={classes.titleMap}>{titleAddress}</div>
                <LabelCommon value={'DIRECCIÓN'} />
                {!!formik.errors.address && (
                  <div className={classes.error}>{formik.errors.address}</div>
                )}
              </div>
              <div>
                <InputField
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  name="address"
                  className={classes.inputField}
                />
              </div>
              <div className={classes.label}>
                <LabelCommon value={'CIUDAD'} />
                {!!formik.errors.description && (
                  <div className={classes.error}>
                    {formik.errors.description}
                  </div>
                )}
              </div>
              <div>
                <InputField
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  className={classes.inputField}
                />
              </div>
              <div className={classes.label}>
                <LabelCommon value={'¿Quien recibe y donde (APTO, CASA)'} />
                {!!formik.errors.details && (
                  <div className={classes.error}>{formik.errors.details}</div>
                )}
              </div>
              <div>
                <InputField
                  onChange={formik.handleChange}
                  name="details"
                  className={classes.inputField}
                />
              </div>
              <div className={classes.buttonMain}>
                <ButtonMain
                  type="submit"
                  description="Guardar Dirección"
                  color={greenSuccess}
                />
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
