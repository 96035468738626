import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  type_order: null,
};

const { Types, Creators } = createActions({
  setCurrentTypeOrder: ["type_order"],
});

const setCurrentTypeOrder = (state, { type_order }) => {
  return produce(state, (draftState) => {
    draftState.type_order = type_order;
  });
};

// noinspection JSCheckFunctionSignatures
export const type_order = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_TYPE_ORDER]: setCurrentTypeOrder,
});

export default {
  Types,
  Creators,
};
