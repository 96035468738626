import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  addresses: [],
};

const { Types, Creators } = createActions({
  setAddresses: ["addresses"],
});

const setAddresses = (state, { addresses }) => {
  return produce(state, (draftState) => {
    draftState.addresses = addresses;
  });
};

// noinspection JSCheckFunctionSignatures
export const addresses = createReducer(INITIAL_STATE, {
  [Types.SET_ADDRESSES]: setAddresses,
});

export default {
  Types,
  Creators,
};
