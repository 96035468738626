import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { textColorBlack, letterSpacing } from "./theme";
import numeral from "numeral";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    // width: "100%",
    minWidth: 150,
    maxWidth: 180,
    boxShadow: "none",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    padding: 5,
    "& > p": {
      textTransform: "uppercase",
      letterSpacing: letterSpacing,
      margin: 0,
    },
  },
  img: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  productName: {
    color: textColorBlack,
    fontWeight: 12,
    marginBottom: 0,
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  productValue: {
    fontWeight: "bold",
    fontSize: 12,
  },
  contentImg: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    width: "100%",
    background: "white",
  },
  strikethroughPrice: {
    textDecorationLine: "line-through",
    fontSize: 12,
  },
}));

export default function CardProduct(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.contentImg}>
        <img
          className={classes.img}
          src={`${
            props.path
              ? props.path
              : "https://menuapps3.s3.amazonaws.com/static/suenadeli.png"
          }`}
          alt={`${props.name}`}
        />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div style={{ width: "100%" }}>
            <p className={classes.productName}> {props.name}</p>
          </div>
          {props.price_functionality == "StandardPlusPersonalization" &&
            props.strikethrough_price && (
              <p className={classes.strikethroughPrice}>
                {numeral(props.strikethrough_price).format("($0,0)")}
              </p>
            )}
          <p className={classes.productValue}>
            {props.price_functionality == "StandardPlusPersonalization"
              ? numeral(props.price).format("($0,0)")
              : props.price_functionality == "DeterminedByPersonalization"
              ? "Ver Precio"
              : "Precio temporada"}
          </p>
        </CardContent>
      </div>
    </Card>
  );
}
