import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import {
  backgroundBlack,
  letterSpacing,
  mainColor,
} from '../components/commons/theme';
import { Divider } from './commons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: '100%',
    height: '100%',
  },
  contendClose: {
    position: 'absolute',
    top: 14,
    right: 20,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundColor: backgroundBlack,
    },
  },
  contendList: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    letterSpacing,
    '& > div': {
      minHeight: 50,
      '& > a': {
        color: 'white',
      },
    },
  },
  divider: {
    position: 'absolute',
    bottom: 0,
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={props.onCloseMenu}
      onKeyDown={props.onCloseMenu}
    >
      <div className={classes.contendList}>
        <div className={classes.contendClose}>
          <div onClick={props.onCloseMenu}>
            <CloseIcon style={{ color: 'white', fontSize: 30 }} />
          </div>
        </div>
        <div>
          <Link to="/profile">MI PERFIL</Link>
        </div>
        <div>
          <Link to="/my-orders">MIS PEDIDOS</Link>
        </div>
        <div>MEDIOS DE PAGO</div>
        <div className={classes.divider}>
          <Divider
            size={2}
            width={125}
            backgroundColor={mainColor}
            marginTop={5}
            marginBottom={5}
            // style={{ marginLeft: 30 }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            className={classes.drawer}
            anchor={anchor}
            open={props.open}
            onClose={props.onCloseMenu}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
