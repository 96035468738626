import react, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing, mainColor } from "./theme";

const useStyles = makeStyles({
  root: (props) => ({
    display: "flex",
    backgroundColor: mainColor,
    height: 60,
    justifyContent: "center",
    textTransform: "UPPERCASE",
    textAlign: "center",
    alignItems: "center",
    letterSpacing,
    fontWeight: 500,
  }),
});

function ButtonFullWidth(props) {
  const classes = useStyles(props);

  useEffect(() => {}, [props]);

  return <div className={classes.root} {...props} >{props.description}</div>;
}

export default ButtonFullWidth;
