import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { greenSuccess } from "../components/commons/theme";
import { SELECTOR_USER } from "../redux/selectors/auth";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiStepLabel-active": {
      fontWeight: 600,
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function TimeLine(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [stepsDescriptions, setStepDescriptions] = useState([]);
  const user = useSelector(SELECTOR_USER);

  useEffect(() => {
    switch (props.status) {
      case "new":
        setActiveStep(0);
        break;
      case "active":
        setActiveStep(1);
        break;
      case "shipped":
        setActiveStep(2);
        break;
      case "completed":
        setActiveStep(4);
        break;
      default:
        break;
    }

    setStepDescriptions([
      `${_.capitalize(user.first_name)} recibimos tu pedido`,
      "El local esta preparando tu pedido",
      props.type == "delivery"
        ? "Tu pedido esta en camino"
        : props.type == "pickup"
        ? "Tu pedido esta listo para recoger"
        : "l",
      "Hemos entregado tu pedido",
    ]);
  }, [user, props]);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      background: greenSuccess,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      background: greenSuccess,
    },
  });

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <CheckCircleIcon />,
      2: <CheckCircleIcon />,
      3: <CheckCircleIcon />,
      4: <CheckCircleIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsDescriptions.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
