import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonPrimary } from "./commons/";

import {
  graySecond,
  mainColor,
  letterSpacing,
  blackMain,
} from "../components/commons/theme";

const useStyles = makeStyles({
  root: {
    minHeight: 150,
    padding: 30,
    // minWidth: 300,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 30,
    width: "100%",
  },
  actions: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    "& > :first-child": {
      marginRight: 20,
    },
  },
  contentTitle: {
    maxWidth: 180,
    textAlign: "center",
    marginBottom: 10,
    letterSpacing,
    fontSize: 16,
    color: blackMain,
    fontWeight: "lighter",
    lineHeight: "20px",
  },
});

export default function CallWaiter(props) {
  const classes = useStyles();

  const call = () => {
    console.log("llamar al mesero");
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentTitle}>
        <div>¿DESEAS LLAMAR AL MESERO?</div>
      </div>
      <div className={classes.actions}>
        <div>
          <ButtonPrimary
            color={graySecond}
            label="CANCELAR"
            onClick={props.handleClose}
          />
        </div>
        <div>
          <ButtonPrimary color={mainColor} label="CONFIRMAR" onClick={call} />
        </div>
      </div>
    </div>
  );
}
