import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { grayPrimary } from '../commons/theme';
const useStyles = makeStyles({
  root: {},
});

const BackIcon = (props) => {
  const classes = useStyles(props);

  const back = () => {
    window.history.back();
  };

  return (
    <div onClick={() => back()}>
      <ArrowBackSharpIcon
        style={{
          color: 'white',
          backgroundColor: grayPrimary,
          borderRadius: '50%',
          fontSize: 26,
        }}
      />
    </div>
  );
};
export default BackIcon;
