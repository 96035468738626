import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { grayPrimary } from "./commons/theme";
import { SELECTOR_TYPE_ORDER_SELECTED } from "../redux/selectors/current-type-order";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const Switch = styled.div`
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  position: relative;
  height: 36px;
  background-color: #e4e4e4;
  border-radius: 20px;
`;

export const SwitchRadio = styled.input`
  display: none;
`;

export const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100px;
  height: 36px;
  background: #56565c;
  border-radius: 20px;
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`
  position: relative;
  align-items: center;
  z-index: 2;
  float: left;
  width: 100px;
  line-height: 36px;
  font-size: 11px;
  font-weight: bold;
  color: ${grayPrimary};
  text-align: center;
  cursor: pointer;
  display: flex;
  ${SwitchRadio}:checked + & {
    transition: 0.15s ease-out;
    color: #fff;
  }
`;

const titleCase = str =>
  str
    .split(/\s+/)
    .map(w => w)
    .join(" ");

const ClickableLabel = ({ title, onChange, id, icon }) => {
  return (
    <SwitchLabel onClick={() => onChange(title)} className={id}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        {icon}
        {title && titleCase(title)}
      </div>
    </SwitchLabel>
  );
};

const ConcealedRadio = ({ value, selected }) => (
  <SwitchRadio type="radio" name="switch" checked={selected === value} />
);

export default function ToggleSwitch(props) {
  const [state, setState] = useState({
    selected: "instore",
    numValues: 3,
    load: false,
  });
  const [style, setStyle] = useState({});
  const typeOrderSelected = useSelector(SELECTOR_TYPE_ORDER_SELECTED);
  const history = useHistory();

  const setTypeOrder = type => {
    history.push(`?typeOrder=${type}`);
  };

  const handleChange = val => {
    setState({ selected: val });
    setTypeOrder(val);
    props.selectedTypeOrder(val);
  };

  useEffect(() => {
    setStyle(
      `${
        (props.values.findIndex(obj => obj.id == typeOrderSelected) /
          props.numValues) *
        100
      }%`
    );
  }, [typeOrderSelected]);

  useState(() => {
    setState({ ...state });
  }, [props]);

  return (
    <Switch>
      {props.values.map(val => {
        return (
          <span>
            <ConcealedRadio value={val.id} selected={typeOrderSelected} />
            <ClickableLabel
              title={val.description}
              icon={val.icon}
              onChange={() => handleChange(val.id)}
            />
          </span>
        );
      })}
      <SwitchSelection style={{ left: style }} />
    </Switch>
  );
}
