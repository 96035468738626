import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Numeral from "numeral";
import {
  greenSuccess,
  grayPrimary,
  graySecond,
  letterSpacing,
} from "../components/commons/theme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    fontWeight: "500 !important",
    letterSpacing,
  },
  radioButtons: {
    "& input[type='radio']": {
      opacity: "0",
      position: "fixed",
      width: "0",
    },
    "& label": {
      display: "inline-block",
      backgroundColor: graySecond,
      padding: "6px 16px",
      fontSize: "12px",
      borderRadius: "20px",
      color: grayPrimary,
      marginRight: 2,
    },
    "& label:hover": {
      backgroundColor: greenSuccess,
      color: "white",
    },
    "& input[type='radio']:checked + label": {
      backgroundColor: greenSuccess,
      color: "white",
    },
  },
}));

const AmoutTip = props => {
  const classes = useStyles(props);

  const [value, setValue] = useState("no");
  const [amoutTip, setAmoutTip] = useState(0);

  const handleChangeAmoutTip = event => {
    setValue(event.target.value);
    setAmoutTip((props.totalOrder * event.target.value) / 100);
    props.getAmoutTip((props.totalOrder * event.target.value) / 100);
  };

  useEffect(() => {
    setAmoutTip((props.totalOrder * value) / 100);
  }, [props.totalOrder, value]);

  return (
    <div className={classes.root}>
      <div
        aria-label="gender"
        name="gender1"
        value={value}
        className={classes.radioButtons}
      >
        <input
          type="radio"
          id="1"
          name="radioFruit"
          value="0"
          onClick={e => handleChangeAmoutTip(e)}
          checked={value ? true : false}
        />
        <label for="1">No</label>
        <input
          type="radio"
          id="10"
          name="radioFruit"
          value="10"
          onClick={e => handleChangeAmoutTip(e)}
          checked={value === "10" ? true : false}
        />
        <label for="10">10%</label>
        <input
          type="radio"
          id="15"
          name="radioFruit"
          value="15"
          onClick={e => handleChangeAmoutTip(e)}
          checked={value === "15" ? true : false}
        />
        <label for="15">15%</label>
        <input
          type="radio"
          id="18"
          name="radioFruit"
          value="18"
          onClick={e => handleChangeAmoutTip(e)}
          checked={value === "18" ? true : false}
        />
        <label for="18">18%</label>
      </div>
      <div style={{ fontSize: 12 }}>{Numeral(amoutTip).format("($0,0)")}</div>
    </div>
  );
};

export default AmoutTip;
