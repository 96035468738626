/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { retryLoginAndGetCurrentUser } from "../redux/actions/auth";

function Guard({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retryLoginAndGetCurrentUser());
  }, []);

  return null;
}

export default Guard;
