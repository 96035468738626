import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  grayPrimary,
  graySecond,
  mainColor,
  letterSpacing,
} from "../components/commons/theme";
import numeral from "numeral";
import { OpenAlerDialog } from "./commons";

const useStyles = makeStyles(props => ({
  leftButton: props => ({
    disabled: true,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  }),
  rightButton: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: props => ({
    height: props.small ? 30 : 36,
    minWidth: props.small ? 30 : 36,
    border: "0px",
  }),
  icon: props => ({
    fontSize: props.small ? ".8rem" : "1.25rem",
    zIndex: 0,
    color: grayPrimary,
  }),
  descriptionProduct: props => ({
    backgroundColor: props.color ? props.color : mainColor,
    overflow: "hidden",
    borderRadius: 23,
    paddingRight: 30,
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 6,
    color: "white",
    textTransform: "uppercase",
    letterSpacing: letterSpacing,
    textAlign: "initial",
  }),
}));

const CustomButtonGroup = withStyles(() => ({
  root: props => ({
    borderRadius: 23,
    width: "100%",
    backgroundColor: graySecond,
    color: grayPrimary,
    alignItems: "center",
    "& .MuiButton-outlined.Mui-disabled": {
      border: "none",
    },
  }),
}))(ButtonGroup);

const ButtonAdd = ({ handleReduce, quantity, handleIncrease, ...props }) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [childrens, setChildrens] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomButtonGroup
        size="small"
        aria-label="outlined secondary button group"
        {...props}
        style={{ color: grayPrimary }}
      >
        <Button
          disabled={props.disableLessButton}
          onClick={handleReduce}
          className={clsx(classes.leftButton, classes.button)}
        >
          <RemoveIcon fontSize="small" className={classes.icon} />
        </Button>
        <Button className={classes.button} style={{ fontSize: 18 }}>
          {quantity}
        </Button>
        <Button
          onClick={handleIncrease}
          className={clsx(classes.rightButton, classes.button)}
        >
          <AddIcon fontSize="small" className={classes.icon} />
        </Button>
        <div
          className={classes.descriptionProduct}
          onClick={() => {
            props.handleCreateOrder();
          }}
        >
          <div style={{ fontSize: 8, marginBottom: -2 }}>
            <label>agregar</label>
          </div>
          <div style={{ fontWeight: 500, fontSize: 14 }}>
            <label> +{numeral(props.totalPrice).format("($0,0)")}</label>
          </div>
        </div>
      </CustomButtonGroup>
      <OpenAlerDialog
        childrens={childrens}
        open={open}
        handleClose={handleClose}
        minWidth={360}
      />
    </>
  );
};

export default ButtonAdd;
