import React, { useEffect, useState, useSelector } from "react";
import { getAuth } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { ClientFeathers } from "../api/feathers";
import { graySecond, greenSuccess } from "../components/commons/theme";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router";
import { LabelCommon, ButtonMain } from "../components/commons/";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import { InputField } from "../components/commons/inputs/";
import { Snackbar } from "../components/commons/";

const useStyles = makeStyles({
  root: props => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 10,
    paddingRight: 10,
    padding: "20px 10px",
  }),
  label: {
    textAlign: "left",
    marginTop: 10,
  },
  contendForm: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  ButtonMain: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    "& > button": {
      maxWidth: 200,
      backgroundColor: greenSuccess,
    },
  },
  dialog: {
    "& .MuiPaper-rounded": {
      borderRadius: 8,
    },
  },
  header: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default function Login(props) {
  const classes = useStyles(props);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hasOtpCode, setHasOtpCode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ show: false });
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  const [otpData, setOtpData] = useState({
    strategy: "sms",
    phone: "",
    otp: 0,
    countryCode: "57",
  });

  const handleChangeOtp = e => {
    setOtpData(prevState => ({
      ...prevState,
      otp: e.target.value,
    }));
  };

  const handleChangePhone = (phone, countryCode) => {
    setOtpData(prevState => ({
      ...prevState,
      phone: phone.substr(countryCode.dialCode.length),
      phone_country_code: countryCode.dialCode,
    }));
  };

  const sentCode = () => {
    const { prefix, email, phone, otp } = otpData;

    ClientFeathers.authenticate({
      strategy: "sms",
      phone: phone,
      phone_country_code: otpData.countryCode,
      token_login_phone: otp,
    })
      .then(it => {
        dispatch(getAuth(it.user));
        history.push(`${location.pathname}?login=true`);
      })
      .catch(e => {
        setMessage({ message: e.message, show: true, severity: "error" });
      });
  };

  const handleClose = () => {
    setHasOtpCode(false);
    history.push(`${location.pathname}`);
  };

  const validate = values => {
    const errors = {};
    if (!otpData.phone) {
      errors.phone = "Required";
    } else if (otpData.phone.length < 8) {
      errors.phone = "Ingresa un numero correcto";
    }

    if (errors.phone) {
      setMessage({ message: errors.phone, show: true, severity: "error" });
    }

    return errors;
  };

  const handleCloseSnackbar = () => {
    setMessage({ show: false });
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validate,
    onSubmit: values => {
      const { prefix, phone, countryCode } = otpData;

      ClientFeathers.authenticate({
        strategy: "sms",
        phone: phone,
        phone_country_code: countryCode,
      })
        .then(it => {})
        .catch(e => {
          setHasOtpCode(true);
        });
    },
  });

  return (
    <Dialog
      open={params?.login === "false"}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      className={classes.dialog}
    >
      <div className={classes.root}>
        {!hasOtpCode ? (
          <div>
            <div className={classes.header}>
              <div>
                <h2>¡Hola!</h2>
              </div>
              <div style={{ maxWidth: 300 }}>
                Enviaremos un código a tu Whatsapp/SMS para identificar tu
                pedido
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={classes.contendForm}>
                <div>
                  <div className={classes.label}>
                    <LabelCommon />
                  </div>
                  <PhoneInput
                    inputStyle={{
                      height: 38,
                      marginBottom: 6,
                      marginTop: 6,
                      minWidth: 220,
                      borderRadius: 50,
                      width: "100%",
                      border: `2px solid ${graySecond}`,
                      "&::placeholder": {
                        color: "black",
                      },
                    }}
                    buttonStyle={{
                      borderTopLeftRadius: 20,
                      borderBottomLeftRadius: 20,
                    }}
                    country={"co"}
                    onChange={handleChangePhone}
                  />
                </div>
                <div className={classes.ButtonMain}>
                  <ButtonMain description="Enviar código" />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div className={classes.header} style={{ marginBottom: 20 }}>
              <div>
                <h2>¡Listo!</h2>
              </div>
              <div>
                Ingresa el código que te enviamos al numero
                {`+${otpData.countryCode}${otpData.phone} `}
                por Whatsapp/SMS
              </div>
            </div>
            <div className={classes.contendForm}>
              <div>
                <InputField
                  onChange={e => handleChangeOtp(e)}
                  type="tel"
                  maxLength={4}
                  style={{ textAlign: "center" }}
                />
                <div className={classes.ButtonMain}>
                  <ButtonMain
                    onClick={() => sentCode()}
                    description="Continuar"
                  />
                </div>
                <div
                  style={{ marginTop: 10 }}
                  onClick={() => setHasOtpCode(false)}
                >
                  {"< cambiar numero"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={message.show}
        severity={"error"}
        message={message.message}
        handleClose={handleCloseSnackbar}
      />
    </Dialog>
  );
}
