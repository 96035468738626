import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  show_orders: false,
};

const { Types, Creators } = createActions({
  setShowOrders: ["show_orders"],
});

const setShowOrders = (state, { show_orders }) => {
  return produce(state, (draftState) => {
    draftState.show_orders = show_orders;
  });
};

// noinspection JSCheckFunctionSignatures
export const show_orders = createReducer(INITIAL_STATE, {
  [Types.SET_SHOW_ORDERS]: setShowOrders,
});

export default {
  Types,
  Creators,
};
