import React from "react";
import "antd/dist/antd.css";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { letterSpacing } from "./theme";

const useStyles = makeStyles({
  root: (props) => ({
    marginRight: props.home ? 0 : 4,
    width: "100%",
    "& > button": {
      color: "black",
      fontWeight: "normal",
      borderWidth: 2,
      borderColor: "white",
      borderRadius: props.buttonLocation ? "none" : 55,
      boxShadow: props.buttonLocation
        ? "none"
        : "0px 7px 16px -6px rgba(0,0,0,0.12)",
    },
    "& > :hover": {
      color: props.buttonLocation ? "black" : "#0D3B92 !important",
      borderColor: "white",
    },
  }),
  button: (props, md) => ({
    width: "100%",
    height: "100% !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    letterSpacing,
    padding: 14,
    textTransform: "capitalize",
    "& > .anticon-down": {
      color: props.buttonLocation ? "black" : "#f6bb36",
      fontSize: "18px !important",
      maginTop: 4,
    },
  }),
  buttonLocation: {
    color: "red",
    fontSize: 14,
  },
  address: {
    maxWidth: 300,
    height: "auto",
    overflow: "auto",
    letterSpacing: 0,
  },
});

export default function Dropdownn(props) {
  const md = useMediaQuery("(min-width:1030px)");
  const classes = useStyles(props, md);

  return (
    <div className={classes.root}>
      <Dropdown overlay={props.children} trigger={["click"]}>
        <Button onClick={e => e.preventDefault} className={classes.button}>
          <div className={classes.address}>{props.description}</div>
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}
