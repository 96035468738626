import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { grayPrimary, letterSpacing } from "./theme";
import { ButtonAdd } from "../index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    padding: 5,
    "& > p": {
      textTransform: "uppercase",
      letterSpacing: letterSpacing,
      margin: 0,
    },
  },
  img: {
    width: " 100%",
    objectFit: "cover",
    objectPosition: "center",
    overflow: "hidden",
  },
  productName: {
    color: grayPrimary,
    fontWeight: 15,
    marginBottom: 0,
  },
  productValue: {
    fontWeight: "bold",
    fontSize: 18,
  },
}));

export default function CardProduct() {
  const classes = useStyles();
  const theme = useTheme();

  const handleAdd = (id, quantity) => {
    console.log("agregar");
  };

  const handleRemove = (id, quantity) => {
    console.log("quitar");
  };

  return (
    <Card className={classes.root}>
      <div>
        <img
          className={classes.img}
          src="https://freebornmedia.s3.amazonaws.com/poket/Enmascarar%20grupo%2034.png"
          alt=""
        />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div style={{ width: "100%" }}>
            <p className={classes.productName}> Nombre del producto</p>
          </div>
          <p className={classes.productValue}>$25.000</p>
          <div>
            <ButtonAdd
              handleIncrease={() => handleAdd()}
              handleReduce={() => handleRemove()}
              quantity={1}
            />
          </div>
        </CardContent>
      </div>
    </Card>
  );
}
