import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { letterSpacing, mainColor } from './theme';
import { rubberBand } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import clsx from 'clsx';
import { SELECTOR_UPDATE_ORDERS } from '../../redux/selectors/update-orders';
import { useSelector } from 'react-redux';
import restaurant from '../../redux/reducers/restaurant';

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    color: 'white',
    backgroundColor: props.backgroundColor ? props.backgroundColor : mainColor,
    height: 36,
    justifyContent: 'center',
    textTransform: 'UPPERCASE',
    textAlign: 'center',
    alignItems: 'center',
    letterSpacing,
    fontWeight: 500,
    width: '100%',
    borderRadius: 30,
    border: 0,
    maxWidth: 220,

  }),
  content: {
    minWidth: 180,
    maxWidth: 200,
    padding: '0px 6px',
    fontSize: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const bounceAnimation = keyframes`${rubberBand}`;

const Animation = styled.div`
  &.animate {
    animation: 1s ${bounceAnimation};
  }
`;

function ButtomNumItems(props) {
  const classes = useStyles(props);
  const updateOrders = useSelector(SELECTOR_UPDATE_ORDERS);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const numOrders = localStorage.getItem('num_orders');
    if (numOrders === updateOrders) return;
    if (!updateOrders) return;

    setShowAnimation(true);

    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 1000);
    return () => clearTimeout(timer);

    localStorage.setItem('num_orders', updateOrders);
  }, [updateOrders]);

  return (
    <>
      <Animation
        className={clsx({
          ['animate']: showAnimation,
        })}
      >
        <button className={classes.root} {...props}>
          <div className={classes.content}>
            <div>Ver pedido</div>
            <div>{props.num} </div>
          </div>
        </button>
      </Animation>
    </>
  );
}

export default ButtomNumItems;
