import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const TakeAwayIcon = props => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Outline"
      viewBox="0 0 512 512"
      width="25"
      height="25"
      fill={props.color ? props.color : "white"}
    >
      <path d="M232,359.6V264a8,8,0,0,0-8-8c-48.86,0-55.71,83.833-55.978,87.4a8,8,0,0,0,4.4,7.754L184,356.944v2.66l-6.2,62.008A24,24,0,0,0,201.68,448h12.64A24,24,0,0,0,238.2,421.612ZM184.549,339.33a175.605,175.605,0,0,1,7.365-33.019c6.148-18.091,14.226-29.1,24.086-32.853V352H200a8,8,0,0,0-4.422-7.155Zm35.7,90.038A7.91,7.91,0,0,1,214.32,432H201.68a8,8,0,0,1-7.961-8.8L199.24,368h17.52l5.521,55.2A7.908,7.908,0,0,1,220.252,429.368Z" />
      <path d="M488,8H424a8,8,0,0,0-8,8v8H248.982a43.35,43.35,0,0,0-20.7,5.278L149.959,72H56c-13.458,0-24,14.056-24,32,0,16.748,9.185,30.1,21.351,31.811l-36.068,122.1c-.032.109-.062.219-.09.328a40.073,40.073,0,0,0-1.193,9.7V496a8,8,0,0,0,8,8H384a8,8,0,0,0,8-8V268.608a39.933,39.933,0,0,0-1.626-11.287L357.042,143.993A43.281,43.281,0,0,0,399.881,104H416v16a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V16A8,8,0,0,0,488,8ZM48,104c0-9.767,4.738-16,8-16H284.687l-32,32H56C52.738,120,48,113.767,48,104ZM297.193,258.24a40.073,40.073,0,0,0-1.193,9.7V488H32V267.939a24.077,24.077,0,0,1,.679-5.668L69.978,136H238.213a35.245,35.245,0,0,0,51.9,45.826L328.6,151.889,297.283,257.912C297.251,258.021,297.221,258.131,297.193,258.24ZM344,467.314,364.686,488H323.314Zm31.025-205.475a23.947,23.947,0,0,1,.975,6.769V476.686l-24-24V280a8,8,0,0,0-16,0V452.687l-24,24V267.939a24.077,24.077,0,0,1,.679-5.668l31.3-105.976ZM416,88H396.773A12.788,12.788,0,0,0,384,100.773,27.258,27.258,0,0,1,356.773,128H337.107a11.282,11.282,0,0,0-6.892,2.365L280.288,169.2a19.226,19.226,0,0,1-25.4-28.771l54.768-54.769A8,8,0,0,0,304,72H183.374l52.571-28.676A27.3,27.3,0,0,1,248.982,40H416Zm64,24H432V24h48Z" />
      <path d="M160,264a8,8,0,0,0-16,0v24H128V264a8,8,0,0,0-16,0v24H96V264a8,8,0,0,0-16,0v32h.015a7.974,7.974,0,0,0,.83,3.578l15.019,30.039L89.245,422.29A24,24,0,0,0,113.184,448h13.632a24,24,0,0,0,23.939-25.71l-6.619-92.673,15.019-30.039a7.974,7.974,0,0,0,.83-3.578H160Zm-20.944,40-8,16H108.944l-8-16Zm-6.384,125.451A7.919,7.919,0,0,1,126.816,432H113.184a8,8,0,0,1-7.98-8.569L111.449,336h17.1l6.245,87.431A7.919,7.919,0,0,1,132.672,429.451Z" />
    </svg>
  );
};
export default TakeAwayIcon;
