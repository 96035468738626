import { makeStyles } from '@material-ui/core/styles';
import { s3PathImageHandrer } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  contentAvatar: (props) => ({
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props.width,
    height: props.height,
    borderRadius: '50%',
  }),
  img: (props) => ({
    width: '100%',
    height: props.imgHeight,
    objectFit: 'cover',
    objectPosition: 'center',
  }),
});

function UserAvatar(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.contentAvatar}>
      <img
        className={classes.img}
        src={
          props.path
            ? `${s3PathImageHandrer}/${window.imageShark(props.path)}`
            : '/logo192.png'
        }
        alt="logo"
      />
    </div>
  );
}

export default UserAvatar;
