import auth from '../reducers/auth';
import { retryLogin } from '../../api/feathers';
import { ACCESS_TOKEN, STORAGE } from '../../utils/constants';
import { usersService } from '../../api/feathers';
import { SELECTOR_USER } from '../selectors/auth';

export function getAuth(user) {
  return async (dispatch) => {
    dispatch(auth.Creators.setLogin(user));
  };
}

export const retryLoginAndGetCurrentUser = () => {
  return async (dispatch) => {
    if (STORAGE.getItem(ACCESS_TOKEN)) {
      return retryLogin().then((it) => {
        dispatch(auth.Creators.setLogin(it.user));
      });
    } else {
      return Promise.resolve();
    }
  };
};

export const updateUserInfo = (values) => {
  return async (dispatch, getState) => {
    const user = SELECTOR_USER(getState());
    return usersService.patch(user.id, values).then((resp) => {
      dispatch(auth.Creators.setLogin(resp));
      return resp;
    });
  };
};
