import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { LogoRestaurant } from "./";
import { letterSpacing } from "./theme";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    boxShadow: "none",
    borderRadius: 20,
    maxHeight: 250,
    boxShadow: "17px 15px 54px -21px rgba(0,0,0,0.70);",
  },
  media: {
    height: 330,
  },
  cardContainer: {
    position: "relative",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    marginTop: -190,
    color: "white",
    marginLeft: 20,
    paddingBottom: 18,
  },
  title: {
    color: "white",
    fontSize: 20,
    letterSpacing,
    marginBottom: -6,
    fontWeight: 500,
  },
  contentTitle: {
    textTransform: "uppercase",
    paddingLeft: 17,
  },
  subTitle: {
    fontWeight: 400,
    letterSpacing,
    fontSize: 12,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          {...props}
          className={classes.media}
          title="Contemplative Reptile"
        />
        <div className={classes.CardContainer}>
          <div className={classes.cardContent}>
            <div>
              <LogoRestaurant path_logo={props.logo} height={50} width={50} />
            </div>
            <div className={classes.contentTitle}>
              <div className={classes.title}>{props.name}</div>
              <div className={classes.subTitle}>{props.slogan}</div>
            </div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}
