import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserAvatar } from './';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import { useHistory } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { grayPrimary } from '../commons/theme';
import { logout } from '../../api/feathers';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', height: '100%' },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 15,
  },
  header: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {
    width: '100%',
    '& > div': {
      display: 'flex',
      marginBottom: 20,
      alignItems: 'center',
    },
  },
  description: {
    marginLeft: 10,
    fontSize: 16,
  },
}));

function LateralMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(SELECTOR_USER);

  const logout1 = () => {
    logout().then((it) => (window.location.href = '/'));
  };

  useEffect(() => {}, []);

  const redirect = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <UserAvatar
            path={user.avatar_path}
            heigth={90}
            width={90}
            imgHeight={90}
          />
          <h3
            style={{
              margin: '8px 0px 20px 0px',
              textTransform: 'capitalize',
            }}
          >{`${user.first_name || ''} ${user.last_name || ''}`}</h3>
          <div className={classes.menu}>
            <div onClick={() => redirect('/profile')}>
              <div>
                <PersonOutlineOutlinedIcon
                  fontSize="medium"
                  style={{
                    color: grayPrimary,
                  }}
                />
              </div>
              <div className={classes.description}>Mi Perfil</div>
            </div>
            <div onClick={() => redirect('/orders')}>
              <div>
                <AccountBalanceWalletOutlinedIcon
                  fontSize="medium"
                  style={{ color: grayPrimary }}
                />
              </div>
              <div className={classes.description}>Mis Pedidos</div>
            </div>
          </div>
          <div
            className={classes.menu}
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=573013393783&text=Hola,%20estoy%20interesado%20en%20Poket%20Men%C3%BA',
                '_blank'
              )
            }
          >
            <div>
              <div>
                <WhatsAppIcon
                  fontSize="medium"
                  style={{ color: grayPrimary }}
                />
              </div>
              <div className={classes.description}>Contáctanos</div>
            </div>
          </div>
          <div className={classes.menu} onClick={() => logout1()}>
            <div>
              <div>
                <ExitToAppIcon
                  fontSize="medium"
                  style={{ color: grayPrimary }}
                />
              </div>
              <div className={classes.description}>Cerrar sesión</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LateralMenu;
