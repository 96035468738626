import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  leftButton: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  rightButton: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: (props) => ({
    height: 20,
    minWidth: 20,
    border: 'none',
    padding: '0px 4px',
    fontWeight: 'bold',
  }),
  icon: (props) => ({
    fontSize: '18px',
  }),
}));

const CustomButtonGroup = withStyles(() => ({
  root: (props) => ({
    borderRadius: 23,
    height: props.small ? 30 : 36,
  }),
}))(ButtonGroup);

const AddedButton = ({ handleReduce, quantity, handleIncrease, ...props }) => {
  const classes = useStyles(props);

  return (
    <CustomButtonGroup size="small" aria-label="button group" {...props}>
      <Button
        onClick={handleReduce}
        className={clsx(classes.leftButton, classes.button)}
      >
        {quantity > 1 ? (
          <RemoveIcon fontSize="small" className={classes.icon} />
        ) : (
          <DeleteIcon fontSize="small" className={classes.icon} />
        )}
      </Button>
      <Button className={classes.button}>{quantity}</Button>
      <Button
        onClick={handleIncrease}
        className={clsx(classes.rightButton, classes.button)}
      >
        <AddIcon fontSize="small" className={classes.icon} />
      </Button>
    </CustomButtonGroup>
  );
};

export default AddedButton;
