export const graySecond = "#EDEDED";
export const mainColor = "#F7AB00";
export const grayPrimary = "#838383";
export const blackMain = "#272533";
export const letterSpacing = "2px";
export const grayInputsChecks = "#D5CFCF";
export const textColorBlack = "#2A2B39";
export const backgroundBlack = "#33313F";
export const headerTitleColor = "#B2B2B2";
export const greenSuccess = "#09C665";
export const redSecond = "#fff2f0";
export const redPrimary = "#ff4d4f";
