import { BrowserRouter, Switch } from 'react-router-dom';
import { Main, WithoutFooter } from './layouts';
import {
  restaurantRoutes,
  userRoutes,
  commonsRoutes,
  checkoutRoutes,
  ordersRoutes,
  restaurantRoutesList,
} from './routes/';
import { Helmet } from 'react-helmet';
import { HelmetRestaurant } from './components/commons';
import { Provider } from 'react-redux';
import 'antd/dist/antd.css';
import './index.scss';
import { makeStyles } from '@material-ui/core/styles';
import store from './redux';
import Login from './components/login';
import Guard from './components/guard';
import './App.css';

window.imageShark = (url, width, height) =>
  btoa(
    JSON.stringify({
      bucket: 'menuapps3',
      key: url,
      edits: { resize: { width, height, fit: 'cover' } },
    })
  );
window.imageSharkOriginSize = (url) =>
  btoa(JSON.stringify({ bucket: 'menuapps3', key: url }));

const useStyles = makeStyles({});

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Poket Menu</title>
      </Helmet>
      <HelmetRestaurant />
      <Provider store={store()} onUpdate={() => window.scrollTo(0, 0)}>
        <BrowserRouter>
          <Guard />
          <Login />
          <Switch>
            {commonsRoutes.map((route, idx) => (
              <WithoutFooter
                path={route.path}
                key={idx}
                exact={route.exact}
                childrens={route.component}
              />
            ))}
            {checkoutRoutes.map((route, idx) => (
              <WithoutFooter
                path={route.path}
                childrens={route.component}
                key={idx}
                exact={route.exact}
              />
            ))}
            {ordersRoutes.map((route, idx) => (
              <WithoutFooter
                path={route.path}
                childrens={route.component}
                key={idx}
                exact={route.exact}
              />
            ))}
            {restaurantRoutes.map((route, idx) => (
              <Main
                path={route.path}
                key={idx}
                exact={route.exact}
                childrens={route.component}
              />
            ))}
            {userRoutes.map((route, idx) => (
              <Main
                path={route.path}
                childrens={route.component}
                key={idx}
                exact={route.exact}
              />
            ))}
            {restaurantRoutesList.map((route, idx) => (
              <WithoutFooter
                path={route.path}
                childrens={route.component}
                key={idx}
                exact={route.exact}
              />
            ))}
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
