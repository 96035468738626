import React from "react";
import { Helmet } from "react-helmet";
import { s3Path, s3PathImageHandrer } from "../../utils/constants";

export default function ChildComponent(props) {
  return (
    <Helmet>
      <title>
        {props.title ? `Poket Menu | ${props.title}` : `Poket Menu`}
      </title>
      <meta name="description" content={props.description} />
      <meta
        name="og:image"
        content={`${s3PathImageHandrer}/${window.imageShark(
          props.cover,
          1080,
          1080
        )}`}
      />
      <meta
        name="og:description"
        content={
          props.slogan
            ? props.slogan
            : props.description
            ? props.description
            : "Nuestro menu completo con fotos y precios."
        }
      />
      <meta name="og:title" content={props.title} />
    </Helmet>
  );
}
