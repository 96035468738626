import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { greenSuccess, letterSpacing, grayPrimary } from './theme';
import { s3Path } from '../../utils/constants';
import S3Field from '../commons/S3-field';
import FormPsePayments from '../forms/FormPsePayments';
import FormNequiPayments from '../forms/FormNequiPayments';

const useStyles = makeStyles({
  root: {},
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    letterSpacing,
    fontWeight: '500',
    fontSize: 12,
    alignItems: 'center',
  },
  textInfo: {
    color: greenSuccess,
    width: '100%',
    textAlign: 'center',
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: '500',
    fontSize: 10,
  },
  contentIntructions: {
    marginTop: 10,
    marginBottom: 16,
  },
  addMethod: {
    color: greenSuccess,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {props.brand && (
          <div className={classes.contentImage}>
            <img
              src={`${s3Path}/static/logos-tarjetas-de-credito/${
                props.brand === 'bancolombia_transfer'
                  ? 'bancolombia'
                  : props.brand
              }.png`}
              height={40}
              width={60}
              alt=""
            />
          </div>
        )}
        {props.brand && (
          <>
            <div>
              <div>
                {props.brand === 'cash_on_delivery'
                  ? 'efectivo contra entrega'
                  : props.brand === 'bank_transfer'
                  ? 'Transferencia Bancaria'
                  : props.brand == 'dataphone_on_delivery'
                  ? 'Datafono contra entrega'
                  : props.brand == 'payment_on_pickup'
                  ? 'Pago en el establecimiento'
                  : props.brand == 'pse'
                  ? 'Pago por pse'
                  : props.brand == 'nequi'
                  ? 'Pago por nequi'
                  : props.brand == 'bancolombia_transfer'
                  ? 'Pago por bancolombia'
                  : props.brand}
              </div>
              <div>{props.masked_number}</div>
            </div>
            <div
              className={classes.addMethod}
              onClick={() => props.setOpenCreditCard(true)}
            >
              {props.brand && 'Cambiar'}
            </div>
          </>
        )}
      </div>
      {props.brand === 'bank_transfer' && (
        <>
          <div className={classes.contentIntructions}>
            <div className={classes.title}>
              INSTRUCCIONES DE LA TRANFERENCIA
            </div>
            <div>{props.order?.restaurant?.bank_transfer_instructions}</div>
          </div>
          <div>
            <S3Field
              label={'Subir comprobante de pago'}
              idComponent="s3uploader"
              path={`restaurants/${props.order?.restaurant_id}/payments_evidences/${props.order.id}/`}
              id={props.order?.id}
              uploadEvidence={props.setBankTransferEvidence}
              preview={`${props.preview}`}
              errors={props.errors}
            />
          </div>
        </>
      )}
      {props.brand === 'pse' && (
        <FormPsePayments
          dataForm={props.dataPaymentPse}
          getterDataForm={props.setterDataPaymentPse}
        />
      )}

      {props.brand === 'nequi' && (
        <FormNequiPayments
          dataForm={props.dataPaymentPse}
          getterDataForm={props.setterDataPaymentPse}
        />
      )}
    </div>
  );
}
