import react, { useEffect, useState } from "react";
import styled from "styled-components";
import { LogoRestaurant } from "./commons/";
import { SearchInput } from "./index";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { letterSpacing } from "../components/commons/theme";
import { ShareIcon } from "./icons";
import { OpenAlerDialog } from "./commons/";
import { ShareRestaurant } from "./";
import { useLocation } from "react-router";
import { HOST_FRONT } from "../utils/constants";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
  },
  Contentdescription: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    marginLeft: "18px",
    justifyContent: "start",
    zIndex: "80",
    letterSpacing: letterSpacing,
    fontWeight: "500",
    marginTop: 10,
  },
}));

const HeaderRestaurantt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contentInfo {
    background-color: #ededed;
    min-height: 65px;
    border-radius: 0px 0px 20px 20px;
    .contendSearch {
      text-align: center;
      margin-top: 10px;
    }
  }
  .contentdescription {
    display: flex;
    align-items: center;
  }

  .contend {
    // height: 306px;
    top: 0;
    left: 0;
    right: 0;
    .contentItems {
      display: flex;
      min-width: 280px;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 20px;
    }
  }
`;

function HeaderRestaurant(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <HeaderRestaurantt>
      <div className="contend">
        <div className="contentInfo">
          <div className="contentItems">
            <div style={{ display: "flex" }}>
              <div style={{ position: "relative" }}>
                <div style={{ marginTop: "-13px" }}>
                  {props.path_logo ? (
                    <LogoRestaurant
                      path_logo={props.path_logo}
                      height={70}
                      width={70}
                    />
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      style={{
                        height: 70,
                        minWidth: 70,
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={classes.Contentdescription}>
                <div
                  style={{
                    fontSize: 18,
                    textTransform: "uppercase",
                    marginBottom: -8,
                    display: "flex",
                  }}
                >
                  <div>{props.name}</div>
                </div>

                <div>{props.slogan}</div>
              </div>
            </div>
            <div onClick={() => setOpen(true)} style={{ marginLeft: 10 }}>
              <ShareIcon width={22} />
            </div>
          </div>
          {/* <div className="contendSearch">
          <SearchInput />
        </div> */}
        </div>
      </div>
      <OpenAlerDialog
        childrens={
          <ShareRestaurant link={`${HOST_FRONT}${location.pathname}`} />
        }
        open={open}
        handleClose={() => setOpen(false)}
        minWidth={260}
      />
    </HeaderRestaurantt>
  );
}

export default HeaderRestaurant;
