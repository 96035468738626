import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CreditCardInput from 'react-credit-card-input';
import Select from '@material-ui/core/Select';
import { ButtonMain } from '../components/commons';
import { InputField } from '../components/commons/inputs';
import MenuItem from '@material-ui/core/MenuItem';
import { graySecond, greenSuccess } from '../components/commons/theme';
import InputBase from '@material-ui/core/InputBase';
import { userCreditCardService } from '../api/feathers';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    // border: "1px solid #ced4da",
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -20,
    color: 'blue',
  },
  contendCreditCard: {
    display: 'flex',
    width: '100%',
    '& #PaymentForm': {
      width: '100%',
    },
    '& .rccs': {
      width: '100%',
    },
    '& .rccs__card': {
      width: '100%',
    },
  },
  contendCard: {
    marginBottom: 30,
  },
  input: {
    marginTop: 20,
    '& .input': {
      height: 50,
    },
    marginBottom: 10,
  },
  contendForm: {
    width: '100%',
  },
  buttonMain: {
    marginTop: 20,
  },
  select: {
    width: '100%',
    height: 38,
    marginBottom: 6,
    marginTop: 6,
    minWidth: 220,
    borderRadius: 50,
    border: `2px solid ${graySecond}`,
    '&::placeholder': {
      color: 'black',
    },
    '& .MuiSelect-select': {
      paddingLeft: 10,
    },
  },
}));

function AddCreditCard({
  handleCloseDialogFullScreen,
  getCreditCards,
  closedFullScreen,
  setShowFrom,
  setPaymentMethod,
}) {
  const classes = useStyles();

  const [dataCreditCard, setDataCreditCard] = useState({ type_document: 'CC' });
  const [loadAddCreditCard, setLoadAddCreditCard] = useState(false);

  const handleChangeCreditCard = (name, e) => {
    setDataCreditCard({ ...dataCreditCard, [name]: e.target.value });
  };

  function handleSubmit(values, { setSubmitting }) {
    if (loadAddCreditCard) return;

    const exp_month = dataCreditCard.expiry.split('/')[0];
    const exp_year = dataCreditCard.expiry.split('/')[1];

    const data = {
      owner_name: dataCreditCard.name,
      exp_year,
      exp_month,
      identification_number: dataCreditCard.document_number,
      masked_number: dataCreditCard.cardNumber,
      type_document: dataCreditCard.type_document,
      cvc: dataCreditCard.cvc,
      default: 'true',
      address: dataCreditCard.address,
      phone: dataCreditCard.phone,
    };
    setLoadAddCreditCard(true);
    userCreditCardService
      .create(data)
      .then((it) => {
        setLoadAddCreditCard(false);

        if (closedFullScreen) handleCloseDialogFullScreen();
        setShowFrom(false);
        setPaymentMethod('credit_card');
        getCreditCards();
      })
      .catch((e) => {
        setLoadAddCreditCard(false);
        console.log(e.message);
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.contendCreditCard}>
          <div className={classes.contendForm}>
            <Formik
              initialValues={{}}
              onSubmit={handleSubmit}
              validate={(values) => {
                const errors = {};
                if (!dataCreditCard.name) {
                  errors.name = 'El nombre de la tarjeta es necesario';
                }
                if (!dataCreditCard.cardNumber) {
                  errors.cardNumber =
                    'Los datos de la tarjeta son obligatorios';
                }
                if (!dataCreditCard.expiry) {
                  errors.expiry = 'Los datos de la tarjeta son obligatorios';
                }
                if (!dataCreditCard.cvc) {
                  errors.cvc = 'Los datos de la tarjeta son obligatorios';
                }
                if (!dataCreditCard.document_number) {
                  errors.document_number = 'El documento es necesario';
                }
                if (!dataCreditCard.address) {
                  errors.address = 'La dirección es necesaria';
                }

                return errors;
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <div className={classes.input}>
                    <CreditCardInput
                      containerStyle={{
                        '& > div': {
                          boxShadow: '-2px 8px 40px -23px rgba(0,0,0,0.98)',
                        },
                      }}
                      invalidStyle={{ border: 'none', height: 60 }}
                      inputStyle={{
                        backgroundColor: 'white',
                      }}
                      customTextLabels={{
                        invalidCardNumber:
                          'El número de la tarjeta es inválido',
                        expiryError: {
                          invalidExpiryDate:
                            'La fecha de expiración es inválida',
                          monthOutOfRange:
                            'El mes de expiración debe estar entre 01 y 12',
                          yearOutOfRange:
                            'El año de expiración no puede estar en el pasado',
                          dateOutOfRange:
                            'La fecha de expiración no puede estar en el pasado',
                        },
                        invalidCvc: 'El código de seguridad es inválido',
                        invalidZipCode: 'El código postal es inválido',
                        cardNumberPlaceholder: 'Número de tarjeta',
                        expiryPlaceholder: 'MM/AA',
                        cvcPlaceholder: 'COD',
                        zipPlaceholder: 'C.P.',
                      }}
                      cardNumberInputProps={{
                        value: dataCreditCard.cardNumber,
                        onChange: (e) =>
                          handleChangeCreditCard('cardNumber', e),
                      }}
                      cardExpiryInputProps={{
                        value: dataCreditCard.expiry,
                        onChange: (e) => handleChangeCreditCard('expiry', e),
                      }}
                      cardCVCInputProps={{
                        value: dataCreditCard.cvc,
                        onChange: (e) => handleChangeCreditCard('cvc', e),
                      }}
                      fieldClassName="input"
                    />
                  </div>
                  <div>{errors.cardNumber || errors.expiry || errors.cvc}</div>
                  <div>
                    <InputField
                      onChange={(e) => handleChangeCreditCard('name', e)}
                      name="name"
                      placeHolder="Nombre en la tarjeta"
                      style={{
                        height: 40,
                        borderRadius: 20,
                        boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 51px -10px',
                      }}
                      error={errors.name && touched.name}
                    />
                  </div>
                  <div>{errors.name}</div>
                  <div>
                    <Select
                      className={classes.select}
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={dataCreditCard.type_document}
                      input={<BootstrapInput />}
                      onChange={(e) =>
                        handleChangeCreditCard('type_document', e)
                      }
                    >
                      {['CC', 'CE', 'PPN', 'SSN', 'LIC', 'NIT', 'TI'].map(
                        (it, index) => (
                          <MenuItem value={it} key={`selectItem${index}`}>
                            <em>{it}</em>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                  <div>
                    <InputField
                      onChange={(e) =>
                        handleChangeCreditCard('document_number', e)
                      }
                      name="document_number"
                      style={{
                        height: 40,
                        borderRadius: 20,
                        boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 51px -10px',
                      }}
                      placeHolder="Numero de documento"
                      error={errors.name && touched.name}
                    />
                  </div>
                  <div>{errors.document_number}</div>
                  <div>
                    <InputField
                      onChange={(e) => handleChangeCreditCard('address', e)}
                      name="address"
                      placeHolder="Dirección"
                      style={{
                        height: 40,
                        borderRadius: 20,
                        boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 51px -10px',
                      }}
                    />
                  </div>
                  <div>
                    <InputField
                      onChange={(e) => handleChangeCreditCard('phone', e)}
                      name="phone"
                      placeHolder="Celular"
                      style={{
                        height: 40,
                        borderRadius: 20,
                        boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 51px -10px',
                      }}
                    />
                  </div>
                  <div>{errors.address}</div>
                  <div className={classes.buttonMain}>
                    <ButtonMain
                      type="submit"
                      description="Guardar tarjeta"
                      color={greenSuccess}
                      disabled={loadAddCreditCard}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        {/* <Backdrop open={loading} /> */}
      </div>
    </div>
  );
}

export default AddCreditCard;
