import React, { useEffect, useState } from "react";
import Numeral from "numeral";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing, grayPrimary } from "../../components/commons/theme";
import { AlertTitle } from "../../components/commons/";
import { ordersService } from "../../api/feathers";
import { s3PathImageHandrer } from "../../utils/constants";
import {
  TimeLineIcons,
  TimeLine,
  RestaurantListProductsTraking,
  ShippingAddressInfo,
  PaymentMethodTracking,
} from "../../components/";
import { SELECTOR_USER } from "../../redux/selectors/auth";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 50,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    letterSpacing,
    width: "100%",
    padding: "10px 20px 10px 20px",
    justifyContent: "center",
  },
  titleMain: {
    alignItems: "center",
    marginTop: -10,
  },
  containerTimeLineIcons: {
    display: "flex",
    width: "100%",
    marginTop: 10,
  },
  containerTimeLine: {
    display: "flex",
    width: "100%",
    marginTop: 10,
  },
  containerRestaurantListProductsTraking: {
    width: "100%",
  },
  containerShippingAddress: {
    width: "100%",
    marginTop: 10,
    padding: "20px 0px 20px 0px",
    boxShadow: "rgb(0 0 0 / 12%) 0px 7px 16px -6px",
  },
  contentTimeLines: {
    width: "100%",
    boxShadow: "rgb(0 0 0 / 12%) 0px 7px 16px -6px",
    marginTop: 20,
  },
  containerTotals: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "right",
  },
  items: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  contendTitle: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "left",
    marginTop: 20,
    "& h1": {
      marginBottom: 0,
    },
    "& >div": {
      letterSpacing,
      color: grayPrimary,
      fontWeight: 100,
    },
  },
  contentTitleLogo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function Traking(props) {
  const classes = useStyles();
  const match = useRouteMatch("/order/:id");
  const [order, setOrder] = useState({});
  const user = useSelector(SELECTOR_USER);

  useEffect(() => {
    if (user)
      ordersService
        .get(match.params.id)
        .then(it => {
          setOrder(it);
        })
        .catch(e => console.log(e.message));
  }, [user.id]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.contendTitle}>
          <div className={classes.contentTitleLogo}>
            <div>
              <h1 style={{ fontSize: 35 }}>{`Pedido #${order.id}`}</h1>
              <div className={classes.titleMain}>
                Pedido
                {order?.type === "delivery"
                  ? "a domicilio"
                  : order?.type === "pickup"
                  ? "contraentrega"
                  : ""}
              </div>
            </div>
            <div
              className={classes.contentLogo}
              logoOpacity={props.logoOpacity}
            >
              <img
                className={classes.imgProduct}
                src={`${s3PathImageHandrer}/${window.imageShark(
                  order.restaurant?.path_logo
                    ? order.restaurant.path_logo
                    : "static/suenadeli.png",
                  200,
                  200
                )}`}
                alt="logo"
                height={"60px"}
                width={"60px"}
              />
            </div>
          </div>
        </div>
        {order.payments?.[0]?.status === "rejected" ? (
          <div style={{ width: "100%", margin: "20px 10px 10px 10px" }}>
            <AlertTitle
              severity="error"
              title="Error en el pago"
              message={
                order?.payments[0]?.message
                  ? order?.payments[0]?.message
                  : "Error en el pago"
              }
            />
          </div>
        ) : (
          <div className={classes.contentTimeLines}>
            <div className={classes.containerTimeLineIcons}>
              <TimeLineIcons status={order.status} type={order.type} />
            </div>
            <div className={classes.containerTimeLineIcons}>
              <TimeLine status={order.status} type={order.type} />
            </div>
          </div>
        )}
        <div className={classes.containerShippingAddress}>
          <ShippingAddressInfo order={order} />
        </div>
        <div className={classes.containerShippingAddress}>
          <PaymentMethodTracking order={order} />
        </div>
        <div className={classes.containerRestaurantListProductsTraking}>
          <RestaurantListProductsTraking order={order} />
        </div>
        <div className={classes.containerTotals}>
          <div className={classes.items}>
            <div>Productos:</div>
            <div>
              {Numeral(
                order?.total_tax_incl - order?.total_shipping - order?.total_tip
              ).format("($0,0)")}
            </div>
          </div>
          <div className={classes.items}>
            <div>Propina:</div>
            <div>{Numeral(order?.total_tip).format("($0,0)")}</div>
          </div>
          <div className={classes.items}>
            <div>Envío:</div>
            <div>{Numeral(order?.total_shipping).format("($0,0)")}</div>
          </div>
          <div className={classes.items}>
            <div>Total:</div>
            <div>{Numeral(order?.total_tax_incl).format("($0,0)")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Traking;
