import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CardProduct } from "../components/commons";
import { SlideDialog } from "../components";
import { ProductDetails } from "../components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
  },
  tabMain: {
    backgroundColor: "white",
    paddingBottom: 8,
    paddingTop: 8,
  },
  tab: {
    marginLeft: 3,
    marginRight: 3,
  },
  tabPanel: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& > div": {
      width: "100%",
      padding: 0,
      marginTop: 10,
      marginBottom: 70,
    },
  },
  contendProducts: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div style={{ position: "sticky", top: "50px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabMain}
        >
          <Tab label="Entradas" className={classes.tab} {...a11yProps(0)} />
          <Tab label="Postres" className={classes.tab} {...a11yProps(1)} />
          <Tab label="Plato fuerte" className={classes.tab} {...a11yProps(2)} />
          <Tab label="Ensaladas" className={classes.tab} {...a11yProps(3)} />
          <Tab label="Item Five" className={classes.tab} {...a11yProps(4)} />
          <Tab label="Item Six" className={classes.tab} {...a11yProps(5)} />
          <Tab label="Item Seven" className={classes.tab} {...a11yProps(6)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <div className={classes.contendProducts}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(it => (
            <div onClick={() => handleClickOpen()}>
              <CardProduct />
            </div>
          ))}
        </div>
      </TabPanel>
      <div style={{ width: "100%" }}>
        <TabPanel value={value} index={2}>
          Plato fuerte
        </TabPanel>
        <TabPanel value={value} index={3}>
          Ensaladas
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
        </TabPanel>
      </div>
      <SlideDialog
        handleClose={handleClose}
        openModal={openModal}
        children={<ProductDetails handleClose={handleClose} />}
      />
    </div>
  );
}
