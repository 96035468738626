import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const EditIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 21.278 21.275"
    >
      <g id="edit_1_" data-name="edit (1)" transform="translate(0 -0.028)">
        <path
          id="Trazado_815"
          data-name="Trazado 815"
          d="M19.9,1.405a4.693,4.693,0,0,0-6.642,0L1.081,13.578a.609.609,0,0,0-.172.349l-.9,6.683a.607.607,0,0,0,.172.512.619.619,0,0,0,.431.181.493.493,0,0,0,.082,0l4.026-.544a.613.613,0,1,0-.163-1.215l-3.228.435.63-4.661,4.905,4.905a.619.619,0,0,0,.431.181.6.6,0,0,0,.431-.181L19.9,8.047a4.69,4.69,0,0,0,0-6.642ZM13.494,2.9l2.045,2.045L4.426,16.058,2.382,14.013ZM7.3,18.923l-2-2L16.409,5.812l2,2ZM19.26,6.936,14.369,2.044A3.475,3.475,0,0,1,19.26,6.936Z"
          transform="translate(0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
export default EditIcon;
