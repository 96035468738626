import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { blackMain } from '../commons/theme';

const useStyles = makeStyles({
  root: {},
  line: {
    fill: ({ mainColor }) => mainColor,
  },
});

const UserIcon = ({ mainColor = blackMain }) => {
  const classes = useStyles({ mainColor });

  return (
    <svg
      className={classes.root}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40"
      fill="white"
      viewBox="0 0 1200 1200"
    >
      <g>
        <path
          d="M632.81,0C821.88,0,1010.94,0,1200,0c0,162.5,0,325,0,487.5c-1.61-5.03-3.72-9.96-4.76-15.1
		c-13.46-66.61-45-122.62-97.64-166.08c-25.56-21.11-52.22-20.66-70.72,0.32c-18.81,21.33-15.21,49.4,10.63,71.58
		c47.63,40.88,68.97,92.83,68.82,154.93c-0.3,125.73,0.1,251.47-0.15,377.2c-0.23,115.41-80.44,195.65-196.21,195.82
		c-206.95,0.3-413.9,0.34-620.86-0.01c-115.39-0.2-195.16-80.84-195.33-196.69c-0.18-124.17,0.59-248.35-0.28-372.51
		c-0.5-70.88,26.79-126.41,83.35-169.05c100.36-75.65,200.08-152.16,299.92-228.51c79.47-60.78,165.46-61.35,245.38-0.88
		c54.78,41.45,109.32,83.22,163.9,124.94c16.06,12.28,33.26,17.52,52.33,8.11c19.58-9.66,27.46-26.25,27.32-47.76
		c-0.32-48.42,0.32-96.84-0.28-145.25c-0.39-30.76-19.53-50.35-47.05-50.16c-27.5,0.19-45.61,19.78-46.41,50.85
		c-0.44,16.77-0.08,33.57-0.08,55.1c-13.78-10.33-23.78-17.36-33.28-25.01C777.01,59.67,714.6,11.63,632.81,0z M564.17,598.69
		c-0.37-32.03-26.26-57.15-58.56-56.82c-32.37,0.34-57.57,25.86-57.31,58.04c0.28,34.14,25.42,58.38,59.69,57.57
		C540.86,656.71,564.56,631.9,564.17,598.69z M751.57,601.97c1.09-33.03-22.39-58.44-55.18-59.71c-34.25-1.32-60,22.8-60.51,56.69
		c-0.5,33.04,23.41,57.89,56.34,58.54C725.6,658.16,750.49,634.87,751.57,601.97z M506.48,844.99c33.17-0.12,57.42-24.23,57.6-57.28
		c0.18-33.03-23.9-57.57-56.92-58.01c-34.1-0.46-59.21,24.53-58.77,58.49C448.82,821.21,473.26,845.11,506.48,844.99z M751.58,787.2
		c-0.1-33-24.37-57.29-57.44-57.5c-34.02-0.21-58.97,25.06-58.25,59.01c0.7,32.96,25.34,56.63,58.6,56.28
		C727.59,844.65,751.68,820.26,751.58,787.2z"
        />
        <path
          className={classes.line}
          d="M632.81,0c81.78,11.63,144.2,59.67,205.81,109.32c9.5,7.65,19.5,14.69,33.28,25.01c0-21.54-0.36-38.33,0.08-55.1
		c0.81-31.07,18.92-50.66,46.41-50.85c27.52-0.19,46.66,19.41,47.05,50.16c0.61,48.41-0.04,96.84,0.28,145.25
		c0.14,21.51-7.74,38.1-27.32,47.76c-19.07,9.41-36.27,4.16-52.33-8.11c-54.58-41.72-109.12-83.49-163.9-124.94
		c-79.92-60.47-165.91-59.89-245.38,0.88c-99.84,76.35-199.56,152.86-299.92,228.51c-56.57,42.64-83.85,98.16-83.35,169.05
		c0.87,124.17,0.1,248.34,0.28,372.51c0.17,115.85,79.94,196.49,195.33,196.69c206.95,0.35,413.9,0.32,620.86,0.01
		c115.78-0.17,195.98-80.41,196.21-195.82c0.25-125.73-0.15-251.47,0.15-377.2c0.15-62.1-21.19-114.05-68.82-154.93
		c-25.84-22.18-29.44-50.25-10.63-71.58c18.5-20.98,45.15-21.43,70.72-0.32c52.64,43.46,84.18,99.47,97.64,166.08
		c1.04,5.14,3.15,10.07,4.76,15.1c0,156.25,0,312.5,0,468.75c-1.45,3.44-3.5,6.76-4.28,10.34
		c-25.88,120.2-98.32,195.76-217.24,226.81c-8.94,2.33-17.95,4.4-26.92,6.6c-234.38,0-468.75,0-703.12,0
		c-4.4-1.4-8.7-3.32-13.2-4.13c-77.13-13.81-141.28-50.94-183.02-116.89C28.76,1041.9,17.02,997.4,0,956.25C0,800,0,643.75,0,487.5
		c1.33-2.7,3.38-5.28,3.87-8.12c13.25-77.71,53.08-138.48,115.74-185.66c84.13-63.34,168.23-126.76,251.04-191.8
		C429.97,55.34,489.99,11.48,567.19,0C589.06,0,610.94,0,632.81,0z"
        />
        <path
          d="M567.19,0c-77.2,11.48-137.21,55.34-196.53,101.92c-82.82,65.04-166.91,128.45-251.04,191.8
		C56.95,340.9,17.12,401.67,3.87,479.38c-0.48,2.84-2.54,5.42-3.87,8.12C0,325.36,0,163.22,0,0C189.06,0,378.12,0,567.19,0z"
        />
        <path
          d="M0,956.25c17.02,41.15,28.76,85.65,52.22,122.73c41.73,65.95,105.89,103.09,183.02,116.89
		c4.5,0.81,8.8,2.73,13.2,4.13c-82.81,0-165.62,0-248.44,0C0,1118.75,0,1037.5,0,956.25z"
        />
        <path
          d="M951.56,1200c8.98-2.19,17.98-4.26,26.92-6.6c118.92-31.05,191.35-106.61,217.24-226.81
		c0.77-3.59,2.82-6.9,4.28-10.34c0,81.25,0,162.5,0,243.75C1117.19,1200,1034.38,1200,951.56,1200z"
        />
        <path
          className={classes.line}
          d="M564.17,598.69c0.39,33.21-23.31,58.02-56.17,58.8c-34.27,0.81-59.41-23.44-59.69-57.57
		c-0.27-32.18,24.94-57.71,57.31-58.04C537.91,541.54,563.8,566.66,564.17,598.69z"
        />
        <path
          className={classes.line}
          d="M751.57,601.97c-1.08,32.9-25.98,56.19-59.35,55.53c-32.93-0.65-56.84-25.5-56.34-58.54
		c0.51-33.89,26.26-58.01,60.51-56.69C729.18,543.53,752.66,568.93,751.57,601.97z"
        />
        <path
          className={classes.line}
          d="M506.48,844.99c-33.21,0.12-57.66-23.78-58.09-56.79c-0.44-33.96,24.67-58.95,58.77-58.49
		c33.03,0.44,57.11,24.99,56.92,58.01C563.89,820.77,539.65,844.88,506.48,844.99z"
        />
        <path
          className={classes.line}
          d="M751.58,787.2c0.1,33.06-23.99,57.45-57.09,57.79c-33.27,0.35-57.91-23.32-58.6-56.28c-0.72-33.95,24.23-59.22,58.25-59.01
		C727.21,729.91,751.48,754.2,751.58,787.2z"
        />
      </g>
    </svg>
  );
};
export default UserIcon;
