import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grayPrimary, letterSpacing } from "./commons/theme";

const useStyles = makeStyles({
  root: {
    padding: "0px 24px 0px 24px",
  },
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: "500",
    fontSize: 10,
  },
  descriptioAddress: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    letterSpacing,
    fontWeight: "500",
    fontSize: 12,
  },
});

export default function ShippingAddress(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {props.order.type == "pickup"
          ? "DIRECCIÓN DE ENTREGA"
          : "DIRECCIÓN DE ENVIO"}
      </div>
      <div className={classes.descriptioAddress}>
        <div style={{ maxWidth: 350 }}>
          <div>
            {props.order.type == "pickup" ? (
              props.order?.restaurant?.address -
              props.order?.restaurant?.city.name
            ) : (
              <>
                <div>{props.order?.delivery_address}</div>
                <div>{props.order?.delivery_apartment}</div>
                <div>{props.order?.delivery_city_name}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
