import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    // paddingBottom: 30,
  },
});

function WithoutFooter(props) {
  const classes = useStyles(props);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div className={classes.root}>{props.childrens}</div>;
}

export default WithoutFooter;
