import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const UserIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      id="dish_1_"
      data-name="dish (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="27.7"
      height="25.083"
      viewBox="0 0 27.7 18.953"
    >
      <path
        id="Trazado_23"
        data-name="Trazado 23"
        d="M27.6,24.088a.933.933,0,0,0-.833-.508H26.23A12.655,12.655,0,0,0,16.016,11.663a2.247,2.247,0,0,0,.021-.233,2.43,2.43,0,1,0-4.86,0,2.111,2.111,0,0,0,.021.233A12.654,12.654,0,0,0,.984,23.579H.938a.937.937,0,0,0-.762,1.482l1.1,1.538A3.239,3.239,0,0,0,3.9,27.953H23.8A3.239,3.239,0,0,0,26.427,26.6l1.1-1.538A.935.935,0,0,0,27.6,24.088ZM12.152,11.513c0-.028,0-.056,0-.084a1.458,1.458,0,1,1,2.916,0c0,.028,0,.055,0,.084l-.068-.006c-.167-.018-.335-.032-.5-.043-.062,0-.124-.011-.186-.014-.231-.013-.463-.02-.7-.02s-.466.007-.7.02c-.062,0-.124.01-.186.014-.169.012-.337.025-.5.043Zm-.292,1.019c.26-.039.522-.067.785-.088l.16-.012a11.926,11.926,0,0,1,1.6,0l.16.012c.262.021.524.05.785.088a11.684,11.684,0,0,1,9.906,11.047H1.954A11.684,11.684,0,0,1,11.861,12.533Zm13.775,13.5a2.266,2.266,0,0,1-1.84.947H3.9a2.267,2.267,0,0,1-1.84-.947L1.005,24.551H26.7Z"
        transform="translate(0 -9)"
        fill={props.color}
      />
    </svg>
  );
};
export default UserIcon;
