import React from "react";
import RestaurantMenu from "../views/restaurants/restaurant-menu";
import RestaurantListBranch from "../views/restaurants/restaurant-lists-branch";
import {
  Profile,
  Home,
  Ckeckout,
  Terms,
  OrderDetails,
  Orders,
} from "../views/";

const ordersRoutes = [
  {
    path: ["/order/:id"],
    component: <OrderDetails />,
    exact: true,
  },
  {
    path: ["/orders"],
    component: <Orders />,
    exact: true,
  },
];

const restaurantRoutes = [
  {
    path: ["/r-:slug", "/r-:slug/p-:id"],
    component: <RestaurantMenu />,
    exact: true,
  },
];

const restaurantRoutesList = [
  {
    path: ["/:slug"],
    component: <RestaurantListBranch />,
    exact: true,
  },
];

const checkoutRoutes = [
  {
    path: ["/checkout/:id"],
    component: <Ckeckout />,
    exact: true,
  },
];

const userRoutes = [
  {
    path: ["/profile"],
    component: <Profile/>,
    exact: true,
  },
];

const commonsRoutes = [
  {
    path: ["/"],
    component: <Home/>,
    exact: true,
  },
  {
    path: ["/terms"],
    component: <Terms/>,
    exact: true,
  },
];

export {
  restaurantRoutes,
  userRoutes,
  commonsRoutes,
  checkoutRoutes,
  ordersRoutes,
  restaurantRoutesList,
};
