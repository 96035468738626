import React, { useState } from "react";
import Numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import { greenSuccess, grayPrimary, letterSpacing } from "./commons/theme";

const useStyles = makeStyles({
  root: {},
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: "500",
    fontSize: 10,
  },
  descriptioAddress: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    letterSpacing,
    fontWeight: "500",
    fontSize: 12,
  },
});

export default function ShippingAddress(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>DIRECCIÓN DEL RESTAURANTE</div>
        <div className={classes.descriptioAddress}>
          <div style={{ maxWidth: 350 }}>
          <div>
              {`${props.order?.restaurant.address} - ${props.order?.restaurant.city.name}`}
            </div>
          </div>
        </div>
    </div>
  );
}
