import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { grayPrimary, greenSuccess, letterSpacing } from './commons/theme';
import { Rate } from 'antd';
import styled, { css } from 'styled-components';
import { reviewsService } from '../api/feathers';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 24px 0px 24px',
  },
  title: {
    color: grayPrimary,
    margin: '10px 0px',
    letterSpacing,
    fontWeight: '500',
    fontSize: 10,
  },
  textAreaObservation: {
    resize: 'none',
    width: '100%',
    borderRadius: '6px',
    fontSize: '13px',
    border: `1px solid ${grayPrimary}`,
    outline: 'none',
    padding: '7px',
  },
  spanLink: {
    color: '#09c665',
    cursor: 'pointer',
    fontWeight: '600',
    fontSize: '12px',
  },
  button: {
    width: '100%',
    borderRadius: '14px',
    backgroundColor: greenSuccess,
    color: '#ffffff',
    border: 'none',
    outline: 'none',
    padding: '5px 0px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
}));

const Commentary = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0px;
`;

const CommentaryCard = styled.div`
  border: 1px solid;
  width: max-content;
  padding: 5px 7px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: 0.2s;
  font-size: 12px;
  font-weight: 400;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #09c665;
      color: #ffff;
    `}
`;

const arrCommentary = [
  {
    value: 'Buen Servicio',
    rate: 'good',
  },
  {
    value: '¡Lo Mejor!',
    rate: 'good',
  },
  {
    value: 'Buena presentación',
    rate: 'good',
  },
  {
    value: 'Excelente comida',
    rate: 'good',
  },
  {
    value: 'Regular',
    rate: 'regular',
  },
  {
    value: 'Entrega demorada',
    rate: 'bad',
  },
  {
    value: 'No era lo que esperaba',
    rate: 'bad',
  },
  {
    value: 'No me gusto',
    rate: 'bad',
  },
];

export const Review = ({ order, review }) => {
  const classes = useStyles();

  const [rateValue, setRateValue] = useState(0);
  const [commentarySelected, setCommentarySelected] = useState({});
  const [commentaryValue, setCommentaryValue] = useState(false);
  const [dataReview, setDataReview] = useState(null);

  const handleChangeRate = (rate) => {
    setRateValue(rate);
    setCommentarySelected({});
  };

  const handleCreate = async () => {
    const message = commentaryValue && commentarySelected?.value ? `${commentarySelected?.value} - ${commentaryValue} ` : `${commentarySelected?.value}`;
    try {
      const resp = await reviewsService.create({
        order_id: order?.id,
        score: rateValue,
        order_type: order?.type,
        message,
      });
      setDataReview(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!review?.loading && review?.data) {
      setDataReview(review?.data);
      setRateValue(review?.data?.score);
      setCommentaryValue(review?.data?.message?.split('-')?.[1]);
      setCommentarySelected({
        value: review?.data?.message?.split('-')?.[0]?.trim(),
      });
    } else if (!review?.called && !review.loading) {
      setDataReview(null);
    }
  }, [review]);
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {dataReview ? 'CALIFICADO' : 'CALIFICAR'}
      </div>
      <Rate
        disabled={dataReview}
        value={rateValue}
        onChange={handleChangeRate}
      />
      <Commentary>
        {!dataReview
          ? arrCommentary
            .filter((x) =>
              rateValue >= 1 && rateValue <= 2
                ? x?.rate === 'bad'
                : rateValue >= 3 &&
                (x?.rate === 'good' || x?.rate === 'regular')
            )
            .map((x, i) => (
              <CommentaryCard
                onClick={() =>
                  setCommentarySelected({
                    value: x?.value,
                  })
                }
                selected={
                  commentarySelected?.value?.trim() === x?.value?.trim()
                }
                key={`commentary${i}`}
              >
                {x?.value}
              </CommentaryCard>
            ))
          : dataReview?.message?.split('-')?.[0] !== 'undefined' && (
            <CommentaryCard selected={true}>
              {dataReview?.message?.split('-')?.[0]}
            </CommentaryCard>
          )}
      </Commentary>
      {(!dataReview || dataReview?.message?.split('-')[1]) && (
        <>
          <div className={classes.title}> COMENTARIO {!dataReview ? `(OPCIONAL)` : ''}:  </div>
          <div style={{ width: '100%', textAlign: 'start' }}>
            <textarea
              onChange={({ target: { value } }) => setCommentaryValue(value)}
              value={commentaryValue || ''}
              className={classes.textAreaObservation}
              maxLength={254}
              disabled={dataReview?.message?.split('-')[0]}
            />
          </div>
        </>
      )}
      {!dataReview && (
        <button
          className={classes.button}
          type="button"
          onClick={() => handleCreate()}
        >
          Calificar
        </button>
      )}
    </div>
  );
};
