import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 150,
    maxWidth: 180,
    boxShadow: "none",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  skeleton: {
    borderRadius: "20px",
    marginTop: 18,
  },
  skeletonLogo: {
    position: "absolute",
    marginTop: -65,
    marginLeft: 18,
  },
}));

export default function SkeletonCardRestarant(props) {
  const classes = useStyles();

  return (
    <div>
      <Skeleton
        className={classes.skeleton}
        variant="rect"
        width={"100%"}
        height={200}
      />
      <div className={classes.skeletonLogo}>
        <div style={{ display: "flex" }}>
          <div>
            <Skeleton variant="circle" width={50} height={50} />
          </div>
          <div style={{ marginLeft: 10 }}>
            <Skeleton animation="wave" height={25} width={100} />
            <Skeleton animation="wave" height={15} width={200} />
          </div>
        </div>
      </div>
    </div>
  );
}
