import { Input, Space } from "antd";
import styled, { css } from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import { graySecond } from "./commons/theme";

const ContentInput = styled.div`
  .ant-input-group-addon {
    background: transparent;
    border: none;
  }
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

function SearchInput(props) {
  return (
    <ContentInput>
      <Input
        bordered={false}
        placeholder="Busca un plato"
        allowClear
        // onSearch={/* () => onSearch */}
        style={{
          width: 250,
          height: "100%",
          border: `solid 2px ${graySecond}`,
          borderRadius: 20,
        }}
        addonAfter={
          <SearchOutlined
            style={{ backgroundColor: "transparent !important" }}
          />
        }
      />
    </ContentInput>
  );
}

export default SearchInput;
