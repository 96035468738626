import React, { useEffect, useRef, useState } from 'react';
import lodash, { create } from 'lodash';
import numeral from 'numeral';
import {
  grayPrimary,
  graySecond,
  letterSpacing,
  mainColor,
} from './commons/theme';
import { RadioGroup, Snackbar } from './commons';
import { ButtonAdd, CommingSoon } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { Divider, OpenAlerDialog } from '../components/commons';
import Skeleton from '@material-ui/lab/Skeleton';
import { menuItemsService, ordersService } from '../api/feathers';
import { makeStyles } from '@material-ui/core/styles';
import { s3PathImageHandrer } from '../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { SELECTOR_IS_AUTH } from '../redux/selectors/auth';
import { SELECTOR_RESTAURANT } from '../redux/selectors/restaurant';
import { SELECTOR_TYPE_ORDER_SELECTED } from '../redux/selectors/current-type-order';
import { SELECTOR_UPDATE_ORDERS } from '../redux/selectors/update-orders';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { getProductData } from './commons/product-dialog';
import { ProductSelectedSet } from '../redux/actions/product-selected';
import { updateOrderSet } from '../redux/actions/update-order';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    boxShadow: 'none',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 0,
    marginBottom: 20,
  },
  contendImg: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 100,
    minWidth: 330,
    letterSpacing: letterSpacing,
    '& > :first-child': {
      marginTop: 20,
      textTransform: 'uppercase',
    },
    '& > :nth-child(2n)': {
      alignItems: 'center',
    },

    '& > :nth-child(4n)': {
      marginTop: 15,
      fontSize: 16,
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  contendPersonalization: {
    width: '100%',
  },
  formPersonalization: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    backgroundColor: graySecond,
    padding: 2,
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: 50,
    fontSize: 12,
  },
  radioGroup: {
    marginTop: 30,
  },
  buttonAdd: {
    position: 'fixed',
    bottom: '40px',
    backgroundColor: '#0C9',
    color: '#FFF',
    borderRadius: '50px',
    textAlign: 'center',
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '14px !important',
    color: grayPrimary,
  },
  price: {
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 18,
  },
  textAreaObservation: {
    resize: 'none',
    width: '100%',
    borderRadius: '6px',
    fontSize: '13px',
    border: `1px solid ${grayPrimary}`,
    outline: 'none',
    padding: '7px',
  },
}));

export default function ProductDetails(props) {
  const child = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const is_auth = useSelector(SELECTOR_IS_AUTH);
  const restaurant = useSelector(SELECTOR_RESTAURANT);
  const currenTypeOrder = useSelector(SELECTOR_TYPE_ORDER_SELECTED);
  const updateOrders = useSelector(SELECTOR_UPDATE_ORDERS);
  const [menuItem, setMenuItem] = useState();
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [personalization, setPersonalization] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [totalPersonalization, setTotalPersonalization] = useState(0);
  const [personalizationSelect, setPersonalizationSelect] = useState([]);
  const [errors, setErrrors] = useState({
    required: [],
    sections: [],
    message: null,
  });
  const [observationValue, setObservationValue] = useState('');
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  const createOrderNextLogin = () => {
    createOrder();
  };

  const handleChange = (event) => setObservationValue(event?.target?.value);

  useEffect(() => {
    if (params.login === 'true') {
      createOrderNextLogin();
    }
  }, [location]);

  useEffect(() => {
    setMenuItem(null);
    const data = getProductData(location.pathname);

    if (data?.params?.p_id) {
      getMenu(data.params.p_id);
    }
    setTotalPersonalization(0);
    setPersonalizationSelect([]);
    setErrrors({ required: [], sections: [] });
  }, [location.pathname]);

  useEffect(() => {
    if (child?.current) {
      child.current.parentNode.style.padding = '0px';
    }
  }, [child?.current, menuItem]);

  const getPersonalization = ({ data, sum }) => {
    if (sum == 'sum-and-rest') {
      const valueAnt =
        data.personalizationOptionsSectionAnt.price_tax_incl * quantity;

      const currentValue =
        data.personalizationOptionsSection.price_tax_incl * quantity;

      const tValueAnt = Math.abs(totalPersonalization - valueAnt);

      setTotalPersonalization(tValueAnt + currentValue);

      return;
    }

    if (sum) {
      const exist = personalizationSelect.find((it) => it.id == data.id);

      if (!exist) {
        //PERSONALIZACIONES
        setPersonalizationSelect([
          ...personalizationSelect,
          {
            id: data.id,
            personalization_option_id: data.personalization_option_id,
            personalization_section_id: data.personalization_section_id,
            name: data.name,
          },
        ]);
        //PRECIO

        setTotalPersonalization(
          totalPersonalization + data.price_tax_incl * quantity
        );
      }
    } else {
      setTotalPersonalization(
        totalPersonalization - data.price_tax_incl * quantity
      );

      const filterPersonalization = personalizationSelect.filter(
        (value) => value.id != data.id
      );

      setPersonalizationSelect(filterPersonalization);
    }
  };

  const determinedPrice = (menuItem) => {
    if (menuItem.price_functionality == 'StandardPlusPersonalization')
      setTotalPrice(menuItem.price_tax_incl * quantity);

    if (menuItem.price_functionality == 'DeterminedByPersonalization') {
      setTotalPrice(0);
    }
  };

  const getMenu = (menuId) => {
    menuItemsService
      .get(menuId, {
        query: {
          $client: {
            type: currenTypeOrder === 'pick_up' ? 'pickup' : currenTypeOrder,
            restaurant_id: restaurant.id,
          },
        },
      })
      .then((menuItem) => {
        setMenuItem(menuItem);
        dispatch(ProductSelectedSet(menuItem));
        setLoad(true);
        determinedPrice(menuItem);

        const personalizationMenuSections = lodash.orderBy(
          menuItem.personalization_menu_item_sections,
          ['position'],
          ['desc']
        );

        setPersonalization(
          personalizationMenuSections.map((it) => it.personalization_section)
        );
      })
      .catch((e) => console.log(e));
  };

  const increment = (quantity) => {
    if (totalPersonalization) {
      const p = totalPersonalization / (quantity - 1);
      setTotalPersonalization(p * quantity);
    }

    if (menuItem.price_functionality === 'StandardPlusPersonalization')
      setTotalPrice(menuItem?.price_tax_incl * quantity);
  };

  const decreement = (quantity) => {
    if (totalPersonalization) {
      const p = totalPersonalization / (quantity + 1);

      setTotalPersonalization(Math.abs(p - totalPersonalization));
    }

    if (menuItem.price_functionality === 'StandardPlusPersonalization')
      setTotalPrice(menuItem?.price_tax_incl * quantity);
  };

  const createOrder = () => {
    if (!is_auth) history.push(`${location.pathname}?login=false`);
    else {
      const personalizacionSelectGroup = lodash.groupBy(
        personalizationSelect,
        'personalization_section_id'
      );

      const keysPersonalizacionSelectGroup = Object.keys(
        personalizacionSelectGroup
      );

      const personalization_sections = [];
      for (
        let index = 0;
        index < keysPersonalizacionSelectGroup.length;
        index++
      ) {
        const personalization = keysPersonalizacionSelectGroup[index];

        personalization_sections.push({
          id: personalization,
          personalization_opt_sections: personalizacionSelectGroup[
            personalization
          ].map((it) => it.id),
        });
      }

      const data = {
        restaurant_id: restaurant.id,
        type: currenTypeOrder === 'pick_up' ? 'pickup' : currenTypeOrder,
        menu_item_id: menuItem.id,
        quantity,
        personalization_sections,
        observations: observationValue,
      };
      ordersService
        .create(data)
        .then((it) => {
          dispatch(updateOrderSet(updateOrders + 1));
          setObservationValue('');
          history.push(`/r-${restaurant.slug}`);
        })
        .catch((it) => {
          setErrrors({
            message: it.message,
            required: it.data?.required
              ? it.data.required.map((it) => it.id)
              : [],
            sections: it.data?.sections ? it.data.sections.map((it) => it) : [],
          });
        });
    }
  };

  return (
    <div className={classes.root} ref={child}>
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          onClick={() => props.handleClose()}
          style={{
            zIndex: 10,
            position: 'absolute',
            marginLeft: 30,
            marginTop: 30,
          }}
        >
          <ArrowBackSharpIcon
            style={{
              color: 'white',
              backgroundColor: grayPrimary,
              borderRadius: '50%',
              fontSize: 26,
            }}
          />
        </div>
      </div>
      <div className={classes.container}>
        {load &&
          menuItem?.menu_category.layout !== 'FullWidthNoImage' &&
          menuItem?.path_main && (
            <div className={classes.contendImg}>
              {load ? (
                <ImageGallery
                  showThumbnails={false}
                  showPlayButton={false}
                  items={[
                    {
                      original: `${s3PathImageHandrer}/${window.imageShark(
                        menuItem?.path_main
                          ? menuItem?.path_main
                          : 'static/suenadeli.png',
                        1000,
                        1000
                      )}`,
                    },
                  ]}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={350}
                />
              )}
            </div>
          )}
        <div className={classes.description}>
          <div>{menuItem?.name}</div>
          {menuItem?.price_functionality == 'StandardPlusPersonalization' &&
            menuItem?.strikethrough_price && (
              <div className={classes.lineThrough}>
                {numeral(menuItem?.strikethrough_price).format('($0,0)')}
              </div>
            )}
          <div className={classes.price}>
            {menuItem?.price_functionality == 'StandardPlusPersonalization'
              ? numeral(menuItem?.price_tax_incl).format('($0,0)')
              : menuItem?.price_functionality == 'DeterminedByPersonalization'
              ? 'Precio según elección'
              : 'Precio temporada'}
          </div>
          <Divider
            size={2}
            width={125}
            backgroundColor={
              restaurant?.primary_color ? restaurant.primary_color : mainColor
            }
            marginTop={5}
            marginBottom={5}
            style={{ marginLeft: 30 }}
          />
          <div>{menuItem?.description}</div>
          {menuItem?.observations === 'active' && (
            <div style={{ width: '100%', textAlign: 'start' }}>
              Observaciones:
              <textarea
                className={classes.textAreaObservation}
                value={observationValue}
                maxLength={254}
                onChange={handleChange}
              />
            </div>
          )}
          {menuItem?.personalization_menu_item_sections.length ? (
            <div className={classes.contendPersonalization}>
              <div className={classes.formPersonalization}></div>
              <div>
                <div className={classes.radioGroup}>
                  <RadioGroup
                    personalization_options={personalization} //esto hay que eliminarlo
                    getPersonalization={getPersonalization}
                    personalizationSelect={personalizationSelect}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {params.typeOrder !== 'undefined' && params.typeOrder !== 'instore' && (
            <div className={classes.buttonAdd}>
              <ButtonAdd
                handleIncrease={() => {
                  setQuantity(quantity + 1);
                  increment(quantity + 1);
                }}
                handleReduce={() => {
                  setQuantity(quantity - 1);
                  decreement(quantity - 1);
                }}
                handleCreateOrder={() => createOrder()}
                quantity={quantity}
                totalPrice={totalPrice + totalPersonalization}
                disableLessButton={quantity == 1}
                color={
                  restaurant?.primary_color
                    ? restaurant.primary_color
                    : mainColor
                }
              />
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={errors.message}
        severity={'error'}
        message={errors.message}
        handleClose={() =>
          setErrrors({ required: [], sections: [], message: null })
        }
      />
      <OpenAlerDialog
        childrens={
          <CommingSoon
            handleClose={() => setOpenDialog(false)}
            title={`Este restaurante no tiene esta opcion aun.`}
          />
        }
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        minWidth={360}
      />
    </div>
  );
}
