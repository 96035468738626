import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const ShareIcon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      id="Capa_1"
      enable-background="new 0 0 551.13 551.13"
      // height="100"
      viewBox="0 0 551.13 551.13"
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m465.016 172.228h-51.668v34.446h34.446v310.011h-344.457v-310.011h34.446v-34.446h-51.669c-9.52 0-17.223 7.703-17.223 17.223v344.456c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-344.456c0-9.52-7.703-17.223-17.223-17.223z" />
      <path d="m258.342 65.931v244.08h34.446v-244.08l73.937 73.937 24.354-24.354-115.514-115.514-115.514 115.514 24.354 24.354z" />
    </svg>
  );
};
export default ShareIcon;
