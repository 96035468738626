import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LogoRestaurant from '../../components/commons/Logo-restaurant';
import { grayPrimary, graySecond } from '../../components/commons/theme';
import { rawQuerysService } from '../../api/feathers';
import { useHistory } from 'react-router-dom';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDialog-container': {
      transition: `height 4s, ${props.height}`,
      width: '100%',
      height: props.height,
    },
    // "& .MuiDialogContent-root": {
    //   padding: "20px 10px",
    // },
  }),
  contentTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    fontSize: 24,
    fontWeight: 500,
  },
  note: {
    fontSize: 20,
    marginTop: -20,
    fontWeight: 100,
  },
  accordion: {
    marginTop: 10,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 7px 16px -6px',
  },
  contentRestaurant: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    marginTop: 20,
    marginLeft: 10,
  },
  text: {
    marginTop: -2,
    fontSize: 12,
    color: grayPrimary,
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentDescription: {
    width: '100%',
  },
  description: {
    width: '100%',
    marginLeft: 10,
  },
});

const Message = (props) => (
  <>
    {props.type === 'instore'
      ? 'PEDIDO EN EL RESTAURANTE'
      : props.restaurant.type === 'delivery'
      ? 'PEDIDO A DOMICILIO'
      : 'PEDIDO PICKUP'}
  </>
);

const PaymentMessage = (props) => {
  const { payment } = props;
  return (
    <>
      {payment.payment_status === 'rejected'
        ? 'RECHAZADO'
        : payment.payment_status === 'completed'
        ? 'COMPLETADO'
        : 'PENDIENTE'}
    </>
  );
};

const LabelWaitingGatewayConfirmation = (props) => {
  const useStyles = makeStyles({
    info: {
      padding: '6px',
      backgroundColor: graySecond,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        marginRight: 2,
      },
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.info}>
      <div>
        <AccessTimeIcon fontSize="small" />
      </div>
      <div>Pedido en verificación</div>
    </div>
  );
};

function Orders(props) {
  const classes = useStyles();
  const [ordersActives, setOrdersActives] = useState([]);
  const [ordersCompleted, setOrderCompeted] = useState([]);
  const history = useHistory();

  useEffect(() => {
    rawQuerysService
      .find({ query: { limit: 300, $client: { myOrders: 'true' } } })
      .then((it) => {
        const orderCompletedInit = [];
        const orderActiveInit = [];

        for (const key in it) {
          const order = it[key];
          if (order.status === 'completed' || order.status === 'rejected') {
            orderCompletedInit.push(order);
          } else if (
            order.status === 'new' ||
            order.status === 'active' ||
            order.status === 'in_progress' ||
            order.status === 'shipped' ||
            order.status === 'ready_for_pickup' ||
            order.status === 'ready_for_shipping' ||
            order.status === 'waiting_gateway_confirmation'
          ) {
            orderActiveInit.push(order);
          }
        }

        setOrdersActives(orderActiveInit);
        setOrderCompeted(orderCompletedInit);
      });
  }, []);

  const showOrderDetail = (id) => {
    history.push(`order/${id}`);
  };

  return (
    <div>
      <div className={classes.content}>
        <div onClick={() => history.goBack()}>
          <ArrowBackSharpIcon
            style={{
              color: 'white',
              backgroundColor: grayPrimary,
              borderRadius: '50%',
              fontSize: 26,
            }}
          />
        </div>
        {ordersActives.length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.contentTitle}>
              <div>Pedidos activos </div>
            </div>
          </div>
        )}
        <div className={classes.lists}>
          {ordersActives.map((it) => (
            <div
              className={classes.accordion}
              onClick={() => showOrderDetail(it.id)}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.contentDescription}>
                  <div className={classes.contentRestaurant}>
                    <div>
                      <LogoRestaurant
                        path_logo={it.path_logo}
                        height={70}
                        width={70}
                      />
                    </div>
                    <div className={classes.description}>
                      <div>{it.name}</div>
                      <div className={classes.text}>
                        <div>
                          <Message restaurant={it} />
                        </div>
                        {it.status === 'waiting_gateway_confirmation' ? (
                          <div>
                            <LabelWaitingGatewayConfirmation />
                          </div>
                        ) : (
                          <div>
                            <PaymentMessage payment={it} />
                          </div>
                        )}
                      </div>
                      <div className={classes.text}>Pedido #{it.id}</div>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
            </div>
          ))}
        </div>
        <div className={classes.contentTitle} style={{ marginTop: 20 }}>
          <p>Pedidos historicos</p>
        </div>
        <div className={classes.lists}>
          {ordersCompleted.map((it) => (
            <div
              className={classes.accordion}
              onClick={() => showOrderDetail(it.id)}
            >
              <AccordionSummary
                expandIcon={<ChevronRightIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.contentDescription}>
                  <div className={classes.contentRestaurant}>
                    <div>
                      <LogoRestaurant
                        path_logo={it.path_logo}
                        height={70}
                        width={70}
                      />
                    </div>
                    <div className={classes.description}>
                      <div>{it.name}</div>
                      <div className={classes.text}>
                        <div>
                          <Message restaurant={it} />
                        </div>
                        <div>
                          <PaymentMessage payment={it} />
                        </div>
                      </div>
                      <div className={classes.text}>Pedido #{it.id}</div>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Orders;
