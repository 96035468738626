import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

// reducers
import { restaurant } from './reducers/restaurant';
import { product } from './reducers/product-selected';
import { session } from './reducers/auth';
import { type_order } from './reducers/current-type-order';
import { current_shopping_cart } from './reducers/current-shopping-cart';
import { show_orders } from './reducers/show-orders';
import { update_orders } from './reducers/update-orders';
import { addresses } from './reducers/addresses';
import { credit_cards } from './reducers/credit-cards';
import { payment_method } from './reducers/payment-method';

// noinspection JSUnresolvedVariable
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function store() {
  return createStore(
    combineReducers({
      restaurant,
      product,
      session,
      type_order,
      current_shopping_cart,
      show_orders,
      update_orders,
      addresses,
      credit_cards,
      payment_method,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
}

export default store;
