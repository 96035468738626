import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { grayPrimary } from "../commons/theme";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: props => ({
    display: "flex",
    alignItems: "flex-end",
    // zIndex: "20 !important",
    "& .MuiDialog-container": {
      height: "100%",
      width: "100%",
    },
  }),
});

export default function FullScreenDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        className={classes.root}
        fullScreen
        fullWidth
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div onClick={() => props.handleClose()}>
            <CancelRoundedIcon style={{ color: grayPrimary, fontSize: 26 }} />
          </div>
          <div>
            {props.childrens({
              handleCloseDialogFullScreen: props.handleClose,
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
