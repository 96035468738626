import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import BookIcon from "@material-ui/icons/Book";
import StepConnector from "@material-ui/core/StepConnector";
import { greenSuccess } from "../components/commons/theme";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { DeliveryIcon, TakeAwayIcon } from "../components/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient(90deg, rgba(234,234,234,1) 0%, rgba(9,198,101,1) 68%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient(90deg, rgba(234,234,234,1) 0%, rgba(9,198,101,1) 68%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: greenSuccess,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    background: greenSuccess,
  },
});

function ColorlibStepIconDelivery(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  let icons = {};

  icons = {
    1: <BookIcon />,
    2: <StorefrontIcon />,
    3: <DeliveryIcon color="white" />,
    4: <CheckCircleOutlineIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorlibStepIconPickup(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <BookIcon />,
    2: <StorefrontIcon />,
    3: <TakeAwayIcon color="white" />,
    4: <CheckCircleOutlineIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function TimeLineIcons(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [typeOrder, setTypeOrder] = useState(null);
  const [numStepIcons, setNumStepIcons] = useState([]);

  useEffect(() => {
    switch (props.status) {
      case "new":
        setActiveStep(0);
        break;
      case "active":
        setActiveStep(1);
        break;
      case "shipped":
        setActiveStep(2);
        break;
      case "completed":
        setActiveStep(4);
        break;
      default:
        break;
    }

    setTypeOrder(props.type);
    switch (props.type) {
      case "delivery":
        setNumStepIcons([1, 2, 3, 4]);
        break;

      case "pickup":
        setNumStepIcons([1, 2, 3, 4]);

        break;
      default:
        break;
    }
  }, [props.status]);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        style={{ padding: 0 }}
      >
        {numStepIcons.map(label => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={
                typeOrder == "delivery"
                  ? ColorlibStepIconDelivery
                  : ColorlibStepIconPickup
              }
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
