import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  letterSpacing,
  headerTitleColor,
} from "../../components/commons/theme";

const useStyles = makeStyles({
  root: {
    width: "100%",
    letterSpacing,
    fontWeight: 200,
    fontSize: 12,
  },
});

function LabelCommon(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <label>{props.value}</label>
    </div>
  );
}

export default LabelCommon;
