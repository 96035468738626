import React, { useState } from "react";
import Numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import {
  greenSuccess,
  grayPrimary,
  letterSpacing,
} from "../components/commons/theme";

const useStyles = makeStyles({
  root: {},
  title: {
    color: grayPrimary,
    marginBottom: 10,
    letterSpacing,
    fontWeight: "500",
    fontSize: 10,
  },
  descriptioAddress: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    letterSpacing,
    fontWeight: "500",
    fontSize: 12,
  },
  addAddress: {
    color: greenSuccess,
    width: "100%",
    textAlign: "center",
  },
});

export default function ShippingAddress({
  shippingCost,
  addressMain,
  setOpenAddress,
  shippingFree
  }) {
  const classes = useStyles({ addressMain });
  return (
    <div className={classes.root}>
      <div className={classes.title}>DIRECCIÓN DE ENTREGA</div>
      <div className={classes.descriptioAddress}>
        {addressMain && addressMain.address && (
          <div style={{ width: "100%" }}>
            {addressMain && <div>{addressMain?.address}</div>}
            {addressMain?.details && <div>{addressMain?.details}</div>}
            {addressMain?.city_name && <div>{addressMain?.city_name}</div>}
            <div style={{ color: greenSuccess }}>{addressMain?.city?.name}</div>
          </div>
        )}

        <div
          className={classes.addAddress}
          onClick={() => setOpenAddress(true)}
        >
          {!addressMain
            ? "Agrega una dirección de entrega "
            : !shippingCost && !shippingFree
            ? "El restaurante no tiene cobertura para tu direcciòn."
            : ""}
        </div>
        {addressMain && (
          <div style={{minWidth:'100px' }}>
            <div
              style={{ color: greenSuccess }}
              onClick={() => setOpenAddress(true)}
            >
              Cambiar
            </div>
            <div>
              {
                shippingFree ? (<div>Envio gratis</div>) : (
                  <div>
                    {shippingCost && Numeral(shippingCost.price).format("($0,0)")}
                  </div>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
