import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { blackMain, graySecond } from './commons/theme';
import { s3PathImageHandrer } from '../utils/constants';
import HomeIconDepartment from '../components/icons/home-icon-departments';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8px',
  },
  contentGroups: {
    minWidth: 80,
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingLeft: 0,
    margin: '5px 0px 10px 0px',
  },
  border: {
    height: 25,
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '30px',
    backgroundColor: graySecond,
  },
  contendDeparments: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    minWidth: '170px !important',
  },
  selected: {
    backgroundColor: '#56565c',
    color: 'white',
  },
  contentWhitGrid: {
    display: 'flex',
    overflow: 'auto',
  },
  selectedWithImage: {
    border: ({ mainColor }) => `2px solid ${mainColor}`,
    borderRadius: '10px',
    opacity: 0.8,
  },
  contendHome: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 4,
    marginBottom: '5px',
  },
  imgDepartment: {
    borderRadius: '9px',
  },
  selectedHome: {
    opacity: 0.8,
  },
});

export default function TabDeparments({
  departments,
  setDepartmentId,
  mainColor,
}) {
  const classes = useStyles({ mainColor });
  const [selected, setSelected] = useState(0);
  const [gridWhitImage, setGridWhitImage] = useState(false);

  const OnSelected = ({ i, id }) => {
    setSelected(i);
    setDepartmentId(id);
  };

  useEffect(() => {
    if (departments[0].path_background) setGridWhitImage(true);
  }, [departments]);

  return (
    <div className={classes.root}>
      <div
        className={`${classes.contendHome} ${selected === 'home' && classes.selectedHome
          }`}
        onClick={() => OnSelected({ i: 'home', id: 0 })}
      >
        <HomeIconDepartment mainColor={mainColor} />
      </div>
      {gridWhitImage ? (
        <div className={classes.contentWhitGrid}>
          {departments.map((it, key) => (
            <div className={classes.contendDeparments}>
              <div
                className={clsx(
                  key === selected && key !== 0
                    ? classes.selectedWithImage
                    : key === selected && key === 0 && classes.selectedHome
                )}
                onClick={() => OnSelected({ i: key, id: it.id })}
              >
                <img
                  className={classes.imgDepartment}
                  src={`${s3PathImageHandrer}/${window.imageShark(
                    it.path_background,
                    600
                  )}`}
                  alt="logo"
                  height={'auto'}
                  width={'100%'}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={classes.contentGroups}>
          {[{}, ...departments].map((it, key) => (
            <div
              style={{
                minWidth: '120px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className={classes.contendDeparments}>
                <div
                  className={clsx(
                    classes.border,
                    key === selected && classes.selected
                  )}
                  onClick={() => OnSelected({ i: key, id: it.id })}
                >{`${it.name ? it.name : 'Todos'}`}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
