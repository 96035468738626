import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "./";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { grayPrimary, graySecond, letterSpacing } from "./theme";

const useStyles = makeStyles({
  root: (props) => ({
    height: props.size,
    width: props.width,
    backgroundColor: props.backgroundColor,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
  }),
  containerBottom: {
    color: grayPrimary,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
  },
  contentCategories: {
    marginTop: 10,
    color: "#bcbcbc",
    display: "flex",
    "& > :first-child": {
      marginLeft: 0,
    },
  },
  tagsCategories: {
    marginLeft: 10,
    border: `1px solid #bcbcbc`,
    borderRadius: 30,
    padding: 3,
    paddingLeft: 12,
    paddingRight: 12,
    letterSpacing,
    textTransform: "capitalize",
    fontWeight: 500,
  },
});

export default function CardRestaurantBottom(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.containerBottom}>
        <div
          style={{ marginRight: 4, color: "rgb(51 222 51)", fontWeight: 600 }}
        >
          Abierto
        </div>
        <Divider
          size={16}
          width={1}
          backgroundColor={grayPrimary}
          marginTop={5}
          marginBottom={5}
        />
        <div style={{ marginRight: 4, display: "flex", alignItems: "center" }}>
          <AccessTimeIcon style={{ fontSize: 18 }} />
          <div style={{ marginLeft: 5 }}>l-s 1pm-4pm</div>
        </div>
        <Divider
          size={16}
          width={1}
          backgroundColor={grayPrimary}
          marginTop={5}
          marginBottom={5}
        />
        <div style={{ marginRight: 4, display: "flex", alignItems: "center" }}>
          <HomeOutlinedIcon style={{ fontSize: 18 }} />{" "}
          <div style={{ marginLeft: 5 }}>Calle 90 No 46-12</div>
        </div>
      </div>
      <div className={classes.contentCategories}>
        <div className={classes.tagsCategories}>pastas</div>
        <div className={classes.tagsCategories}>pizzas</div>
      </div>
    </div>
  );
}
