import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
});

const CallWaiterICon = (props) => {
  const classes = useStyles(props);

  return (
    <svg
      height={props.height}
      viewBox="0 0 512 512"
      width={props.width}
      fill={props.color}
    >
      <g>
        <g>
          <path
            d="M144.099,28.444c-40.85,0-74.084,33.233-74.084,74.084c0,40.85,33.234,74.084,74.084,74.084
			c40.85,0,74.084-33.233,74.084-74.084C218.182,61.677,184.949,28.444,144.099,28.444z M144.099,160.148
			c-31.771,0-57.621-25.848-57.621-57.621s25.849-57.621,57.621-57.621s57.621,25.848,57.621,57.621S175.87,160.148,144.099,160.148
			z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M143.093,191.978l-47.743-13.545v67.675l47.743-12.605l47.743,12.605v-67.675L143.093,191.978z M174.373,224.735
			l-31.28-8.258l-31.28,8.258v-24.518l31.28,8.874l31.28-8.874V224.735z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M369.734,213.78l-52.133,52.131l-48.708-48.708c-9.848-9.847-22.937-15.269-36.865-15.269c0,0-0.007,0-0.01,0
			l-23.072,0.005l0.004,16.463l23.071-0.005h0.008c9.528,0,18.486,3.71,25.222,10.447l60.348,60.348l58.95-58.95h34.544
			l-94.312,105.021l-74.911-67.82v228.093H77.24v-95.942h16.281v75.73h98.961v-75.73h41.158v-64.207h-41.158v-8.23H93.521v8.23
			h-16.28V286H60.778v65.851h32.743v31.281h-16.28H69.01H33.061V254.102c0-19.664,15.998-35.665,35.662-35.67l-0.004-16.463
			c-28.74,0.007-52.121,23.393-52.121,52.133v145.492h44.181V512h197.556V304.556l59.653,54.006L448.007,213.78H369.734z
			 M192.482,351.851h24.695v31.281h-24.695V351.851z M109.984,343.621h66.035v115.241h-66.035V343.621z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M482.518,187.771c-3.959-46.818-41.359-84.221-88.089-88.028V85.7h-16.463v14.109
			c-46.388,4.135-83.419,41.39-87.358,87.962h-13.615v16.463h13.262h192.617h12.531v-16.463H482.518z M307.135,187.771
			c4.122-40.325,38.171-71.888,79.427-71.888c41.256,0,75.305,31.564,79.428,71.888H307.135z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M328.028,178.99h16.463c0-17.923,14.524-32.503,32.377-32.503v-16.463C349.938,130.024,328.028,151.99,328.028,178.99z" />
        </g>
      </g>
      <g>
        <g>
          <rect x="472.357" y="34.572" width="16.463" height="16.463" />
        </g>
      </g>
      <g>
        <g>
          <rect x="472.357" y="100.424" width="16.463" height="14.817" />
        </g>
      </g>
      <g>
        <g>
          <rect x="472.357" y="67.498" width="16.463" height="16.463" />
        </g>
      </g>
      <g>
        <g>
          <rect x="441.077" y="51.035" width="16.463" height="16.463" />
        </g>
      </g>
      <g>
        <g>
          <rect x="441.077" y="18.109" width="16.463" height="16.463" />
        </g>
      </g>
      <g>
        <g>
          <rect x="408.151" y="32.926" width="16.463" height="16.463" />
        </g>
      </g>
      <g>
        <g>
          <rect x="408.151" width="16.463" height="16.463" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
export default CallWaiterICon;
