import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';

const INITIAL_STATE = {
  update_orders: 0,
};

const { Types, Creators } = createActions({
  setUpdateOrders: ['update_orders'],
});

const setUpdateOrders = (state, { update_orders }) => {
  return produce(state, (draftState) => {
    draftState.update_orders = update_orders;
  });
};

// noinspection JSCheckFunctionSignatures
export const update_orders = createReducer(INITIAL_STATE, {
  [Types.SET_UPDATE_ORDERS]: setUpdateOrders,
});

export default {
  Types,
  Creators,
};
