import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

const INITIAL_STATE = {
  payment_method: undefined,
};

const { Types, Creators } = createActions({
  setPaymentMethod: ["payment_method"],
});

const setPaymentMethod = (state, { payment_method }) => {
  return produce(state, draftState => {
    draftState.payment_method = payment_method;
  });
};

// noinspection JSCheckFunctionSignatures
export const payment_method = createReducer(INITIAL_STATE, {
  [Types.SET_PAYMENT_METHOD]: setPaymentMethod,
});

export default {
  Types,
  Creators,
};
