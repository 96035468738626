import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dialog: props => ({
    "& .MuiDialog-paper": {
      width: "100%",
      borderRadius: 40,
      minWidth: props.minWidth,
      padding: props.padding ? props.padding : "none",
    },
  }),
});

export default function OpenAlerDialog(props) {
  const classes = useStyles(props);
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        className={classes.dialog}
      >
        {props.childrens}
      </Dialog>
    </div>
  );
}
