import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Divider } from "./commons";
import Button from "@material-ui/core/Button";
import { mainColor, letterSpacing, blackMain } from "./commons/theme";
import LinkIcon from "@material-ui/icons/Link";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 20,
  },
  contentTitle: {
    maxWidth: 180,
    textAlign: "center",
    marginBottom: 5,
    letterSpacing,
    fontSize: 16,
    color: blackMain,
    fontWeight: "lighter",
    lineHeight: "20px",
  },
  buttonWhatsapp: {
    "&:hover": {
      backgroundColor: mainColor,
    },
    width: "100%",
    marginBottom: 10,
    backgroundColor: mainColor,
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    color: "white",
    "& .MuiButton-label": {
      fontWeight: 600,
    },
  },
  buttonLink: {
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    "& .MuiButton-label": {
      fontWeight: 600,
    },
    minWidth: 200,
  },
});

export default function ShareRestaurant(props) {
  const classes = useStyles();
  const [copy, setCopy] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.contentTitle}>
        <div>COMPARTIR</div>
      </div>
      <Divider
        size={2}
        width={125}
        backgroundColor={mainColor}
        // marginTop={5}
        marginBottom={10}
        style={{ marginLeft: 30 }}
      />
      <div style={{ maxWidth: 200, textAlign: "center" }}>
        <a href={`whatsapp://send?text=${props.link}`} target="blank">
          <div>
            <Button
              variant="raised"
              className={classes.buttonWhatsapp}
              startIcon={<WhatsAppIcon />}
            >
              WHATSAPP
            </Button>
          </div>
        </a>
        <div
          onClick={() => {
            setCopy(true);
            var copyText = document.getElementById("linkInput");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");

            // navigator.clipboard?.writeText("link");
          }}
        >
          <input
            type="text"
            value={`${props.link}`}
            id="linkInput"
            disabled
            style={{ position: "absolute", top: -800 }}
          />
          {!copy ? (
            <Button
              className={classes.buttonLink}
              variant="outlined"
              startIcon={<LinkIcon />}
            >
              COPIAR LINK
            </Button>
          ) : (
            <div>enlace copiado</div>
          )}
        </div>
      </div>
    </div>
  );
}
