import clsx from "clsx";
import lodash from "lodash";
import numeral from "numeral";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { grayPrimary, grayInputsChecks, textColorBlack } from "./theme";

import styled, { keyframes, css } from "styled-components";
import { bounce } from "react-animations";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    color: grayPrimary,
  },
  title: {
    textAlign: "initial",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 0,
  },
  label: {
    backgroundColor: (props) => (props.error ? "red" : "gray"),
    padding: 2,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 50,
    fontSize: 10,
    textTransform: "uppercase",
    color: "white",
    letterSpacing: 1,
  },
  titleItem: {
    fontWeight: "500",
    textTransform: "uppercase",
    letterSpacing: 1,
    fontSize: 16,
  },
  price: {
    color: textColorBlack,
    letterSpacing: 2,
  },
  labelItem: {
    "& .MuiFormControlLabel-label": {
      minWidth: 160,
      letterSpacing: 1,
      textAlign: "left",
    },
  },
  selects: {
    letterSpacing: 0,
    fontSize: 10,
  },
  contendSection: {
    marginBottom: 15,
  },
});

const bounceAnimation = keyframes`${bounce}`;

const Required = styled.div`
  background-color: ${(props) =>
    css`
      ${props.error ? "red" : "#9A9A9A"};
    `};
  padding: 5px 10px;
  borderradius: 50px;
  fontsize: 10px;
  texttransform: uppercase;
  color: white;
  letterspacing: 1;
  &.animate {
    animation: 1s ${bounceAnimation};
  }
`;

const SelectMinMax = styled.div`
  texttransform: uppercase;
  &.animates {
    animation: 1s ${bounceAnimation};
  }
`;

export default function RadioButtonsGroup(props) {
  const [groups, setGroups] = useState({});
  const classes = useStyles(props);
  const [personalizationOptions, setPersonalizationOptions] = useState([]);

  const SelectOptions = ({ min_selects, max_selects }) => {
    let message = null;
    if (min_selects == max_selects) {
      message = `Selecciona ${max_selects} ${
        max_selects == 1 ? "opción" : "opciones"
      }`;
    } else if (min_selects < max_selects) {
      message = ` Selecciona ${
        min_selects > 0 ? `minimo ${min_selects} y` : ""
      } máximo ${max_selects} ${max_selects == 1 ? "opción" : "opciones"}`;
    } else {
      message = "Selecciona";
    }
    return message;
  };

  const handleChange = (
    event,
    personalizationOptSections,
    minSelects,
    maxSelect
  ) => {
    const countChecked = window.document.getElementsByClassName(
      `check-${personalizationOptSections.personalization_section_id} Mui-checked`
    );

    if (maxSelect == 1) {
      if (event.target.checked && countChecked.length) {
        const input = countChecked[0].getElementsByTagName("input")[0];

        const personalizationOptionsSections = [];

        for (const key in personalizationOptions) {
          const element = personalizationOptions[key];

          personalizationOptionsSections.push(
            ...element.personalization_opt_sections
          );
        }

        let grouptTemp = {};
        const inputsChecks = Object.keys(groups);

        //personalizacion pasada
        const personalizationOptionsSection =
          personalizationOptionsSections.find((it) => it.id == input.value);
        for (const key in inputsChecks) {
          const element = inputsChecks[key];
          if (element == input.name) {
            delete inputsChecks[element];
            grouptTemp = groups;
            props.getPersonalization({
              data: {
                personalizationOptionsSectionAnt: personalizationOptionsSection,
                personalizationOptionsSection: personalizationOptSections,
              },
              sum: "sum-and-rest",
            });
            delete grouptTemp[element];
          }
        }

        setGroups({ ...groups, [event.target.name]: true });

        return;
      }
    }

    if (countChecked.length == maxSelect && event.target.checked) {
      setGroups({ ...groups, [event.target.name]: false });

      return;
    }

    setGroups({ ...groups, [event.target.name]: event.target.checked });
    props.getPersonalization({
      data: personalizationOptSections,
      sum: event.target.checked,
    });
  };

  useEffect(() => {
    setPersonalizationOptions(props.personalization_options);
  }, [props]);

  return (
    <FormControl component="fieldset" className={classes.root}>
      {personalizationOptions &&
        personalizationOptions.map((itt, i) => (
          <div className={classes.contendSection} key={i}>
            <div className={classes.title}>
              <SelectMinMax
                className={clsx({
                  ["animates"]: props.errors.sections.includes(itt.id),
                })}
                error={props.errors.sections.includes(itt.id)}
              >
                <span className={classes.titleItem}>{itt.name}</span>
                <div className={classes.selects}>
                  <SelectOptions
                    min_selects={itt.min_selects}
                    max_selects={itt.max_selects}
                  />
                </div>
              </SelectMinMax>
              {itt.required == "true" && (
                <div>
                  <Required
                    className={clsx(
                      {
                        ["animate"]: props.errors.required.includes(itt.id),
                      },
                      classes.label
                    )}
                    error={props.errors.required.includes(itt.id)}
                  >
                    Obligatorio
                  </Required>
                </div>
              )}
            </div>
            {lodash
              .orderBy(itt.personalization_opt_sections, ["position"], ["asc"])
              .map((it, i) => (
                <div className={classes.item}>
                  <FormControlLabel
                    className={clsx(classes.labelItem)}
                    value={`${it.id}`}
                    control={
                      <Checkbox
                        className={clsx(`check-${itt.id}`)}
                        key={i}
                        checked={
                          groups[`${it.name}-${itt.id}-${i}`] ? true : false
                        }
                        onChange={e =>
                          handleChange(e, it, itt.min_selects, itt.max_selects)
                        }
                        name={`${it.name}-${itt.id}-${i}`}
                        price={it.price_tax_incl}
                        style={{ color: grayInputsChecks }}
                        icon={
                          itt.max_selects > 1 ? (
                            <CheckBoxOutlineBlankIcon />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )
                        }
                        checkedIcon={
                          itt.max_selects > 1 ? (
                            <CheckBoxIcon />
                          ) : (
                            <RadioButtonCheckedIcon />
                          )
                        }
                      />
                    }
                    label={it.name}
                  />
                  <label className={classes.price}>
                    +{numeral(it.price_tax_incl).format("($0,0)")}
                  </label>
                </div>
              ))}
          </div>
        ))}
    </FormControl>
  );
}
