import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { UserIcon, CallWaiter } from "../components/icons";
import { ButtomNumItems } from "../components/commons";
import { OpenAlerDialog } from "./commons";
import { CommingSoon } from "./";
import { useDispatch } from "react-redux";
import { showOrdersSet } from "../redux/actions/show-orders";
import { useLocation } from "react-router";
import { grayPrimary, mainColor } from "../components/commons/theme";
import { UserMenu } from "../components";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SELECTOR_USER } from "../redux/selectors/auth";
import { SELECTOR_RESTAURANT } from "../redux/selectors/restaurant";
import { SELECTOR_CURRENT_SHOPPING_CART } from "../redux/selectors/current-shopping-cart";
import { SELECTOR_UPDATE_ORDERS } from "../redux/selectors/update-orders";
import { RestaurantSet } from "../redux/actions/restaurant";
import { SELECTOR_TYPE_ORDER_SELECTED } from "../redux/selectors/current-type-order";
import ModalOrders from "../components/modal-orders";

const useStyles = makeStyles({
  root: props => ({
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: props.home ? "center" : "space-around",
    zIndex: 100,
    opacity: "0.9",
    backgroundColor: props.footer_background_color
      ? props.footer_background_color
      : "white",
    "& > button": {
      color: "white",
      "& > span": {
        color: grayPrimary,
        "& > span": {
          fontSize: "10px !important",
        },
      },
    },
  }),
  divider: {
    marginLeft: 10,
    marginRight: 10,
  },
  navigation: props => ({
    minWidth: 116,
    padding: 0,
    "& .MuiBottomNavigationAction-label": {
      color: props.primary_color ? props.primary_color : mainColor,
    },
  }),
});

export default function SimpleBottomNavigation(props) {
  const [open, setOpen] = useState(false);
  const [openMenuLeft, setOpenMenuLeft] = useState(false);
  const [childrens, setChildrens] = useState(false);

  const [numItems, setNumItems] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const restaurant = useSelector(SELECTOR_RESTAURANT);
  const user = useSelector(SELECTOR_USER);
  const dispatch = useDispatch();
  const currentShoppingCart = useSelector(SELECTOR_CURRENT_SHOPPING_CART);
  const updateOrder = useSelector(SELECTOR_UPDATE_ORDERS);
  const typeOrder = useSelector(SELECTOR_TYPE_ORDER_SELECTED);
  const classes = useStyles(restaurant);

  const handleClickOpen = childrens => {
    setChildrens(childrens);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (event, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMenuLeft(open);
  };

  const actionOrders = () => {
    if (user) {
      if (currentShoppingCart.length === 1) {
        history.push(`checkout/${currentShoppingCart[0].id}?type=delivery`);
      } else dispatch(showOrdersSet(true));
    }
  };

  useEffect(
    it => {
      let num = 0;
      currentShoppingCart.map(it => (num += it.order_details.length));
      setNumItems(num);
    },
    [updateOrder, currentShoppingCart]
  );

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(RestaurantSet({}));
    }
  }, []);
  const visible = numItems > 0 ? "visible" : "hidden";

  return (
    <BottomNavigation showLabels className={classes.root}>
      {!props.home && (
        <BottomNavigationAction
          className={classes.navigation}
          label="HOME"
          icon={
            <Link to={`/`} style={{ marginBottom: -3 }}>
              <UserIcon
                height="26px"
                color={
                  restaurant?.primary_color
                    ? restaurant.primary_color
                    : mainColor
                }
              />
            </Link>
          }
        />
      )}

      <div style={{ display: "flex", justifyContent: "center", width: "100%" ,visibility: visible}}>
        <ButtomNumItems
          num={numItems ? numItems : "0"}
          onClick={() => actionOrders()}
          backgroundColor={
            restaurant?.primary_color ? restaurant.primary_color : mainColor
          }
        />
      </div>
      {/* {!props.home && typeOrder === "instore" && (
        <BottomNavigationAction
          className={classes.navigation}
          label="LLAMAR MESERO"
          icon={
            <CallWaiter
              height="30px"
              color={
                restaurant?.primary_color ? restaurant.primary_color : mainColor
              }
            />
          }
          onClick={() =>
            handleClickOpen(
              <CommingSoon
                handleClose={handleClose}
                title={`Proximamente podrás llamar al mesero`}
              />
            )
          }
        />
      )} */}
      <UserMenu
        open={openMenuLeft}
        onCloseMenu={e => {
          toggleDrawer(e, false);
        }}
        state
      />
      <OpenAlerDialog
        childrens={childrens}
        open={open}
        handleClose={handleClose}
        minWidth={360}
      />
      <ModalOrders handleClose={() => dispatch(showOrdersSet(false))} />
    </BottomNavigation>
  );
}
