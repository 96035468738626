import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { LogoRestaurant } from "../../components/commons";
import { restaurantsService } from "../../api/feathers";
import MuiButton from "@material-ui/core/Button";
import Logo from "../../components/commons/logo";
import { BackIcon } from "../../components/icons";
import styled, { css } from 'styled-components'
import { s3Path, s3PathImageHandrer } from "../../utils/constants";

export const ContentList = styled.div`
    position:relative;
    display: flex;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 'calc(100vh - 30px)';
    ${({$bgColor, $bgPath}) => {
      if ($bgPath) {
        return css`
        background-image: url("${$bgPath}");
        background-repeat: no-repeat;
        background-size: cover;
      `}

      if($bgColor) { 
        return css`
        background-color: ${$bgColor};""
      `}
      
    }}
`

export const Button = styled(MuiButton)`
  border-radius: ${({$borderRadius})=> `${$borderRadius}px`};
  background-color: ${({$bgColor})=> $bgColor} ;
  color: ${({$fontColor})=> $fontColor};
  font-weight: bold;
  :hover {
    background-color: ${({$bgColor})=> $bgColor};
  }
`

const useStyles = makeStyles(() => ({
  contentRestauranList: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: 20,
  },
  description: {
    color: ({bioColor}) => bioColor,
    fontSize: 16,
    marginTop: 20,
  },
  header: {
    position: 'absolute',
    top: '5%',
    left: '5%',
  },
}));

function RestaurantBranchsList(props) {
  const [restaurantBranchs, setRestaurantBranchs] = useState([]);
  const classes = useStyles({ bioColor: restaurantBranchs[0]?.bio_text_color });
  const history = useHistory();
  const match = useRouteMatch("/:slug");

  const getRestaurantBranchsLists = slug => {
    restaurantsService
      .find({
        query: {
          $client: {
            brandSlug: slug,
          },
          status: "active",
        },
      })
      .then(it => {
        if (it.length === 1) {
          redirectRestaurant(it[0].slug);
        }
        setRestaurantBranchs(it);
      });
  };

  useEffect(() => {
    getRestaurantBranchsLists(match.params.slug);
  }, []);

  const redirectRestaurant = slug => {
    history.push(`r-${slug}`);
  };

  const back = () => {
    history.push("/");
  };

  return (
    <div>
      <ContentList 
        $bgPath={restaurantBranchs[0]?.background_image_path && `${s3PathImageHandrer}/${window.imageShark(restaurantBranchs[0]?.background_image_path) }`}
        $bgColor={restaurantBranchs[0]?.background_color} 
      >
      <div className={classes.header}>
        <div onClick={() => back()}>
          <BackIcon />
        </div>
      </div>
        <div className={classes.logoRestaurant}>
          {restaurantBranchs[0] ? (
            <LogoRestaurant
              path_logo ={restaurantBranchs[0]?.path_logo}
              height={70}
              width={70}
            />
          ) : (
            <Logo height={50} width={50} imgHeight={50} />
          )}
        </div>
        {/* <div className={classes.description}>
          {restaurantBranchs[0]
            ? "Selecciona la dirección del la sucursal"
            : "Poket Menu"}
        </div> */}
        <div className={classes.contentRestauranList}>
          {restaurantBranchs.map(it => (
            <Button
              $bgColor={restaurantBranchs[0]?.links_background_color}
              $borderRadius={restaurantBranchs[0]?.links_border_radius}
              $fontColor={restaurantBranchs[0]?.links_text_color}
              key={it.id}
              className={classes.button}
              variant="outlined"
              onClick={() => redirectRestaurant(it.slug)}
            >
              {it.address}
            </Button>
          ))}
        </div>
      </ContentList>
    </div>
  );
}

export default RestaurantBranchsList;
