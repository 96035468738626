import react, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing, mainColor } from "./theme";

const useStyles = makeStyles({
  root: (props) => ({
    display: "flex",
    color: "white",
    backgroundColor: props.color ? props.color : mainColor,
    height: 36,
    justifyContent: "center",
    textTransform: "UPPERCASE",
    textAlign: "center",
    alignItems: "center",
    letterSpacing,
    fontWeight: 500,
    width: "100%",
    borderRadius: 30,
    border: 0,
  }),
});

function ButtonMain(props) {
  const classes = useStyles(props);

  useEffect(() => {}, [props]);

  return (
    <button type="submit" className={classes.root} {...props}>
      {props.description}
    </button>
  );
}

export default ButtonMain;
